import { Injectable } from '@angular/core';
import { Entity, Output, SettingTaskOutputPool, SettingTaskOutputPoolOutput, SettingTaskOutputPoolWorkflowTask, WorkflowTask } from '@common/types';
import { memoize } from 'lodash';
import { Observable, from } from 'rxjs';
import { DATA_TYPES_INHERITED, DATA_TYPES_NUMERIC } from 'src/app/data-type';
import { DataManagerService } from '@services/data-manager.service';
import { EntityQuery, Predicate } from 'breeze-client';
import { TaskType } from 'src/app/tasks/models';
import { AuthService } from '@services/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TaskOutputPoolsService {
  private get defaultTasksPredicate(): Predicate {
    // no materials
    const noMaterials = Predicate.create('NoMaterials', 'ne', 'true');
    // not hidden
    const hidden = Predicate.create('IsHidden', 'ne', 'true');
    // task type only animal or job
    const taskType = Predicate.create('cv_TaskType.TaskType', 'in', [TaskType.Animal, TaskType.Job]);
    // task has numeric outputs
    const numOutputs = Predicate.create('Output', 'any', 'cv_DataType.DataType', 'in', DATA_TYPES_NUMERIC);
    // task has inherited from numeric outputs
    const inhFromNumOutputs = Predicate.and(
      Predicate.create('Output', 'any', 'cv_DataType.DataType', 'in', DATA_TYPES_INHERITED),
      Predicate.create('Output', 'any', 'InheritedFromOutput.cv_DataType.DataType', 'in', DATA_TYPES_NUMERIC),
    );

    return Predicate.and(
      noMaterials,
      hidden,
      taskType,
      // any of tasks with outputs which are numeric or inherited from numeric
      Predicate.or(numOutputs, inhFromNumOutputs),
    );
  }

  private get defaultOutputPredicate(): Predicate {
    // task has numeric outputs
    const numeric = Predicate.create('cv_DataType.DataType', 'in', DATA_TYPES_NUMERIC);
    // task has inherited from numeric outputs
    const inheritedFromNumeric = Predicate.and(
      Predicate.create('cv_DataType.DataType', 'in', DATA_TYPES_INHERITED),
      Predicate.create('InheritedFromOutput.cv_DataType.DataType', 'in', DATA_TYPES_NUMERIC),
    );
    return Predicate.or(numeric, inheritedFromNumeric);
  }

  constructor(
    private auth: AuthService,
    private dataManager: DataManagerService,
  ) {}

  formatterTasks(value: Entity<WorkflowTask>): string {
    return value.TaskName ?? '';
  }

  formatterOutputs(value: Entity<Output>): string {
    return value.OutputName ?? '';
  }

  preparedEntities(values: Entity<SettingTaskOutputPool>[]): Map<number, Entity<SettingTaskOutputPool>> {
    return new Map(values.map(value => [
      value.C_SettingTaskOutputPool_key,
      value,
    ]))
  }
  
  initTasks(values: Entity<SettingTaskOutputPool>[]): Observable<Map<number, Entity<WorkflowTask>>> {
    const taskKeys = Array.from(new Set(values.flatMap(item => item.SettingTaskOutputPoolWorkflowTasks.flatMap(output => output.C_WorkflowTask_key))));
    
    return from(this.searchTasksByKeys(taskKeys)).pipe(
      map(tasks => new Map(tasks.map(item => [item.C_WorkflowTask_key, item]))),
    );
  }

  initOutputs(values: Entity<SettingTaskOutputPool>[]): Observable<Map<number, Entity<Output>>> {
    const outputKeys = Array.from(new Set(values.flatMap(item => item.SettingTaskOutputPoolOutputs.flatMap(task => task.C_Output_key))));

    return from(this.searchOutputsByKeys(outputKeys)).pipe(
      map(outputs => new Map<number, Entity<Output>>(outputs.map(item => [item.C_Output_key, item]))),
    );
  }

  private searchTasksByKeys(taskKeys: number[]): Promise<Entity<WorkflowTask>[]> {
    if (!taskKeys.length) return Promise.resolve([]);

    const predicate = Predicate.create('C_WorkflowTask_key', 'in', taskKeys);
    return this.fetchTasks([predicate]);
  }

  private searchOutputsByKeys(outputKeys: number[]): Promise<Entity<Output>[]> {
    if (!outputKeys.length) return Promise.resolve([]);

    const predicate = Predicate.create('C_Output_key', 'in', outputKeys);
    return this.fetchOutputs([predicate]);
  }

  searchTasks = (searchTerm: string, selected: Entity<WorkflowTask>[]): Promise<Entity<WorkflowTask>[]> => {
    const predicates: Predicate[] = [];
    const preparedSearchTerm = searchTerm.trim();
    if (preparedSearchTerm) {
      // Search by TaskName
      let predicate = Predicate.create('TaskName', 'contains', preparedSearchTerm);
      const key = Number(preparedSearchTerm);
      // if search term is number trying to search by key
      if (!isNaN(key)) {
        predicate = predicate.or(
          Predicate.create('C_WorkflowTask_key', '==', key)
        );
      }
      predicates.push(predicate);
    }

    // excluding already selected
    if (selected?.length) {
      predicates.push(Predicate.create('C_WorkflowTask_key', 'in', selected.map(item => item.C_WorkflowTask_key)).not());
    }

    return this.fetchTasks(predicates);
  }

  fetchTasks(additional: Predicate[] = [], take = 200): Promise<Entity<WorkflowTask>[]> {
    const predicates = this.defaultTasksPredicate.and(additional);
    const query = EntityQuery.from('WorkflowTasks')
      .inlineCount(false)
      .select('C_WorkflowTask_key, TaskName')
      .orderBy('TaskName asc')
      .skip(0)
      .where(predicates)
      .take(take);
    return this.dataManager.returnQueryResults(query);
  }

  searchOutputs = memoize((tasks: Entity<WorkflowTask>[]) => 
    async (searchTerm: string, selected: Entity<Output>[]): Promise<Entity<Output>[]> => {
      const taskKeys = Array.from(new Set(tasks?.map(task => task.C_WorkflowTask_key) ?? []));

      const predicates: Predicate[] = [
        // search only for already selected tasks
        Predicate.create('C_WorkflowTask_key', 'in', taskKeys),
      ];

      const preparedSearchTerm = searchTerm.trim();
      if (preparedSearchTerm) {
        // Search by OutputName
        let predicate = Predicate.create('OutputName', 'contains', preparedSearchTerm);
        const key = Number(preparedSearchTerm);
        // if search term is number trying to search by key
        if (!isNaN(key) && key > 0) {
          predicate = predicate.or(
            Predicate.create('C_Output_key', '==', key)
          );
        }
        predicates.push(predicate);
      }

      // excluding already selected
      if (selected?.length) {
        predicates.push(
          Predicate.create('C_Output_key', 'in', selected.map(item => item.C_Output_key)).not(),
        );
      }
      
      return this.fetchOutputs(predicates);
    }
  );

  fetchOutputs(additional: Predicate[] = [], take = 200): Promise<Entity<Output>[]> {
    const predicates = this.defaultOutputPredicate.and(additional);
    const query = EntityQuery.from('Outputs')
      .inlineCount(false)
      .where(predicates)
      .select('C_Output_key, OutputName, C_WorkflowTask_key')
      .take(take)
      .skip(0)
      .orderBy('OutputName asc');
    return this.dataManager.returnQueryResults(query);
  }

  getTaskOutputPools(): Observable<Entity<SettingTaskOutputPool>[]> {
    const query = EntityQuery.from('SettingTaskOutputPools')
      .inlineCount(false)
      .expand([
        'SettingTaskOutputPoolWorkflowTasks',
        'SettingTaskOutputPoolOutputs',
      ]);

    return from(this.dataManager.returnQueryResults(query));
  }

  createTask(initial: Partial<SettingTaskOutputPoolWorkflowTask>): Entity<SettingTaskOutputPoolWorkflowTask> {
    const date = new Date();
    return this.dataManager.createEntity('SettingTaskOutputPoolWorkflowTask', {
      CreatedBy: this.auth.getCurrentUserName(),
      DateCreated: date,
      ModifiedBy: this.auth.getCurrentUserName(),
      DateModified: date,
      ...initial,
    });
  }

  deleteTask(entity: Entity<SettingTaskOutputPoolWorkflowTask>): void {
    this.dataManager.deleteEntity(entity);
  }

  createOutput(initial: Partial<SettingTaskOutputPoolOutput>): Entity<SettingTaskOutputPoolOutput> {
    const date = new Date();
    return this.dataManager.createEntity('SettingTaskOutputPoolOutput', {
      CreatedBy: this.auth.getCurrentUserName(),
      DateCreated: date,
      ModifiedBy: this.auth.getCurrentUserName(),
      DateModified: date,
      ...initial,
    });
  }

  deleteOutput(entity: Entity<SettingTaskOutputPoolOutput>): void {
    this.dataManager.deleteEntity(entity);
  }
}
