import { Injectable } from "@angular/core";
import { ExtendedJob, Job, JobMaterial, Material, Sample, SampleGroup, Entity } from "@common/types";
import { ISelectable } from "@common/types/selectable.interface";
import { createLookup } from "@common/util/create-lookup";
import { JobPharmaDataAccessService, SampleGroupMetadata } from "./job-pharma-data-access.service";
import { JobPharmaDetailService } from "./job-pharma-detail.service";
import { SampleService } from "src/app/samples";
import { CopyBufferService } from "@common/services";
import { MaterialType } from "../models/material-type";

export type SampleGroupExtended = SampleGroup & SampleGroupMetadata & ISelectable & {
    numSources?: number;
    taskFirst?: boolean;
    expanded?: boolean;
    classes?: {
        [key: string]: { [key: string]: boolean };
    };
}

@Injectable({
    providedIn: "root"
})
export class JobPharmaCoreService {
    // TODO: should be moved to service specific to JobPharmaSampleIndividual component
    private jobSampleCounts: Map<Job, number> = new Map();
    private pendingSampleJobMaterialDeletions: Map<Job, Map<number, JobMaterial>> = new Map();
    
    constructor(
        private jobPharmaDataAccessService: JobPharmaDataAccessService,
        private jobPharmaDetailService: JobPharmaDetailService,
        private sampleService: SampleService,
        private copyBufferService: CopyBufferService,
    ){ }

    async getSampleJobMaterials(jobKey: number, pageNumber: number): Promise<JobMaterial[]> {
        return this.jobPharmaDataAccessService.getSampleJobMaterials(jobKey, pageNumber);
    }

    async getSampleCount(job: Job): Promise<number> {
        const count = await this.jobPharmaDataAccessService.getSampleCount(job.C_Job_key);
        this.calculateSampleCountMinusDeletions(job, count)
        return count;
    }

    async getSampleCountMinusPendingDeletions(job: Job): Promise<number> {
        if (this.jobSampleCounts.has(job)) {
            return this.jobSampleCounts.get(job);
        }
        
        const count = await this.jobPharmaDataAccessService.getSampleCount(job.C_Job_key);
        return this.calculateSampleCountMinusDeletions(job, count);
    }

    private calculateSampleCountMinusDeletions(job: Job, count: number) {
        const deletions = this.getIgnoredSampleMaterialKeysOnJob(job);
        const trueCount = count - deletions.size;
        this.jobSampleCounts.set(job, trueCount);
        return trueCount;
    }

    // TODO: The call to the jobPharmaDetailService will be replaced with a GraphQL query
    addSamplesToJob(samples: Sample[], job: Entity<Job & ExtendedJob> | Entity<Job>): Promise<any> {
        const materials = samples.map(s => s.Material) as Entity<Material>[];
        return this.jobPharmaDetailService.addMaterialsToJobIfMissing(job, materials);
    }

    onDropSamples(job: Entity<Job & ExtendedJob> | Entity<Job>): Promise<any> {
        const samples = this.sampleService.draggedSamples;
        this.sampleService.draggedSamples = [];

        return this.addSamplesToJob(samples, job);
    }

    onPasteSamples(job: Entity<Job & ExtendedJob> | Entity<Job>): Promise<any> {
        if (!this.copyBufferService.hasSamples()) {
            return Promise.resolve();
        }

        const samples = this.copyBufferService.paste();
        return this.addSamplesToJob(samples, job);
    }

    // TODO: again, call to the jobPharmaDetailSerivce will be replaced with GraphGL query
    async removeSampleJobMaterial(jobMaterial: JobMaterial): Promise<void> {
        const job = jobMaterial.Job;
        const success = await this.jobPharmaDetailService.removeJobMaterial(jobMaterial);
        if (!success) {
            return;
        }

        const deletions = this.pendingSampleJobMaterialDeletions.get(job);
        if (!deletions) {
            this.pendingSampleJobMaterialDeletions.set(job, new Map().set(jobMaterial.C_Material_key, jobMaterial));
        } else {
            deletions.set(jobMaterial.C_Material_key, jobMaterial);
        }

        const count = this.jobSampleCounts.get(job);
        this.jobSampleCounts.set(job, count - 1);
    }

    rejectSampleJobMaterialChange(jobMaterial: JobMaterial) {
        const job = jobMaterial.Job;

        const deletions = this.pendingSampleJobMaterialDeletions.get(job);
        if (!deletions || !deletions.has(jobMaterial.C_Material_key)) {
            return;
        }

        deletions.delete(jobMaterial.C_Material_key);

        const count = this.jobSampleCounts.get(job);
        this.jobSampleCounts.set(job, count + 1);
    }

    getAllSampleMaterialsOnJob(job: Job) {
        return this.jobPharmaDataAccessService.getAllMaterialsOnJob(job.C_Job_key, MaterialType.SAMPLE);
    }

    getIgnoredSampleMaterialKeysOnJob(job: Job, includeDetached?: boolean): Set<number> {
        const map = this.pendingSampleJobMaterialDeletions.get(job);
        if (!map) {
            return new Set();
        }

        const jobMaterials = (<Entity<JobMaterial>[]>Array.from(map.values()))
            .filter((jm) => jm.entityAspect?.entityState?.isDeleted() ||  (includeDetached && jm.entityAspect?.entityState?.isDetached()));
    
        return new Set(jobMaterials.map(jm => jm.C_Material_key));
    }

    clearIgnoredSamplesOnAllJobs() {
        this.pendingSampleJobMaterialDeletions.clear();
    }

    cleanupIgnoredSamplesOnJob(job: Job) {
        const map = this.pendingSampleJobMaterialDeletions.get(job);
        if (!map) {
            return;
        }

        const jobMaterials = Array.from(map.values()) as Entity<JobMaterial>[];
        jobMaterials.forEach(jm => jm.entityAspect?.entityState?.isDetached() && map.delete(jm.C_Material_key));
    }

    async populateSampleGroupsMetadata(sampleGroups: SampleGroupExtended[]): Promise<void> {
        if (!sampleGroups || sampleGroups.length === 0) {
            return;
        }
        
        const sampleGroupsLookup = createLookup(sampleGroups, "C_SampleGroup_key");
        const sampleGroupsMetadata = await this.jobPharmaDataAccessService.getSampleGroupsMetadata(sampleGroups.map(sg => sg.C_SampleGroup_key));
        for (const metadata of sampleGroupsMetadata) {
            const key = metadata.C_SampleGroup_key;
            const sg = sampleGroupsLookup.get(key);
            sg.SamplesCreatedCount = metadata.SamplesCreatedCount;
            sg.NumberOfAnimalSourcesCount = metadata.NumberOfAnimalSourcesCount;
            sg.NumberOfSampleSourcesCount = metadata.NumberOfSampleSourcesCount;
            sg.NumberOfAnimalPlaceholderSourcesCount = metadata.NumberOfAnimalPlaceholderSourcesCount;
            sg.HasEndStateMemberTask = metadata.HasEndStateMemberTask;
            sg.numSources = (
                sg.NumberOfAnimalSourcesCount + 
                sg.NumberOfSampleSourcesCount + 
                sg.NumberOfAnimalPlaceholderSourcesCount
            );
        }
    }

}