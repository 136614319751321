<table class="table table-bordered job-pharma-tasks-schedule">
    <thead>
        <tr class="detail-grid-header">
            <th *ngIf="!readonly" class="icon-cell">
                <lock-control [(model)]="allLocked"
                              [administrator]="isStudyDirector"
                              (modelChange)="allLockedChanged()"></lock-control>
            </th>
            <!-- Select -->
            <th *ngIf="!readonly" class="icon-cell">
                <input type="checkbox"
                       name="selectAll"
                       [(ngModel)]="allSelected"
                       (ngModelChange)="allSelectedChanged()"
                       title="Select/Clear all" />
            </th>

            <th *ngIf="visible.protocol">Protocol</th>
            <th *ngIf="visible.taskAlias">Task Name</th>
            <th *ngIf="visible.dueDate" class="text-nowrap">
                <div class="display-flex">
                    <ng-template [ngTemplateOutlet]="bulkDateDueHeader"
                             [ngTemplateOutletContext]="{title: 'Due Date'}"></ng-template>
                    <info-tooltip class="tool-tip padding-left-5"
                              [text]="'Completing anchor tasks updates the schedule in the Workflow facet for tasks that are linked by &quot;Relative Task-Complete Date/Time&quot;'"></info-tooltip>
                </div>
            </th>
            <th *ngIf="visible.startTime" class="text-nowrap">
                <ng-template [ngTemplateOutlet]="bulkStartTimeHeader"
                             [ngTemplateOutletContext]="{title: 'Start Time'}"></ng-template>
            </th>
            <th *ngIf="visible.duration" class="text-nowrap">
                <ng-template [ngTemplateOutlet]="bulkDurationHeader"
                             [ngTemplateOutletContext]="{title: 'Duration'}"></ng-template>
            </th>
            <th *ngIf="visible.endTime" class="text-nowrap">End Time</th>
            <th *ngIf="visible.assignedTo" class="text-nowrap">                
                <ng-template [ngTemplateOutlet]="bulkAssignedToHeader"
                             [ngTemplateOutletContext]="{title: 'Assigned To'}"></ng-template>
            </th>
            <th *ngIf="visible.location" class="text-nowrap">
                <bulk-edit-header [headerText]="'Location'"
                                  (updateClicked)="bulkLocationUpdated()"
                                  [readonly]="allLocked || readonly">
                    <div modal-content>
                        <location-chooser [(location)]="bulkLocation"
                                          [readonly]="readonly"
                                          [required]="false"></location-chooser>
                    </div>
                </bulk-edit-header>
            </th>
            <th *ngIf="visible.deviation" class="text-nowrap">
                <bulk-edit-header [headerText]="'Allowance'"
                                  (updateClicked)="bulkAllowanceUpdated()"
                                  [readonly]="allLocked || readonly">
                    <div modal-content>
                        <input name="deviation_bulkFill"
                               [(ngModel)]="bulkAllowance"
                               [disabled]="readonly"
                               class="input-small" />
                    </div>
                </bulk-edit-header>
            </th>
            <th *ngIf="visible.status" class="text-nowrap">Progress</th>
        </tr>
    </thead>
    <!-- open -->
    <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>
    <tbody *ngIf="!loading">
        <tr *ngFor="let task of tasks |
                paginate: { itemsPerPage: 50, currentPage: taskPage, id: 'tasks-schedule-pagination' };
                let index = index"
            [ngClass]="{'selected-row': task.isSelected}"
            class="task-schedule-row">
            <td *ngIf="!readonly"
                class="icon-cell">
                <lock-control [(model)]="task.IsLocked"
                              [administrator]="isStudyDirector"
                              (modelChange)="isLockedChanged()"></lock-control>
            </td>
            <!-- Select -->
            <td *ngIf="!readonly"
                class="icon-cell">
                <input type="checkbox"
                       name="isSelected_{{index}}"
                       [(ngModel)]="task.isSelected"
                       (ngModelChange)="isSelectedChanged()" />
            </td>

            <!-- Protocol -->
            <td *ngIf="visible.protocol" class="protocol-name">
                {{task.ProtocolInstance?.ProtocolAlias}}
            </td>
            <!-- Task -->
            <td *ngIf="visible.taskAlias" class="task-name">
                {{task.TaskAlias}}
            </td>

            <!-- Due Date -->
            <td *ngIf="visible.dueDate">
                <climb-ngb-date [(ngModel)]="task.DateDue"
                                [ngModelOptions]="{ standalone: true }"
                                (ngModelChange)="dateDueChanged([task])"
                                [allowTime]="false"
                                [disabled]="readonly || task.IsLocked"
                                ngDefaultControl></climb-ngb-date>
            </td>

            <!-- Start Time -->
            <td *ngIf="visible.startTime">
                <climb-ngb-date [(ngModel)]="task.DateDue"
                                [ngModelOptions]="{ standalone: true }"
                                (ngModelChange)="timeStartChanged([task])"
                                [allowDate]="false"
                                [allowTime]="true"
                                [alwaysShowTime]="true"
                                [disabled]="readonly || task.IsLocked"
                                ngDefaultControl></climb-ngb-date>
            </td>

            <!-- Duration -->
            <td *ngIf="visible.duration">
                <table class="duration">
                    <tr>
                        <td>
                            <input type="number"
                                   name="Duration_TimePoint_{{index}}"
                                   [(ngModel)]="task.Duration_TimePoint"
                                   (ngModelChange)="durationTimePointChanged(task)"
                                   [disabled]="readonly || task.IsLocked"
                                   step="any"
                                   min="0"
                                   class="form-control input-small" />
                        </td>
                        <td>
                            <select name="Duration_TimeUnit_{{index}}"
                                    [(ngModel)]="task.Duration_TimeUnit"
                                    (ngModelChange)="durationTimeUnitChanged(task)"
                                    [disabled]="readonly || task.IsLocked"
                                    class="form-control">
                                <option *ngFor="let item of timeUnits"
                                        [ngValue]="item.MinutesPerUnit">
                                    {{item.TimeUnit}}
                                </option>
                            </select>
                        </td>

                    </tr>

                </table>
            </td>

            <!-- End Time -->
            <td *ngIf="visible.endTime"
                class="nowrap">
                {{task.TimeEnd | formatTime}}
            </td>

            <!-- Assigned To -->
            <td *ngIf="visible.assignedTo">
                <span placement="right-top" [ngbTooltip]="tipContent" tooltipClass="assigned-to-error" [disableTooltip]="!task.HasInvalidInputs">
                    <climb-assigned-to-select [(model)]="task.C_AssignedTo_key"
                                              (modelChange)="assignedToChanged(task)"
                                              [disabled]="readonly || task.IsLocked || task.HasInvalidInputs"></climb-assigned-to-select>
                </span>
            </td>

            <!-- Location -->
            <td *ngIf="visible.location">
                <location-chooser [(location)]="task.LocationPosition"
                                  (locationChange)="locationChanged(task)"
                                  [readonly]="readonly || task.IsLocked"
                                  [required]="false"></location-chooser>
            </td>

            <!-- Deviation -->
            <td *ngIf="visible.deviation">
                <input name="deviation_{{index}}"
                       [(ngModel)]="task.Deviation"
                       (ngModelChange)="allowanceChanged(task)"
                       [disabled]="readonly || task.IsLocked"
                       class="input-small" />
            </td>

            <!-- Status -->
            <td *ngIf="visible.status"
                [ngClass]="{'done' : task.statusCount?.done}"
                class="progress-count">
                {{task.statusCount?.endState}}/{{task.statusCount?.total}}
            </td>
        </tr>
    </tbody>
</table>

<pagination-controls (pageChange)="changeTaskPage($event)"
                     [autoHide]="true"
                     class="ngx-table-paginator"
                     id="tasks-schedule-pagination"></pagination-controls>

<ng-template #bulkDateDueHeader let-title="title">
    <bulk-edit-header [headerText]="title"
                      (updateClicked)="bulkDueDateUpdated()"
                      [readonly]="allLocked  || readonly">
        <div modal-content class="text-body">
            <div class="form-group">
                <label>Task</label>
                <select name="bulk_dueDateTask"
                        [(ngModel)]="bulkDueDateTask"
                        (ngModelChange)="bulkDueDateTaskChanged()"
                        class="form-control input-medium">
                    <option [ngValue]="null">[ All Tasks ]</option>
                    <option *ngFor="let task of bulkDueDateTasks"
                            [ngValue]="task">
                        {{task.WorkflowTask.TaskName}}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>Task Name</label>
                <select name="bulk_dueDateTaskAlias"
                        [(ngModel)]="bulkDueDateTaskAlias"
                        [disabled]="bulkDueDateTaskAliases.length === 0"
                        class="form-control input-medium">
                    <option [ngValue]="null">[ All Tasks Names]</option>
                    <option *ngFor="let alias of bulkDueDateTaskAliases"
                            [ngValue]="alias">
                        {{alias}}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>Due Date</label>
                <climb-ngb-date name="Due Date"
                                [(ngModel)]="bulkDueDate"
                                [allowTime]="false"
                                [alwaysShowTime]="true"
                                [disabled]="readonly"
                                ngDefaultControl>
                </climb-ngb-date>
            </div>
        </div>
    </bulk-edit-header>
</ng-template>

<ng-template #bulkStartTimeHeader let-title="title">
    <bulk-edit-header [headerText]="title"
                      (updateClicked)="bulkStartTimeUpdated()"
                      [readonly]="allLocked  || readonly">
        <div modal-content class="text-body">
            <div class="form-group">
                <label>Task</label>
                <select name="bulk_startTimeTask"
                        [(ngModel)]="bulkStartTimeTask"
                        (ngModelChange)="bulkStartTimeTaskChanged()"
                        class="form-control input-medium">
                    <option [ngValue]="null">[ All Tasks ]</option>
                    <option *ngFor="let task of bulkStartTimeTasks"
                            [ngValue]="task">
                        {{task.WorkflowTask.TaskName}}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>Task Name</label>
                <select name="bulk_startTimeTaskAlias"
                        [(ngModel)]="bulkStartTimeTaskAlias"
                        [disabled]="bulkStartTimeTaskAliases.length === 0"
                        class="form-control input-medium">
                    <option [ngValue]="null">[ All Tasks Names]</option>
                    <option *ngFor="let alias of bulkStartTimeTaskAliases"
                            [ngValue]="alias">
                        {{alias}}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>Start Time</label>
                <climb-ngb-date name="Start Time"
                                [(ngModel)]="bulkStartTime"
                                [allowDate]="false"
                                [allowTime]="true"
                                [alwaysShowTime]="true"
                                [disabled]="readonly"
                                ngDefaultControl>
                </climb-ngb-date>
            </div>
        </div>
    </bulk-edit-header>
</ng-template>

<ng-template #tipContent><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Required inputs must be filled before assigning a resource.</ng-template>

<ng-template #bulkDurationHeader let-title="title">
    <bulk-edit-header [headerText]="title"
                      (updateClicked)="bulkDurationUpdated()"
                      [readonly]="allLocked  || readonly">
        <div modal-content class="text-body">
            <div class="form-group">
                <label>Task</label>
                <select name="bulk_durationTask"
                        [(ngModel)]="bulkDurationTask"
                        (ngModelChange)="bulkDurationTaskChanged()"
                        class="form-control input-medium">
                    <option [ngValue]="null">[ All Tasks ]</option>
                    <option *ngFor="let task of bulkDurationTasks"
                            [ngValue]="task">
                        {{task.WorkflowTask.TaskName}}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>Task Name</label>
                <select name="bulk_durationTaskAlias"
                        [(ngModel)]="bulkDurationTaskAlias"
                        [disabled]="bulkDurationTaskAliases.length === 0"
                        class="form-control input-medium">
                    <option [ngValue]="null">[ All Tasks Names]</option>
                    <option *ngFor="let alias of bulkDurationTaskAliases"
                            [ngValue]="alias">
                        {{alias}}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>Duration</label>
                <div class="d-flex">
                    <input type="number"
                           name="Duration_TimePoint_bulkFill"
                           [(ngModel)]="bulkDurationTimePoint"
                           [disabled]="readonly"
                           step="any"
                           min="0"
                           class="form-control input-duration mr-1" />
                    <select name="Duration_TimeUnit_bulkFill"
                            [(ngModel)]="bulkDurationTimeUnit"
                            class="form-control input-duration-unit ml-1">
                        <option [ngValue]="null"></option>
                        <option *ngFor="let item of timeUnits"
                                [ngValue]="item.MinutesPerUnit">
                            {{item.TimeUnit}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </bulk-edit-header>
</ng-template>

<ng-template #bulkAssignedToHeader let-title="title">
    <bulk-edit-header [headerText]="title"
                      (updateClicked)="bulkAssignedToUpdated()"
                      (bodyClicked)="climbMultiSelect.close()"
                      [readonly]="allLocked || readonly">
        <div modal-content class="text-body">
            <div class="form-group">
                <label>Task</label>
                <select name="bulk_assignedToTask"
                        [(ngModel)]="bulkAssignedToTask"
                        (ngModelChange)="bulkAssignedToTaskChanged()"
                        class="form-control input-medium">
                    <option [ngValue]="null">[ All Tasks ]</option>
                    <option *ngFor="let task of bulkAssignedToTasks"
                            [ngValue]="task">
                        {{task.WorkflowTask.TaskName}}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>Task Name</label>
                <select name="bulk_assignedToTaskAlias"
                        [(ngModel)]="bulkAssignedToTaskAlias"
                        [disabled]="bulkAssignedToTaskAliases.length === 0"
                        class="form-control input-medium">
                    <option [ngValue]="null">[ All Tasks Names]</option>
                    <option *ngFor="let alias of bulkAssignedToTaskAliases"
                            [ngValue]="alias">
                        {{alias}}
                    </option>
                </select>
            </div>
            <div class="form-group">
                <label>Assigned To</label>
                <climb-multi-select [(model)]="bulkAssignedTo"
                                    clickStopPropagation
                                    #climbMultiSelect
                                    [values]="resources"
                                    [keyProperty]="'C_Resource_key'"
                                    [valueProperty]="'ResourceName'"
                                    [placeholder]="'Assigned to...'"
                                    [disabled]="readonly"
                                    class="d-block"></climb-multi-select>
            </div>
        </div>
    </bulk-edit-header>
</ng-template>
