import { OverlayModule } from '@angular/cdk/overlay';
import { ColorBoxCellRendererComponent } from './colorbox/color-box-cell-renderer.component';
import { ClimbColorBoxComponent } from './colorbox/climb-color-box.component';
import { DecimalFormatterDirective } from './decimal-formatter.directive';
import { BulkAddPromptModalComponent } from './facet/bulk-add-prompt-modal.component';
import { ViewBulkAddPromptModalService } from './facet/view-bulk-add-prompt-modal.service';
import { BulkAddComponent } from './facet/bulk-add.component';
import { BulkEditTableComponent } from './facet/bulk-edit-table.component';
import { ClimbTimepickerComponent } from './date/climb-timepicker/climb-timepicker.component';
import { BulkEditHeaderComponent } from './bulk-edit-header.component';
import { EntityChangesModule } from './../entity-changes/entity-changes.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from "ag-grid-angular";
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { NgxPaginationModule } from 'ngx-pagination';
import { DialogModule } from '@common/dialog/dialog.module';
import { IconModule } from '@common/icons/icon.module';
import { BaseComponentsModule } from '@common/components/base-components.module';
import { ExportModule } from '@common/export/export.module';
import { TypeaheadModule } from '@common/typeahead/typeahead.module';
import { HeaderModule } from '../header/header.module';

import { DraggableDirective } from './draggable.directive';
import { DroppableDirective } from './droppable.directive';
import { MoneyFormatterDirective } from './money-formatter.directive';
import { ParentDroppableDirective } from './parent-droppable.directive';
import { ClickStopPropagationDirective } from './click-stop-propagation.directive';
import {
    UniqueValidatorDirective
} from './validators';

import { CopyBufferService } from './services/copy-buffer.service';

import { ActiveStatusSelectComponent } from './active-status-select.component';
import { ActiveVocabSelectComponent } from './active-vocab-select.component';
import { ActiveVocabTypeaheadMultiSelectComponent } from './active-vocab-typeahead-multi-select.component';
import { ActiveVocabSelectDynamicComponent } from './active-vocab-select-dynamic.component';
import { ToolbarColumnSelectComponent } from './facet/components/toolbar-column-select/toolbar-column-select.component';
import { ClimbExpandComponent } from './climb-expand.component';
import { ClimbMultiSelectComponent } from './climb-multi-select.component';
import { UrgentStatusSelectComponent } from './urgent-status-select.component';
import { AuthContainerComponent } from './auth/auth-container/auth-container.component';
import { AuthFooterComponent } from './auth/auth-footer/auth-footer.component';

import { MainLayoutComponent } from './layouts';

import {
    ConfirmModalComponent,
    ConfirmService
} from './confirm';

import {
    CellFormatterService,
    ClimbDataTableComponent,
    DataTableCommService,
    HeaderSelectAllComponent,
    HeaderAddItemComponent,
    CellDeleteLinkComponent,
    CellJournalLinkComponent,
    CellDetailLinkComponent
} from './datatable';

import { ClimbBarcodeComponent } from './climb-barcode.component';
import { ClimbNgbDateComponent } from './date/climb-ngb-date/climb-ngb-date.component';
import { InfoTooltipComponent } from './info-tooltip.component';
import { LockControlComponent } from './lock-control.component';
import { LockStatusSelectComponent } from './lock-status-select.component';

import {
    ClimbDropzoneComponent,
    FacetDetailFileUploadComponent,
    DropzoneCommService,
    FileUploadCommService,
    ImageModalComponent,
    ViewImageService,
    StoredFileService,
    ViewFilesButtonComponent,
    FileUploadModalComponent
} from './file';

import {
    NoteLogModalComponent,
    NoteLogComponent,
    NoteService,
    ViewNotesButtonComponent,
    ViewNoteLogModalService
} from './notes';

import { PrintPreviewService } from './services';

import {
    MultiControlWrapComponent,
    MultiControlComponent,
    OneManyToggleComponent,
    MultiPasteInputComponent
} from './multi-paste-input';

import {
    BulkEditToolbarComponent,
    DetailToolbarComponent,
    PagerToolbarComponent,
    UnsavedChangesModalComponent,
    ViewUnsavedChangesModalService
} from './toolbars';

import {
    ClimbServicesModule
} from '../services/climb-services.module';

import { DroppableCommService } from './droppable-comm.service';
import { WarningTooltipComponent } from './warning-tooltip.component';
import { CensusScanModalComponent } from '../census/census-scan-modal.component';
import { CensusScanModalService } from '../census/census-scan-modal.service';
import { ReasonForChangeService } from './reason-for-change/reason-for-change.service';
import { ReasonForChangeModalComponent } from './reason-for-change/reason-for-change-modal.component';
import { DevExtremeWrapperModule } from '../dynamic/devextreme/devextreme-wrapper.module';
import { DateFormatterService } from './util/date-time-formatting/date-formatter.service';
import { RequestCache } from './interceptors/request-cache.service';
import { httpInterceptorProviders } from './interceptors/interceptors.providers';
import { ENV_CONFIG, environment } from '@config';
import { EditableLabelComponent } from './label/editable-label.component';
import { EntityTableComponent } from './entitytable/entity-table.component';
import { TextSliderButtonComponent } from './buttons/text-slider-button.component';
import { IntegerInputDirective } from './directives/integer-input.directive';
import { CheckboxMultiSelectComponent } from './checkbox/checkbox-multi-select.component';
import { BasePipesModule } from '@common/pipes/base-pipes.module';
import { BaseDirectivesModule } from '@common/directives/base-directives.module';
import { MultiselectControlComponent } from './multiselect-control/multiselect-control.component';
import { ModernAppService } from './modern-app.service';
import { ModernComponentFactoryComponent } from '../workspaces/components/modern-component-factory/modern-component-factory.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        OverlayModule,
        NgxBarcode6Module,
        NgxPaginationModule,
        // ag-grid imports
        AgGridModule.withComponents([]),
        ClimbServicesModule,
        EntityChangesModule,
        NgxBootstrapMultiselectModule,
        DevExtremeWrapperModule,
        BasePipesModule,
        DialogModule,
        IconModule,
        BaseDirectivesModule,
        BaseComponentsModule,
        ExportModule,
        TypeaheadModule,
        HeaderModule,
    ],
    declarations: [
        MainLayoutComponent,
        ActiveStatusSelectComponent,
        ActiveVocabSelectComponent,
        ActiveVocabTypeaheadMultiSelectComponent,
        BulkEditHeaderComponent,
        BulkEditTableComponent,
        BulkAddComponent,
        BulkAddPromptModalComponent,
        DecimalFormatterDirective,
        MoneyFormatterDirective,
        ClickStopPropagationDirective,
        ClimbDataTableComponent,
        HeaderSelectAllComponent,
        HeaderAddItemComponent,
        CellDeleteLinkComponent,
        CellDetailLinkComponent,
        CellJournalLinkComponent,
        ActiveVocabSelectDynamicComponent,
        ClimbBarcodeComponent,
        ClimbColorBoxComponent,
        ColorBoxCellRendererComponent,
        ToolbarColumnSelectComponent,
        ClimbExpandComponent,
        ClimbMultiSelectComponent,
        ClimbNgbDateComponent,
        ClimbTimepickerComponent,
        ClimbDropzoneComponent,
        ConfirmModalComponent,
        CensusScanModalComponent,
        FacetDetailFileUploadComponent,
        ViewFilesButtonComponent,
        FileUploadModalComponent,
        ImageModalComponent,
        InfoTooltipComponent,
        LockControlComponent,
        LockStatusSelectComponent,
        MultiPasteInputComponent,
        MultiControlWrapComponent,
        MultiControlComponent,
        OneManyToggleComponent,
        NoteLogModalComponent,
        NoteLogComponent,
        ViewNotesButtonComponent,
        BulkEditToolbarComponent,
        DetailToolbarComponent,
        PagerToolbarComponent,
        DraggableDirective,
        DroppableDirective,
        ParentDroppableDirective,
        UniqueValidatorDirective,
        UnsavedChangesModalComponent,
        UrgentStatusSelectComponent,
        WarningTooltipComponent,
        ReasonForChangeModalComponent,
        AuthContainerComponent,
        AuthFooterComponent,
        TextSliderButtonComponent,
        EntityTableComponent,
        IntegerInputDirective,
        CheckboxMultiSelectComponent,
        EditableLabelComponent,
        MultiselectControlComponent,
        ModernComponentFactoryComponent,
    ],
    exports: [
        RouterModule,
        BasePipesModule,
        BaseDirectivesModule,
        DialogModule,
        IconModule,
        BaseComponentsModule,
        ExportModule,
        TypeaheadModule,
        HeaderModule,
        MainLayoutComponent,
        ActiveStatusSelectComponent,
        ActiveVocabSelectComponent,
        ActiveVocabTypeaheadMultiSelectComponent,
        BulkEditHeaderComponent,
        BulkEditTableComponent,
        BulkAddComponent,
        ClimbBarcodeComponent,
        ClimbColorBoxComponent,
        ColorBoxCellRendererComponent,
        DecimalFormatterDirective,
        MoneyFormatterDirective,
        ClickStopPropagationDirective,
        ClimbDataTableComponent,
        HeaderSelectAllComponent,
        HeaderAddItemComponent,
        CellDeleteLinkComponent,
        CellDetailLinkComponent,
        CellJournalLinkComponent,
        ActiveVocabSelectDynamicComponent,
        ToolbarColumnSelectComponent,
        ClimbExpandComponent,
        ClimbMultiSelectComponent,
        ClimbNgbDateComponent,
        ClimbTimepickerComponent,
        ClimbDropzoneComponent,
        ConfirmModalComponent,
        CensusScanModalComponent,
        FacetDetailFileUploadComponent,
        ViewFilesButtonComponent,
        FileUploadModalComponent,
        InfoTooltipComponent,
        ImageModalComponent,
        LockControlComponent,
        LockStatusSelectComponent,
        MultiPasteInputComponent,
        MultiControlWrapComponent,
        MultiControlComponent,
        OneManyToggleComponent,
        NoteLogModalComponent,
        NoteLogComponent,
        ViewNotesButtonComponent,
        BulkEditToolbarComponent,
        PagerToolbarComponent,
        DetailToolbarComponent,
        DraggableDirective,
        DroppableDirective,
        ParentDroppableDirective,
        UniqueValidatorDirective,
        UnsavedChangesModalComponent,
        UrgentStatusSelectComponent,
        WarningTooltipComponent,
        ReasonForChangeModalComponent,
        AuthContainerComponent,
        AuthFooterComponent,
        TextSliderButtonComponent,
        EntityTableComponent,
        IntegerInputDirective,
        CheckboxMultiSelectComponent,
        EditableLabelComponent,
        MultiselectControlComponent,
        ModernComponentFactoryComponent,
    ],
})
export class ClimbCommonModule {
    static forRoot(): ModuleWithProviders<ClimbCommonModule> {
        return {
          ngModule: ClimbCommonModule,
          providers: [
            {
              provide: ENV_CONFIG,
              useFactory: environment,
            },
            httpInterceptorProviders,
            { provide: Window, useFactory: () => window },
            RequestCache,
            CellFormatterService,
            ConfirmService,
            CensusScanModalService,
            CopyBufferService,
            DataTableCommService,
            DropzoneCommService,
            FileUploadCommService,
            DroppableCommService,
            NoteService,
            PrintPreviewService,
            StoredFileService,
            ViewImageService,
            ViewNoteLogModalService,
            ViewUnsavedChangesModalService,
            ViewBulkAddPromptModalService,
            ReasonForChangeService,
            DateFormatterService,
            ModernAppService,
          ]
        };
    }
 }
