<div *ngIf="form.controls.length">
  <table class="table table-sm table-borderless table-nonfluid">
    <tbody>
      <tr *ngFor="let control of form.controls; let i = index;">
        <td class="icon-cell">
          <button
            class="remove-item"
            type="button"
            (click)="removeItem(i)"
            title="Remove item"
            [disabled]="disabled"
          >
            <i [class.disabled]="disabled" class="fa fa-remove"></i>
          </button>
        </td>
        <td>
          {{ formatter(control.value) }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<climb-indirect-typeahead
  [search]="searchValues"
  [resultFormatter]="formatter"
  [exactMatchFunction]="comparator"
  [placeholder]="placeholder"
  (selectItem)="selectItem($event)"
  [disabled]="disabled"
  [error]="error"
>     
</climb-indirect-typeahead>