import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { caretDownFilled, caretUpFilled, checkmark, cloudOut, eyeOpened, trashcan } from '@icons';
import { MixinUnsubscribeClass } from '@common/mixins';
import { SaveChangesService } from '@services/save-changes.service';
import { PopoverComponent } from '@common/components/popover/popover.component';
import { WipModalService } from '../../../wip/wip-modal.service';
import { ConfirmOptions, ConfirmService } from '@common/confirm';
import { FacetView } from '@common/facet';
import { JobService } from '../../../jobs/job.service';
import { PopoverOptions } from '@common/components/popover/popover-options.interface';
import { CurrentWorkgroupService } from '@services/current-workgroup.service';
import { TabStorageService } from 'src/app/multiply-tab/tab-storage.service';
import { DataContextService } from '@services/data-context.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'climb-save-changes-button',
    templateUrl: 'save-changes-button.component.html',
    styleUrls: ['save-changes-button.component.scss']
})
export class SaveChangesButtonComponent extends MixinUnsubscribeClass implements OnInit {
    @ViewChild('saveOptionsPopover') saveOptionsPopover: PopoverComponent;
    popoverOptions: PopoverOptions = {
        positions: [
            {
                originX: 'start',
                originY: 'bottom',
                overlayX: 'start',
                overlayY: 'top',
                offsetX: -24,
                offsetY: 4,
            }
        ]
    };
    icons = { checkmark, caretUpFilled, caretDownFilled, eyeOpened, cloudOut, trashcan };

    readonly LOG_TAG = 'climb-save-changes-button';

    hasChanges$: Observable<boolean>;
    switchingWorkgroup$: Observable<boolean>;
    isPopoverOpen: boolean;

    get showExtraOptions(): boolean {
        return !this.saveChangesService.saving && !this.saveChangesService.isLocked;
    }

    constructor(
        private saveChangesService: SaveChangesService,
        private dataContextService: DataContextService,
        private wipModalService: WipModalService,
        private confirmService: ConfirmService,
        private jobService: JobService,
        private tabStorage: TabStorageService,
        private currentWorkgroupService: CurrentWorkgroupService,
        protected injector: Injector,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.subscription = this.tabStorage.discardChanges$.subscribe(() => {
            this.discardChanges(false);
        });
        this.hasChanges$ = this.dataContextService.checkChanges$;
        this.switchingWorkgroup$ = this.currentWorkgroupService.switchingWorkgroup$;
    }

    onTogglePopover(state: boolean): void {
        this.isPopoverOpen = state;
    }

    async globalSave(): Promise<void> {
        this.saveOptionsPopover?.close();
        if (!this.saveChangesService.saving) {
            this.saveChangesService.saving = true;
            await this.saveChangesService.saveChanges(this.LOG_TAG);
            this.saveChangesService.saving = false;
        }
    }

    openWipModal(): void {
        this.saveOptionsPopover.close();
        this.wipModalService.openWipModal();
    }

    async discardChanges(confirm = true): Promise<void> {
        this.saveOptionsPopover.close();
        if (confirm) {
            const confirmOptions: ConfirmOptions = {
                title: 'Discard Changes',
                message: 'Are you sure you want to discard your unsaved changes?',
                yesButtonText: 'Discard Changes',
                noButtonText: 'Cancel',
                isDanger: true
            };
            await this.confirmService.confirm(confirmOptions);
        }

        this.dataContextService.cancel(true);
        this.jobService.listViewScreenIntoStudies1(FacetView.LIST_VIEW);
        this.jobService.listViewScreenIntoStudies2(FacetView.LIST_VIEW);

        this.jobService.jobRefreshVocabulary();
    }
}
