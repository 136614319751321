import { AbstractControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";

export const registerRevalidationAfterChangeAny = (controls: AbstractControl[]): Subscription => {
  const subs = new Subscription();
  for (const control of controls) {
    const sub = control.valueChanges
      .pipe(map(() => controls.filter(item => item !== control)))
      .subscribe((otherControls) => {
        for (const item of otherControls) {
          item.updateValueAndValidity({ emitEvent: false });
        }
      });
    subs.add(sub);
  }

  return subs;
}