import { createStore, createEvent, sample } from 'effector';
import { ComponentsDataState } from './types';

export const $componentsDataStore = createStore<ComponentsDataState>({});

// public events
export const setDataByIdEvent = createEvent<{ id: string, data: unknown }>();
export const deleteDataByIdEvent = createEvent<{ id: string }>();

// @ts-ignore
// actions
sample({
  clock: setDataByIdEvent,
  source: $componentsDataStore,
  fn: (state, { id, data }) => ({ ...state, [id]: data }),
  target: $componentsDataStore,
});

sample({
  clock: deleteDataByIdEvent,
  source: $componentsDataStore,
  fn: (state, { id }) => {
    const newState = { ...state };
    delete newState[id];
    return newState;
  },
  target: $componentsDataStore,
});
