import {
    Component,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
    EventEmitter,
    Output,
} from '@angular/core';
import { Subscription } from 'rxjs';

import {
    ColumnSelect,
    ColumnSelectLabel
} from '@common/facet';
import { DroppableEvent } from '../../../common/droppable-event';
import {
    uniqueArrayFromPropertyPath,
} from '../../../common/util';

import { JobPharmaDetailService } from '../services/job-pharma-detail.service';
import { JobService } from '../../../jobs/job.service';
import { LoggingService } from '../../../services/logging.service';
import { ProtocolService } from '../../../protocol/protocol.service';
import { TaskType } from '../../../tasks/models';
import { TaskService } from '../../../tasks/task.service';
import {
    ViewAddProtocolComponentService
} from '../../../tasks/add-task/view-add-protocol-component.service';
import {
    ViewAddTaskComponentService
} from '../../../tasks/add-task/view-add-task-component.service';
import { VocabularyService } from '../../../vocabularies/vocabulary.service';
import { WorkflowService } from '../../../workflow/services/workflow.service';
import { SaveChangesService } from '../../../services/save-changes.service';

import { DataManagerService } from "../../../services/data-manager.service";
import { WebApiService } from "../../../services/web-api.service";
import { DialogService, isActionClicked } from '@common/dialog/deprecated';

import { ProtocolDateCalculator } from '../../../tasks/tables/protocol-date-calculator';
import { EntityQuery, Predicate } from 'breeze-client';
import { TaskModalAction, TaskModalActionType, TaskModalComponent } from '../../../tasks/task-modal.component';
import { IFacet } from '../../../common/facet';
import type { Animal, Cohort, Entity, Job, ExtendedJob, ProtocolInstance, TaskCohort, TaskInstance, TaskMaterial, TaskJob, Material, TaskPlaceholder } from '@common/types';
import { JobPharmaTableService } from '../services/job-pharma-table.service';
import { ExtendedTaskInstance } from "../models/extended-task-instance";
import { pluralize } from '@common/util/pluralize';

// Status counts for groups
class StatusCount {
    endState = 0;
    total = 0;

    get done(): boolean {
        return (this.total > 0) && (this.endState === this.total);
    }
}

interface StatusCountMap {
    [index: number]: StatusCount;
}

export class SaveRecordsOverlayEvent {
    state: boolean;
    message?: string;
}

@Component({
    selector: 'job-pharma-tasks-list-table',
    templateUrl: './job-pharma-tasks-list-table.component.html',
})
export class JobPharmaTasksListTableComponent implements OnChanges, OnDestroy, OnInit {
    @Input() readonly: boolean;
    @Input() job: Entity<Job & ExtendedJob>;
    @Input() numItemsExpanded: number;

    @Input() tabset = 'tasks';
    @Input() tab = 'list';
    @Input() isCRO: boolean;
    @Input() isCRL: boolean;
    @Input() isGLP: boolean;
    @Input() isStudyDirector: boolean;
    @Input() facet: IFacet;

    @Output() busy: EventEmitter<SaveRecordsOverlayEvent> = new EventEmitter<SaveRecordsOverlayEvent>();

    loading = false;
    loadingMessage = "Loading";

    taskPage = 1;

    // Data Output Events
    @Output() materialAdd: EventEmitter<any> = new EventEmitter<any>();

    // Tasks to show in the table
    tasks: ExtendedTaskInstance[] = [];

    // Column selections
    columnSelect: ColumnSelect = {
        model: [],
        labels: [],
    };

    // Visible columns
    visible: any = {};

    // Are all the rows selected?
    allSelected = false;
    allLocked = false;

    // All subscriptions
    subs = new Subscription();

    defaultTaskStatusKey: any;

    readonly COMPONENT_LOG_TAG = 'job-pharma-tasks-list-table';
    readonly SAVING_MESSAGE = 'Saving. It may take a minute or longer to save a large number of records.';

    constructor(
        private jobPharmaDetailService: JobPharmaDetailService,
        private jobPharmaTableService: JobPharmaTableService,
        private jobService: JobService,
        private loggingService: LoggingService,
        public protocolService: ProtocolService,
        public taskService: TaskService,
        private vocabularyService: VocabularyService,
        private viewAddProtocolComponentService: ViewAddProtocolComponentService,
        private viewAddTaskComponentService: ViewAddTaskComponentService,
        private workflowService: WorkflowService,
        private saveChangesService: SaveChangesService,
        private dataManager: DataManagerService,
        private webApiService: WebApiService,
        private dialogService: DialogService,
    ) {
        // Do nothing
    }

    async ngOnInit() {
        this.loading = true;
        this.initChangeDetection();
        this.initColumnSelect();
        await this.initialize();
        this.initTabActions();
        this.loading = false;
    }

    ngOnChanges(changes: any) {
        if (changes.job && !changes.job.firstChange) {
            this.initJob();
        }
    }

    ngOnDestroy() {
        // Clear all the subscriptions
        this.subs.unsubscribe();
    }


    /**
     * Watch for external changes
     */
    initChangeDetection() {
        // Watch for changes to job.TaskJob
        this.subs.add(
            this.jobPharmaDetailService.jobTasksChanged$.subscribe(() => {
                this.initJob();
            })
        );
    }

    /**
     * Watch for event between the tabs
     */
    initTabActions() {
        // Listen for calls to refresh the view
        this.subs.add(
            this.jobPharmaDetailService.tabRefresh$.subscribe((event) => {
                if (event.tabset === 'tasks' && event.tab === 'list') {
                    this.initJob();
                }
            })
        );
    }

    /**
     * Initialize the column selections
     */
    initColumnSelect() {
        // Default Visibility
        this.visible = {
            protocol: true,
            taskAlias: true,
            cohorts: true,
            individualAnimals: true,
            sampleGroups: true,
            individualSamples: true,
            status: true,
        };

        // Assemble the list of all columns that can be selected
        this.columnSelect.labels = [
            new ColumnSelectLabel('protocol', 'Protocol'),
            new ColumnSelectLabel('taskAlias', 'Task'),
            new ColumnSelectLabel('cohorts', 'Cohort'),
            new ColumnSelectLabel('individualAnimals', 'Individual Animal'),
            new ColumnSelectLabel('sampleGroups', 'Sample Group'),
            new ColumnSelectLabel('individualSamples', 'Individual Sample'),
            new ColumnSelectLabel('status', 'Progress'),
        ];

        this.columnSelect.model = this.columnSelect.labels.filter(
            (item) => this.visible[item.key]
        ).map((item) => item.key);

        // Register the columns
        this.subs.add(
            this.jobPharmaDetailService.registerColumnSelect(
                this.tabset, this.tab, this.columnSelect,
                () => { this.updateVisible(); }
            )
        );

        // Update the column visiblility
        this.updateVisible();
    }

    /**
     * Update the column visibility flags.
     */
    updateVisible() {
        // Make a lookup table
        const selected = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });

        // Update the visibilty based on the column selections
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;
            this.visible[key] = (selected[key] === true);
        });
    }

    initialize(): Promise<any> {
        return this.getCVs().then(() => {
            return this.initJob();
        });
    }

    async getCVs(): Promise<any> {
        await Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_TaskStatuses'),
        ]);
        const preferLocal = true;
        const status = await this.vocabularyService.getCVDefault(
            'cv_TaskStatuses', preferLocal
        );
        if (status) {
            this.defaultTaskStatusKey = status.C_TaskStatus_key;
        }
    }

    /**
     * Initialize the Job-related values.
     */
    async initJob(): Promise<void> {
        this.loading = true;

        const taskExpands = [
            // Need the status IsEndState when counting
            'cv_TaskStatus',
            // For Animals
            'TaskCohort.Cohort',
            'TaskMaterial.Material.Animal',
            'Job.TaskJob.TaskInstance.TaskCohort',
            // For Samples
            'SampleGroup',
            'SampleGroup.SampleGroupSourceMaterial',
            'MemberTaskInstance.TaskMaterial',
            // For Placeholder
            'TaskPlaceholder',
            'TaskPlaceholder.Placeholder.JobCohort',
            'TaskPlaceholder.TaskPlaceholderInput',
            // For Inputs
            'TaskInput.Input.cv_DataType',
            'TaskInput.Input.cv_DosingTable',
            'TaskInput.Input.cv_JobCharacteristicType'
        ];

        try {
            const loadTaskInstancesPromise = this.jobPharmaDetailService.loadTaskInstances(this.job.C_Job_key);
            // Prepare the tasks
            const loadTaskJobsPromise = this.jobPharmaDetailService.loadTaskJobs(this.job.C_Job_key);
            await Promise.all([loadTaskInstancesPromise, loadTaskJobsPromise]);

            const tasks = await this.jobPharmaDetailService.initTasks(this.job, taskExpands);

            await this.jobPharmaDetailService.initStatusCounts(tasks, this.job);
            this.tasks = this.initRows(tasks);
            this.isLockedChanged();
        } finally {
            this.loading = false;
        }
    }

    /**
     * Count the total and completed tasks in each group
     *
     * @param tasks tasks that will be displayed
     */
    async initStatusCounts(tasks: ExtendedTaskInstance[]) {
        if (!this.job.TaskJob) {
            // Nothing to count
            return Promise.resolve();
        }

        const counts: StatusCountMap = {};

        const query = new EntityQuery('TaskJobs')
            .where('C_Job_key', 'eq', this.job.C_Job_key);

        // Get updated TaskJob
        const taskJobs = await this.dataManager.returnQueryResults(query);
        if (this.job.TaskJob.length < taskJobs.length) {
            for (const taskJob of taskJobs) {
                const taskJobOld = this.job.TaskJob.find((item: any) => {
                    return item.C_TaskJob_key === taskJob.C_TaskJob_key;
                });
                if (taskJobOld == null) {
                    this.job.TaskJob.push(taskJob);
                }
            }
        }
        // Go through all the tasks for this Job and count them by group
        for (const jobTask of this.job.TaskJob) {
            if (!jobTask.TaskInstance) {
                // Weird...
                continue;
            }

            const task = jobTask.TaskInstance;

            if (task.IsGroup) {
                // Don't count the groups themselves
                continue;
            }

            // Which group does this task belong to.
            // Ungrouped tasks belong to themselves.
            const key = task.C_GroupTaskInstance_key || task.C_TaskInstance_key;

            let count = counts[key];
            if (!count) {
                // Initialize a new counter for this group
                counts[key] = count = new StatusCount();
            }

            // Increment the counters
            count.total += 1;
            if (task.cv_TaskStatus && task.cv_TaskStatus.IsEndState) {
                count.endState += 1;
            }
        }
        // Assign the group counts to the tasks that will appear in the table
        for (const task of tasks) {
            task.statusCount = counts[task.C_TaskInstance_key] || new StatusCount();
        }
    }

    private initRows(tasks: any[]): any[] {
        for (const task of tasks) {
            if (!task.SampleGroup || (task.SampleGroup.length === 0)) {
                task.SampleGroupTypes = '';
                task.numSources = null;
                task.numSourceAnimals = null;
                task.numSourceSamples = null;
                task.numSourceAnimalPlaceholders = null;
                continue;
            }

            // Count the sources in the task
            const sources = this.jobPharmaDetailService.getTaskSourceMaterials(task);
            let numSourceAnimals = 0;
            let numSourceSamples = 0;
            let numSourceAnimalPlaceholders = 0;

            task.SampleGroupTypes = '';
            for (const sampleGroup of task.SampleGroup) {
                if (sampleGroup.cv_SampleType) {
                    task.SampleGroupTypes += sampleGroup.cv_SampleType.SampleType + ', ';
                }
                numSourceAnimals += sampleGroup.SampleGroupSourceMaterial.filter((sm: any) => sm.Material && sm.Material.Animal !== null).length;
                numSourceSamples += sampleGroup.SampleGroupSourceMaterial.filter((sm: any) => sm.Material && sm.Material.Sample !== null).length;
                numSourceAnimalPlaceholders += sampleGroup.SampleGroupSourceMaterial.filter((sm: any) => sm.AnimalPlaceholder && !sm.AnimalPlaceholder.Material).length;
            }
            task.SampleGroupTypes = task.SampleGroupTypes
                .substring(0, task.SampleGroupTypes.length - 2);
            task.numSourceAnimals = numSourceAnimals;
            task.numSourceSamples = numSourceSamples;
            task.numSourceAnimalPlaceholders = numSourceAnimalPlaceholders;
            task.numSources = numSourceAnimals + numSourceSamples + numSourceAnimalPlaceholders;
        }
        return tasks;
    }

    /**
     * The Select/Clear All button was clicked
     */
    allSelectedChanged() {
        // Select or unselect all the rows
        if (this.tasks) {
            for (const task of this.tasks) {
                task.isSelected = this.allSelected;
            }
        }
    }


    allLockedChanged() {
        // locked or unlocked all the rows
        if (this.tasks) {
            for (const task of this.tasks) {
                task.IsLocked = this.allLocked;
            }
        }
    }

    /**
     * A row selection checkbox was clicked.
     */
    isSelectedChanged() {
        // Check if all the rows are selected
        this.allSelected = this.tasks.every((task) => task.isSelected);
    }

    isLockedChanged() {
        // Check if all the rows are locked
        this.allLocked = this.tasks.every((task) => task.IsLocked);
    }

    /**
     * Add tasks for a Protocol selected through the modal.
     *
     * Note: The add-protocol component provides the initial values for the
     * TaskInstances.
     */
    async addProtocolViaModal() {
        // FORCE SAVE BEFORE ADD PROTOCOL
        if (this.canSave) {
            let updatePlaceholders = false;
            if (this.job.JobID === null || this.job.JobID.length === 0) {
                updatePlaceholders = true;
            }
            this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG).then(() => {
                if (updatePlaceholders) {
                    this.jobPharmaDetailService.updatePlaceholderNames(this.job);
                }
            });
        }

        // Show the Add Protocol modal
        const protocolKey = await this.viewAddProtocolComponentService.openComponent(TaskType.Job);
        if (!protocolKey) {
            return;
        }
        const protocol = await this.protocolService.getProtocol(protocolKey);
        try {
            this.busy.emit({ state: true, message: this.loadingMessage });
            await this.jobPharmaDetailService.addProtocols(this.job, [protocol]);
        } finally {
            this.busy.emit({ state: false, message: this.loadingMessage });
        }
    }

    /**
     * Add tasks selected through the modal.
     *
     * Note: The add-task modal returns the initial values for the selected
     * tasks.
     */
    async addTasksViaModal() {
        // FORCE SAVE BEFORE ADD TASK
        if (this.canSave) {
            let updatePlaceholders = false;
            if (this.job.JobID === null || this.job.JobID.length === 0) {
                updatePlaceholders = true;
            }
            this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG).then(() => {
                if (updatePlaceholders) {
                    this.jobPharmaDetailService.updatePlaceholderNames(this.job);
                }
            });
        }

        const taskValues = await this.viewAddTaskComponentService.openComponent(TaskType.Job);
        try {
            this.busy.emit({ state: true, message: this.loadingMessage });
            await this.jobPharmaDetailService.addTasks(this.job, taskValues);
        } finally {
            this.busy.emit({ state: false, message: this.loadingMessage });
        }
    }

    /**
     * Handle drop events
     */
    async onDrop(event: DroppableEvent): Promise<any> {
        try {
            this.busy.emit({ state: true, message: this.loadingMessage });
            await this.jobPharmaTableService.onDropToJob(this.job);
        } finally {
            this.busy.emit({ state: false, message: this.loadingMessage });
        }
    }

    /**
     * Create a new ProtocolInstance
     */
    addProtocolInstance(protocol: any) {
        // Get all the existing task instances to help make the next alias
        let allTaskInstances = uniqueArrayFromPropertyPath(
            this.job, 'TaskJob.TaskInstance'
        );
        // Filter to have only parent task instances
        allTaskInstances = allTaskInstances.filter((task) => {
            return task && !task.C_GroupTaskInstance_key;
        });

        // Get protocol instances to help make the next alias
        const allProtocolInstances = uniqueArrayFromPropertyPath(
            allTaskInstances, 'ProtocolInstance'
        );

        // Create a new instance
        return this.taskService.createProtocolInstance({
            C_Protocol_key: protocol.C_Protocol_key,
            ProtocolAlias: this.taskService.generateProtocolAlias(
                allProtocolInstances, protocol
            ),
        });
    }

    /**
     * Delete task instance from job
     * @param task
     */
    async removeTask(task: any) {
        while (task.MemberTaskInstance.length > 0) {
            this.workflowService.deleteTask(task.MemberTaskInstance[0]);
        }
        this.workflowService.deleteTask(task);
        this.jobPharmaDetailService.tabRefresh("tasks", "list");
        this.loggingService.logSuccess('Task Deleted', null, 'jobs', true);
        this.busy.emit({ state: false, message: this.loadingMessage });
    }

    /**
     * Deletes task instances that are selected from a job
     */
    removeTasks() {
        // Count selected tasks
        const selectedTasks = this.tasks.filter((task: any) => task.isSelected);
        const isSelectedTasksLength = selectedTasks.length;
        const selectedTasksAmount = isSelectedTasksLength || this.tasks.length;
        // Count eligible tasks
        const eligibleTasks = selectedTasks.filter((task) => {
            // return isSelectedTasksLength ? this.canRemoveTask(task) && task.isSelected : this.canRemoveTask(task);
            return this.canRemoveTask(task);
        }).length;

        const tasksInWorkflowMessage = eligibleTasks !== selectedTasksAmount ? 'The remaining tasks have associated workflow data and/or sample groups and cannot be deleted.' : '';
        const message = `${eligibleTasks} ${pluralize(eligibleTasks, 'task')} out of ${selectedTasksAmount} ${pluralize(selectedTasksAmount, 'task')} can be deleted. ${tasksInWorkflowMessage} Do you wish to continue?`;
        const options = {
            title: 'Delete Tasks',
            bodyText: message,
        };
        this.dialogService.confirmDelete(options).then((result) => {
            if (result) {
                selectedTasks.forEach((task) => {
                    if (this.canRemoveTask(task) && task.SampleGroup?.length === 0) {
                        while (task.MemberTaskInstance?.length > 0) {
                            this.workflowService.deleteTask(task.MemberTaskInstance[0]);
                        }
                        this.workflowService.deleteTask(task);
                    }
                });
                this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
                this.jobPharmaDetailService.tabRefresh("tasks", "list");
                this.loggingService.logSuccess('Eligible Tasks Deleted', null, 'jobs', true);
                this.busy.emit({ state: false, message: this.loadingMessage });
            }
        }).catch((error: any) => {
            console.error(error);
        });
    }

    get canSave(): boolean {
        return this.saveChangesService.hasChanges && !this.saveChangesService.saving;
    }

    async onDropToProtocolInstance(protocolInstance: ProtocolInstance, isLocked: any, event: DroppableEvent): Promise<any> {
        try {
            this.busy.emit({ state: true, message: this.loadingMessage });
            return await this.jobPharmaTableService.onDropToProtocolInstance(this.job, protocolInstance, isLocked, event);
        } finally {
            this.busy.emit({ state: false, message: this.loadingMessage });
        }
    }

    async onDropToTask(task: TaskInstance, event: DroppableEvent): Promise<any> {
        try {
            this.busy.emit({ state: true, message: this.loadingMessage });
            return await this.jobPharmaTableService.onDropToTask(this.job, task, event);
        } finally {
            this.busy.emit({ state: false, message: this.loadingMessage });
        }
    }

    /**
     * Check if cohort has already been added to a protocol
     */
    checkForDupCohorts(protocolInstance: any, entities: any[]): any {
        let taskCohorts: any[];
        let cohorts: any[];
        let duplicates: any[];

        for (const task of protocolInstance.TaskInstance) {
            taskCohorts = task.TaskCohort;
            cohorts = taskCohorts.map((taskCohort) => taskCohort.Cohort);
            duplicates = entities.filter((entity) => {
                return cohorts.includes(entity);
            });
        }

        return duplicates;
    }

    async onRemoveAnimal(task: Entity<TaskInstance>, animal: Entity<Animal>) {
        try {
            this.busy.emit({ state: true, message: this.loadingMessage });
            await this.jobPharmaDetailService.removeAnimal(this.job, task, animal);
        } finally {
            this.busy.emit({ state: false, message: this.loadingMessage });
        }
    }

    async onRemoveCohort(task: Entity<TaskInstance>, cohort: Entity<Cohort>, taskCohort: Entity<TaskCohort>) {
        try {
            this.busy.emit({ state: true, message: this.loadingMessage });
            await this.jobPharmaDetailService.removeCohort(this.job, task, cohort, taskCohort);
        } finally {
            this.busy.emit({ state: false, message: this.loadingMessage });
        }
    }

    changeTaskPage(newPage: number) {
        this.taskPage = newPage;
    }

    /**
     * Calculate the DateDue based on Material field(s)
     *
     * @param taskInstanceKey
     */
    calculateMaterialDueDatesFromTaskKey(taskInstance: any) {
        const protocolDateCalculator = new ProtocolDateCalculator();
        const allTasks = this.getAllTaskInstances();
        const protocolInstanceTasks = this.getTasksInSameProtocol(taskInstance, allTasks);
        protocolDateCalculator.scheduleMaterialDueDates(protocolInstanceTasks, taskInstance);
    }

    getTaskInstanceByKey(taskInstanceKey: number): any[] {
        const allTasks = this.getAllTaskInstances();
        return allTasks.find((item) => {
            return item.C_TaskInstance_key === taskInstanceKey;
        });
    }

    getTaskInstanceByGroupKey(taskInstanceKey: number): any[] {
        const allTasks = this.getAllTaskInstances();
        return allTasks.find((item) => {
            return item.C_TaskInstance_key === taskInstanceKey;
        });
    }

    getAllTaskInstances(): any[] {
        return uniqueArrayFromPropertyPath(this.tasks, 'TaskInstance');
    }

    getTasksInSameProtocol(taskInstance: any, allTasks: any[]): any[] {
        return allTasks.filter((task) => {
            return task.C_ProtocolInstance_key === taskInstance.C_ProtocolInstance_key;
        });
    }

    async onRemoveSample(taskMaterial: TaskMaterial) {
        try {
            this.busy.emit({ state: true, message: this.loadingMessage });
            await this.jobPharmaDetailService.removeSample(this.job, taskMaterial);
            this.calculateMaterialDueDatesFromTaskKey(taskMaterial.C_TaskInstance_key);
        } finally {
            this.busy.emit({ state: false, message: this.loadingMessage });
        }
    }



    getTaskDeleteTitle(task: any) {
        if (this.hasSampleGroups(task)) {
            return 'First delete associated sample group(s)';
        } else {
            return 'Remove Task';
        }
    }

    hasSampleGroups(task: TaskInstance): boolean {
        return task.SampleGroup.length > 0;
    }

    onRemovePlaceholder(taskPlaceholder: TaskPlaceholder, task: TaskInstance) {
        this.jobPharmaDetailService.removePlaceholder(taskPlaceholder, task);
    }

    onRemoveAnimalPlaceholder(taskPlaceholder: TaskPlaceholder, task: TaskInstance) {
        this.jobPharmaDetailService.removeAnimalPlaceholder(taskPlaceholder, task);
    }

    taskDontHaveMatchingCohort(placeholder: any, taskCohort: any[]) {
        return this.jobPharmaDetailService.taskDontHaveMatchingCohort(placeholder, taskCohort);
    }

    taskDontHaveMatchingAnimal(placeholder: any, taskMaterial: any[]) {
        return this.jobPharmaDetailService.taskDontHaveMatchingAnimal(placeholder, taskMaterial);
    }

    canRemoveAnyTask(): boolean {
        const selectedTasks = this.tasks.filter((task: any) => task.isSelected);
        if (selectedTasks.length === 0) {
            return false;
        }
        for (const task of this.tasks) {
            if (this.canRemoveTask(task)) {
                return true;
            }
        }
        return false;
    }

    canRemoveTask(task: any): boolean {
        return !this.readonly &&
            !task.IsLocked &&
            !task.DateComplete &&
            task.statusCount?.endState === 0 &&
            !this.hasSampleGroups(task) &&
            (task.TaskOutputSet && task.TaskOutputSet.length === 0) &&
            (!task.ProtocolInstance || task.ProtocolInstance.UsedInProtocol !== true) &&
            !task.cv_TaskStatus?.IsEndState;
    }

    async editTasksViaModal(task: any): Promise<void> {
        try {
            this.loadingMessage = 'Loading';
            this.busy.emit({ state: true, message: this.loadingMessage });
            const expands = [
                'WorkflowTask.Input.cv_DataType',
                'WorkflowTask.Input.cv_DosingTable',
                'WorkflowTask.Input.EnumerationClass',
                'WorkflowTask.Input.cv_JobCharacteristicType',
                'WorkflowTask.Output.cv_DataType',
                'WorkflowTask.Output.EnumerationClass',
                'WorkflowTask.Output.OutputFlag'
            ];
            await this.dataManager.ensureRelationships([task], expands);

            const customizeComponent = (component: TaskModalComponent) => {
                component.task = task.WorkflowTask;
                component.taskAlias = task.TaskAlias;
                component.taskAlias = task.TaskAlias;
                component.facet = this.facet;
                component.getTotalTasks = () => this.getTotalTasks(task.C_TaskInstance_key, this.job.C_Job_key);
            };
            const action = await this.dialogService.openComponent<TaskModalAction>(TaskModalComponent, {
                customizeComponent,
                systemOptions: {
                    backdrop: 'static',
                    keyboard: false,
                },
            });
            if (!isActionClicked(action)) {
                return;
            }

            const { type, task: editedTask } = action.result;

            await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
            const isUpdate = type === TaskModalActionType.ApplyToTask;
            await this.applyTask(task.C_TaskInstance_key, this.job.C_Job_key, editedTask.C_WorkflowTask_key, isUpdate);
            task.TaskInput.forEach((ti: any) => {
                this.dataManager.detachEntity(ti);
            });
            return await this.initialize(); // refresh data
        } catch (error) {
            console.error(error);
        } finally {
            this.loadingMessage = '';
            this.busy.emit({ state: false, message: this.loadingMessage });
        }
    }

    getTotalTasks(taskInstanceKey: number, jobKey: number): Promise<any> {
        this.loadingMessage = 'Loading';
        this.busy.emit({ state: true, message: this.loadingMessage });
        const apiUrl = 'api/jobdata/numberOfTasksToEdit?taskInstanceKey=' + taskInstanceKey + '&jobKey=' + jobKey;

        return this.webApiService
            .callApi(apiUrl)
            .then((result) => {
                this.loadingMessage = "";
                this.busy.emit({ state: false, message: this.loadingMessage });
                return result.data;
            })
            .catch((error: any) => {
                console.error(error);
                this.loadingMessage = "";
                this.busy.emit({ state: false, message: this.loadingMessage });
            });
    }

    applyTask(
        taskKey: number,
        jobKey: number,
        workflowKey: number,
        update: boolean
    ): Promise<any> {
        this.loadingMessage = this.SAVING_MESSAGE;
        this.busy.emit({ state: true, message: this.loadingMessage });

        const requestBody = {
            C_TaskInstance_key: taskKey,
            C_WorkflowTask_key: workflowKey,
            C_Job_key: jobKey,
            UpdateThisInstanceOnly: update
        };

        return this.webApiService
            .postApi("api/jobdata/editTaskDefinition", requestBody)
            .then((result) => {
                this.loadingMessage = "";
                this.jobPharmaDetailService.tabRefresh('job', 'main');
                this.busy.emit({ state: false, message: this.loadingMessage });
                return result.data;
            })
            .catch((error: any) => {
                console.error(error);
                this.loadingMessage = "";
                this.busy.emit({ state: false, message: this.loadingMessage });
            });
    }
}

