<h4>
  <span>Task Output Pools</span>
  <i class="fa fa-info-circle ml-1" [title]="TITLE"></i>
</h4>
<form #form="ngForm">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th>Name</th>
        <th>Task(s)</th>
        <th>Numeric Output(s)</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let value of values; let i = index">
        <tr ngModelGroup="{{i}}">
          <td>
            <div class="cell-content">
              <input
                name="name"
                #name="ngModel"
                class="form-control input-medium"
                placeholder="Enter Name"
                [(ngModel)]="value.SettingTaskOutputPoolName"
                [disabled]="readonly"
              />
              <span *ngIf="name.invalid" class="text-danger">
                {{ name.errors?.name || name.errors?.required || name.errors?.maxLength }}
              </span>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <climb-multiselect-control
                #tasks="ngModel"
                name="tasks"
                placeholder="Select Task(s)"
                [formatter]="taskOutputPools.formatterTasks"
                [search]="taskOutputPools.searchTasks"
                [ngModel]="getTasks(value.SettingTaskOutputPoolWorkflowTasks)"
                (ngModelChange)="tasksChange(value.C_SettingTaskOutputPool_key, $event)"
                [disabled]="readonly"
              ></climb-multiselect-control>
              <span *ngIf="tasks.invalid" class="text-danger">
                {{ tasks.errors?.required || tasks.errors?.maxLength }}
              </span>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <climb-multiselect-control
                #outputs="ngModel"
                name="outputs"
                placeholder="Select Numeric Output(s)"
                [formatter]="taskOutputPools.formatterOutputs"
                [search]="taskOutputPools.searchOutputs(getTasks(value.SettingTaskOutputPoolWorkflowTasks))"
                [ngModel]="getOutputs(value.SettingTaskOutputPoolOutputs)"
                (ngModelChange)="outputsChange(value.C_SettingTaskOutputPool_key, $event)"
                [disabled]="readonly || !getTasks(value.SettingTaskOutputPoolWorkflowTasks).length"
              ></climb-multiselect-control>
              <span *ngIf="outputs.invalid" class="text-danger">
                {{ outputs.errors?.required || outputs.errors?.maxLength }}
              </span>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</form>
