import { Subscription } from 'rxjs';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { Observable, defer, of } from 'rxjs';
import {
    NgbTypeahead,
    NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';

import { ClimbTypeahead } from './climb-typeahead.interface';
import { TypeaheadHelper } from './typeahead-helper';

import {
    BrowserDetection,
    ExpireCache,
    expireCache,
    focusElementByQuery,
    randomId
} from '../util';

import { cacheWithExpiration } from '../observable';


/*
* Component to select data from a typeahead
*
* NOTE: Can select an item based on exact text match, if only 1 result returned
*/
@Component({
    selector: 'climb-typeahead',
    templateUrl: './climb-typeahead-shared.html',
    styleUrls: ['./climb-typeahead-shared.scss'],
})
export class ClimbTypeaheadComponent implements
    ClimbTypeahead, OnChanges, OnDestroy, OnInit, AfterViewInit {
    @ViewChild(NgbTypeahead) ngbTypeahead: NgbTypeahead;

    @Input() model: any;
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    /*
    * search is a function returning observable.
    *   preferably search should be an arrow function
    *   (or else 'this' keyword can't be used.
    */
    @Input() search: (text: string) => Promise<any[]>;
    /*
    * Needed to pre-populate input when key is provided at init time
    */
    @Input() keySearch: (key: string) => Promise<any[]>;
    /*
    * resultFormatter is a function returning a string.
    *   Is used to display results using an object property.
    *
    *   E.g. resultFormatter = (value: any) => { return value.AnimalName; }
    */
    @Input() resultFormatter: (value: any) => string;
    /*
    * keyFormatter is a function to return the model value bound to this input
    * E.g. resultFormatter = (value: any) => { return value.C_Material_key; }
    */
    @Input() keyFormatter: (value: any) => any;

    @Input() resultTemplate: any;

    /*
    * Optional override of default exact match behavior
    */
    @Input() exactMatchFunction: (data: any[], inputText: string) => boolean;

    /*
    * input element placeholder text
    */
    @Input() placeholder: string;
    @Input() required: boolean;
    @Input() disabled: boolean;
    @Input() id: string;

    /*
    * Callback when item is selected
    */
    @Output() selectItem: EventEmitter<any> = new EventEmitter<any>();

    // namespace for caching previously selected values
    @Input() namespace: string;

    @Input() forceValidSelection = true;

    @Input() fieldName: string;

    @Input() container = "";

    @Input() error: boolean;
    /*
    * Callback on blur
    */
    @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();

    @Output() onInitialLoad: EventEmitter<any> = new EventEmitter<any>();

    // state variables
    /*
    *  inputModel is what is bound to the ngdTypeahead.
    *  It is an object from the search results
    */
    inputModel: any;
    typeaheadHelper: TypeaheadHelper;
    _typeaheadCache: ExpireCache = expireCache;

    _keySearchSubscription: Subscription;

    // ten minute cache for key search
    readonly KEY_CACHE_EXPIRATION_MS = 10 * 1000 * 60;
    readonly INSTANT_KEY_CACHE_PROP = '__instantKeyCacheValue__';

    hasInitialLoad = false;

    constructor(public ngZone: NgZone) {
        this.typeaheadHelper = new TypeaheadHelper();
    }

    ngOnInit() {
        if (this.namespace) {
            // set cache to the given namespace
            this._typeaheadCache = expireCache.namespace(
                this.namespace, this.KEY_CACHE_EXPIRATION_MS
            );
        }

        if (!this.fieldName) {
            this.fieldName = "typeahead_input_" + randomId();
        }

        if (!this.id) {
            this.id = "typeahead_input_" + randomId();
        }

        // if no result formatter, just display raw search results
        if (!this.resultFormatter) {
            this.resultFormatter = (value: any) => value;
        }

        if (!this.keyFormatter) {
            this.keyFormatter = (value: any) => value;
        }

        if (!this.placeholder ||
            // IE 'input' event with placeholder text causes bad behavior.
            BrowserDetection.isIE()
        ) {
            this.placeholder = '';
        }

        this.inputModel = null;

        if (this.model) {
            // pre-load display values from cache if possible
            const cachedValue = this.getInstantCacheValueFromKey(this.model);
            if (cachedValue) {
                this.setDisplayValues(cachedValue);
            } else {
                this.loadModelFromKey();
            }
        } else {
            this.handleInitialLoad();
        }
    }

    ngAfterViewInit() {
        this.typeaheadHelper.setNgbTypeahead(this.ngbTypeahead);
    }

    ngOnChanges(changes: any) {
        if (changes.model && !changes.model.firstChange) {

            // cancel any pending key searches so we don't corrupt model cache
            //   with the new model key
            if (this._keySearchSubscription) {
                this._keySearchSubscription.unsubscribe();
            }

            if (!this.model) {
                this.clearInputField();
            } else if (this.model) {
                this.loadModelFromKey();
            }
        }
    }

    ngOnDestroy() {
        if (this._keySearchSubscription) {
            this._keySearchSubscription.unsubscribe();
        }
    }

    onBlurHandler(event: Event) {
        if (this.forceValidSelection) {
            this.validateInputModel();
        }
        this.onBlur.emit(event);
    }

    onClickHandler(event: Event) {
        // Temporarily disable, because it breaks bar-code scanning
        // this.typeaheadHelper.triggerTypeahead();
    }

    onClearClickHandler() {
        this.clearInputField();
        this.clearModel();
        focusElementByQuery('[name="' + this.fieldName + '"]');
        this.selectItem.emit(this.model);
    }

    onShowClickHandler() {
        this.typeaheadHelper.triggerTypeahead();
    }

    onKeydownHandler(event: KeyboardEvent) {
        this.typeaheadHelper.triggerTypeaheadOnDownArrow(event);
    }

    onModelChange(event: Event) {
        // This functionality is only important when
        //   accepting arbitrary text input
        if (this.forceValidSelection) {
            return;
        }

        if (!this.inputModel) {
            this.inputModel = this._getActualUserInputText();
        }
        this._newSelectedValue();
    }

    selectItemHandler(event: NgbTypeaheadSelectItemEvent) {
        this.inputModel = event.item;
        this._newSelectedValue();
    }

    private _newSelectedValue() {
        this.model = this.keyFormatter(this.inputModel);
        this.modelChange.emit(this.model);
        this.selectItem.emit(this.inputModel);
        this.addSingleItemToCache(this.inputModel, this.model);
    }

    /**
     * observable function for ng-bootstrap typeahead
     */
    searchObservable = (text$: Observable<string>): Observable<any[]> => {
        return this.typeaheadHelper.searchObservable(
            text$,
            this.search,
            this.resultFormatter,
            this.exactMatchFunction
        );
    }

    /**
     * ensure input box text matches currently selected model
     */
    validateInputModel() {
        const userInput = this._getActualUserInputText();
        let inputModel = this.inputModel;

        /*
         * NgbTypeahead frequently clears the inputModel inappropriately.
         * we check the cache if there is still one in memory we can use
         * for comparisons.
         */
        if (!inputModel && this.model) {
            inputModel = this.getInstantCacheValueFromKey(this.model);
        }
        const resultDisplay = inputModel ? this.resultFormatter(inputModel).toString() : '';

        if (!this._stringsMatch(userInput, resultDisplay) ||
            !userInput
        ) {
            this.clearInputField();
            this.clearModel();
            this.selectItem.emit(this.model);
        }
    }

    _stringsMatch(str1: string, str2: string): boolean {
        str1 = str1 ? str1 : '';
        str1 = str1.trim();
        str2 = str2 ? str2 : '';
        str2 = str2.trim();
        return str1 === str2;
    }

    /**
     * Return current text value in <input> element
     */
    _getActualUserInputText(): string {
        let userInput = "";

        // first check if we can inspect element directly
        const jqElement = jQuery("[name='" + this.fieldName + "']");
        const value = jqElement.val();
        if (value) {
            userInput = value + "";
        }

        if (!userInput) {
            // otherwise try value from typeahead component class
            const userInputProp = '_userInput';
            userInput = this.ngbTypeahead[userInputProp];
        }

        return userInput;
    }

    clearInputField() {
        this.setTypeaheadInputField(null);
        this.inputModel = null;
    }

    clearModel() {
        this.model = null;
        this.modelChange.emit(this.model);
    }

    setTypeaheadInputField(value: string) {
        if (this.ngbTypeahead) {
            const userInputProp = '_userInput';
            this.ngbTypeahead[userInputProp] = value;
        }
    }

    loadModelFromKey() {
        if (this._keySearchSubscription) {
            this._keySearchSubscription.unsubscribe();
        }

        const key = this.model;
        // Always check cache first, to avoid unnecessary breeze call
        const cachedSearch$ = this.getObservableFromCache(key);

        let doSearch = Boolean(this.keySearch || cachedSearch$);
        if (this.inputModel) {
            /*
            * NOTE (kevin.stone):
            * For reasons I don't understand,
            *   occasionally inputModel is set to null by the typeahead
            *   leading to an unnecessary call to keySearch() after selection.
            * It doesn't hurt anything, but might slow things down.
            */
            const inputKeyValue = this.keyFormatter(this.inputModel);
            doSearch = doSearch && inputKeyValue !== this.model;
        }

        if (doSearch) {
            let keySearch$ = cachedSearch$;
            if (!keySearch$) {
                keySearch$ = defer(() => {
                    return this.keySearch(key);
                }).pipe(
                    cacheWithExpiration(this.KEY_CACHE_EXPIRATION_MS, this.ngZone)
                );
            }

            this.addObservableToCache(keySearch$, key);
            this._keySearchSubscription = keySearch$.subscribe((results: any[]) => {
                if (results && results.length > 0) {
                    const result = results[0];
                    this.setInstantCacheValueFromKey(result, key);
                    // update model object and display fields
                    this.setDisplayValues(result);
                } else {
                    // remove empty result set from cache
                    this.removeObservableFromCache(key);
                }

            });
        }
    }

    setDisplayValues(item: any) {
        this.inputModel = item;
        const resultDisplay = this.resultFormatter(this.inputModel);
        this.setTypeaheadInputField(resultDisplay);
        if (!this.hasInitialLoad) {
            this.handleInitialLoad();
        }
    }

    /**
     * Add an in-memory item to the key => Observable<[any]> cache
     * @param item
     * @param key
     */
    addSingleItemToCache(item: any, key: any) {
        if (item && key) {
            const keySearch$: Observable<any[]> = of([item]).pipe(
                cacheWithExpiration(this.KEY_CACHE_EXPIRATION_MS)
            );
            this.addObservableToCache(keySearch$, key);
            this.setInstantCacheValueFromKey(item, key);
        }
    }

    /**
     * looks up cached model using key.
     *   Only returns a value if Observable has completed
     * @param key
     */
    getInstantCacheValueFromKey(key: any): any {
        const keySearch$ = this.getObservableFromCache(key);
        if (keySearch$ && keySearch$.hasOwnProperty(this.INSTANT_KEY_CACHE_PROP)) {
            return keySearch$[this.INSTANT_KEY_CACHE_PROP];
        }
        return undefined;
    }

    /**
     * mark this item in the cache as being instantly retrievable
     * @param key
     */
    setInstantCacheValueFromKey(item: any, key: any) {
        if (!item) {
            return;
        }
        const keySearch$ = this.getObservableFromCache(key);
        if (keySearch$) {
            keySearch$[this.INSTANT_KEY_CACHE_PROP] = item;
        }
    }


    addObservableToCache(keySearch$: Observable<any[]>, key: any) {
        if (keySearch$ && key) {
            this._typeaheadCache.set(this._createKeyValue(key), keySearch$);
        }
    }

    /**
     * Looks up cached model observable using key.
     *  Returns the last keySearch run for this key
     * @param key
     */
    getObservableFromCache(key: any): Observable<any> {
        if (!key) {
            return null;
        }

        return this._typeaheadCache.get(this._createKeyValue(key));
    }

    removeObservableFromCache(key: any): void {
        if (!key) {
            return null;
        }
        this._typeaheadCache.set(this._createKeyValue(key), undefined);
    }
    /*
     * Convert value for use as cache key.
    */
    _createKeyValue(value: string) {
        if (value.toLowerCase) {
            return value.toLowerCase();
        } else {
            return value;
        }
    }
    
    handleInitialLoad() {
        this.hasInitialLoad = true;
        this.onInitialLoad.emit();
    }
}
