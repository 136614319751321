import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JobMaterial, Sample, SampleGroup, SampleGroupSourceMaterial, TaskJob } from "@common/types";
import { DataManagerService } from "@services/data-manager.service";
import { EntityQuery, Predicate } from "breeze-client";
import { PartialMaterial } from "../models/partial-material";
import { MaterialType } from "../models/material-type";

export interface SampleGroupMetadata {
    C_SampleGroup_key?: number;
    SamplesCreatedCount?: number;
    NumberOfAnimalSourcesCount?: number;
    NumberOfSampleSourcesCount?: number;
    NumberOfAnimalPlaceholderSourcesCount?: number;
    HasEndStateMemberTask?: boolean;
}

@Injectable()
export class JobPharmaDataAccessService {
    constructor(
        private dataManager: DataManagerService,
        private httpClient: HttpClient
    ) {}
    
    // TODO: Replace Breeze query with GraphQL query
    async getSampleJobMaterials(jobKey: number, pageNumber: number): Promise<JobMaterial[]> {
        const predicates = [
            Predicate.create("Material.Sample.C_Material_key", "!=", null),
            Predicate.create("C_Job_key", "==", jobKey)
        ];
        const expands = [
            "Material.Sample",
            "Material.cv_MaterialType",
            "Material.MaterialSourceMaterial.SourceMaterial.Animal",
            "Material.MaterialSourceMaterial.SourceMaterial.Sample"
        ];
        const query = EntityQuery.from("JobMaterials")
            .expand(expands.join(", "))
            .where(Predicate.and(predicates))
            .skip(50 * (pageNumber - 1))
            .take(50);

        const result = await this.dataManager.executeQuery(query);

        return result.results as unknown as JobMaterial[];
    }

    // TODO: Replace Breeze query with GraphQL query
    async getSampleCount(jobKey: number): Promise<number>{
        const predicates = [
            Predicate.create("Material.Sample.C_Material_key", "!=", null),
            Predicate.create("C_Job_key", "==", jobKey)
        ];
        const expands = [
            "Material.Sample",
            "Material.cv_MaterialType",
        ];
        const query = EntityQuery.from("JobMaterials")
            .expand(expands.join(", "))
            .where(Predicate.and(predicates));

        return this.dataManager.returnQueryCount(query);
    }

    // TOOD: Pagination?
    async getSampleGroups(jobKey: number) {
        const predicates = [
            Predicate.create("C_Job_key", "==", jobKey),
            Predicate.create("TaskInstance.C_GroupTaskInstance_key", "==", null)
        ];
        const expands = [
            "TaskInstance.ProtocolInstance",
            "TaskInstance.SampleGroup"
        ];
        const orders = [
            "TaskInstance.DateDue",
            "Sequence",
            "TaskInstance.C_ProtocolInstance_key",
            "TaskInstance.ProtocolTask.SortOrder",
            "TaskInstance.C_WorkflowTask_key"
        ];

        const query = EntityQuery.from("TaskJobs")
            .expand(expands.join(","))
            .where(Predicate.and(predicates))
            .orderBy(orders.join(','));

        const { results } = await this.dataManager.executeQuery(query);
        const taskJobs = results as unknown as TaskJob[];
        return taskJobs.flatMap(tj => tj.TaskInstance.SampleGroup);
    }

    async getSampleGroupsMetadata(sampleGroupKeys: number[]) {
        if (!sampleGroupKeys || sampleGroupKeys.length === 0) {
            throw new Error('No sample group keys provided');
        }

        const params = { sampleGroupKeys: sampleGroupKeys.join(',') }; 
        const result = await this.httpClient.get('/api/samplegroup/metadata', { params }).toPromise() as SampleGroupMetadata[];

        return result;
    }

    async getSampleGroupSourceMaterials(sampleGroupKeys: number[]) {
        const predicates = [
            Predicate.create("C_SampleGroup_key", "in", sampleGroupKeys)
        ];
        const expands = [
            "Material.Sample",
            "Material.Animal",
            "AnimalPlaceholder.Material.Animal"
        ];

        const query = EntityQuery.from("SampleGroupSourceMaterials")
            .expand(expands.join(','))
            .where(Predicate.and(predicates))
        
        const { results } = await this.dataManager.executeQuery(query);
        return results as unknown as SampleGroupSourceMaterial[];
    }

    async getSamplesCreatedFromSampleGroups(sampleGroupKeys: number[]) {
        const predicates = [
            Predicate.create("C_SampleGroup_key", "in", sampleGroupKeys)
        ];
        const expands = [
            "Material.MaterialSourceMaterial.SourceMaterial",
            "Material.MaterialSourceMaterial.SourceMaterial.Animal",
            "Material.MaterialSourceMaterial.SourceMaterial.Sample",
        ];

        const query = EntityQuery.from("Samples")
            .expand(expands.join(','))
            .where(Predicate.and(predicates))
        
        const { results } = await this.dataManager.executeQuery(query);
        return results as unknown as Sample[];
    }

    removeSampleGroupSourceMaterials(sampleGroupSourceMaterials: SampleGroupSourceMaterial[]) {
        for (const sgsm of sampleGroupSourceMaterials) {
            this.dataManager.deleteEntity(sgsm);
        }
    }

    removeSampleGroup(sampleGroups: SampleGroup[]) {
        for (const sg of sampleGroups) {
            // prevent breeze from updating sample group source material
            const sampleGroupSourceMaterialsToDetach = [...sg.SampleGroupSourceMaterial];
            for (const sgsm of sampleGroupSourceMaterialsToDetach) {
                this.dataManager.detachEntity(sgsm);
            }
            this.dataManager.deleteEntity(sg);
        }
    }

    async getAllMaterialsOnJob(jobKey: number, materialType: MaterialType, ignoredMaterialKeys?: number[]): Promise<PartialMaterial[]> {
        const url = `/api/jobdata/${jobKey}/${materialType}`;
        let params = new HttpParams();
        if (ignoredMaterialKeys?.length > 0) {
            params = params.set("ignoredMaterialKeys", ignoredMaterialKeys.join(','));
        }

        const result = await this.httpClient.get(url, { params }).toPromise() as PartialMaterial[];

        return result;
    }
}