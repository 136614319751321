<div class="facet">
    <facet-header
        [caption]="'Jobs' | translate"
        [facetId]="facetId">
        <button *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="openFilter()"
                title="Filter {{'Jobs' | translate}}">
            <svg [climbIcon]="icons.magnifier"></svg>
        </button>

        <button *ngIf="facetView === LIST_VIEW && isActiveWorkspaceFilterSupported()"
                climbFacetAction
                (click)="clickIgnoreWorkspaceFilter(); reloadTable();"
                [title]="ignoreWorkspaceFilter ? 'Apply Workspace Filter' : 'Ignore Workspace Filter'">
            <svg [climbIcon]="ignoreWorkspaceFilter ? icons.chain : icons.brokenChain"></svg>
        </button>

        <button *ngIf="facetView === LIST_VIEW && !workspaceFilterActive"
                climbFacetAction
                (click)="filterWorkspaceByJob()"
                title="Filter Workspace by Selected {{'Jobs' | translate}}">
            <svg [climbIcon]="icons.viceVersaArrowsHorizontal"></svg>
        </button>

        <button *ngIf="facetView === LIST_VIEW && workspaceFilterActive"
                climbFacetAction
                (click)="clearWorkspaceFilter()"
                title="Stop Filtering Workspace">
            <svg [climbIcon]="icons.brokenChain"></svg>
        </button>

        <button *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>

    <div class="facet-body" *ngIf="facet.Privilege!=='None'">

        <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>

        <ng-container *ngIf="facetView === LIST_VIEW">
            <climb-facet-toolbar>
                <climb-export-dropdown #exportDropdown
                                       [expandExport]="true"
                                       [options]="isCRO ? jobTableOptions.croOptions : jobTableOptions.options"
                                       [dataService]="dataService"
                                       [componentName]="componentName">
                </climb-export-dropdown>

                <climb-column-select [columnDef]="dataTableColumns$ | async"
                                     (onChange)="dataTable.columnSelectChanged($event)">
                </climb-column-select>
            </climb-facet-toolbar>
            <climb-data-table data-automation-id="job-pharma-table"
                              #dataTable
                              [dataService]="dataService"
                              [options]="isCRO ? jobTableOptions.croOptions : jobTableOptions.options"
                              [(gridStateJson)]="facet.GridState"
                              [(selectedRows)]="selectedRows"
                              (selectedRowsChange)="selectedRowsChange($event)"
                              (selectedColumnsChange)="selectedColumnsChange($event)"
                              (addItemClick)="addItemClick()"
                              (detailLinkClick)="detailLinkClick($event)"
                              (columnStateChanged)="dataTableColumns.next($event)"
                              (stateChange)="exportDropdown.tableState = $event">
            </climb-data-table>
        </ng-container>

        <job-pharma-detail *ngIf="facetView === DETAIL_VIEW"
            [isCRO]="isCRO"
            [isGLP]="isGLP"
            [jobDetailsOrder]="jobDetailsOrder"
            [facet]="facet"
            [facetView]="facetView"
            [job]="itemToEdit"
            [pageState]="detailPaginator.pageState"
            [activeFields]="activeFields"
            [requiredFields]="requiredFields"
            [samplesActiveFields]="samplesActiveFields"
            [samplesRequiredFields]="samplesRequiredFields"
            (exit)="exitDetail()"
            (previous)="detailPreviousClicked()"
            (next)="detailNextClicked()"
            (modelCopy)="modelCopied($event)">
        </job-pharma-detail>

    </div>
</div>
