import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';
import { WorkspacesSharedModule } from '../workspaces/workspaces-shared.module';
import { SettingsSharedModule } from './settings-shared.module';
import { SettingsFacetComponent } from './settings-facet.component';
import { FacetSharedModule } from '../common/facet/facet-shared.module';
import { InactivityTimeoutModalComponent } from './modals/inactivity-timeout-modal.component';
import { FacetSettingsComponent } from './facet-settings/facet-settings.component';
import { TasksSharedModule } from '../tasks/tasks-shared.module';
import { WorkgroupAnimalCharacteristicsComponent } from './workgroup-animal-characteristics/workgroup-animal-characteristics.component';
import { TaskOutputPoolsComponent } from './task-output-pools';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        ClimbCommonModule,
        ClimbServicesModule,
        FacetSharedModule,
        SettingsSharedModule,
        TasksSharedModule,
        WorkspacesSharedModule,
    ],
    declarations: [
        TaskOutputPoolsComponent,
        SettingsFacetComponent,
        InactivityTimeoutModalComponent,
        FacetSettingsComponent,
        WorkgroupAnimalCharacteristicsComponent,
    ],
    exports: [
        SettingsFacetComponent,
        InactivityTimeoutModalComponent,
        FacetSettingsComponent,
        WorkgroupAnimalCharacteristicsComponent,
    ]
})
export class SettingsModule { }
