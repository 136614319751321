import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

export const poolsNameValidator = (): ValidatorFn => (control: AbstractControl) => {
  if (!control.value) return null;

  const values = Object.values(control.parent.parent.controls)
    .map((group: FormGroup) => group.controls.name)
    .filter(nameControl => nameControl !== control)
    .map(nameControl => nameControl.value);

  return values.includes(control.value)
    ? { name: "Name should be unique" }
    : null;
}
