import { EventCallable } from 'effector';
import { InjectionToken, NgZone, Provider } from "@angular/core";
import { partial } from "lodash";
import { mappedStore$, mappedStore, StoreWrapper } from "../helpers";
import { Store } from '../types';
import { ComponentsDataState } from './types';
import { $componentsDataStore, deleteDataByIdEvent, setDataByIdEvent } from './components-data';


export * from './types';

export type ComponentDataStore = Store<ComponentsDataState, {
  setDataByIdEvent: EventCallable<{ id: string, data: unknown }>;
  deleteDataByIdEvent: EventCallable<{ id: string }>;
}>;

const Api: ComponentDataStore = {
  __store: $componentsDataStore,
  events: {
    setDataByIdEvent: setDataByIdEvent,
    deleteDataByIdEvent: deleteDataByIdEvent,
  },
  getValue$: partial(mappedStore$, $componentsDataStore),
  getValue: partial(mappedStore, $componentsDataStore),
};

export const COMPONENT_DATA_STORE_TOKEN = new InjectionToken<ComponentDataStore>('COMPONENT_DATA_STORE_TOKEN');
export const COMPONENT_DATA_STORE_PROVIDER: Provider = {
  provide: COMPONENT_DATA_STORE_TOKEN,
  useFactory: (ngZone: NgZone) => {
    const wrapper = new StoreWrapper(ngZone);
    wrapper.setApi(Api as Store);
    return wrapper;
  },
  deps: [NgZone],
};

window.mfStore ??= {};
window.mfStore.componentDataStore = Api;