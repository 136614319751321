import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy, ViewChildren, QueryList, ViewChild, AfterViewInit } from '@angular/core';
import { TaskOutputPoolsService } from './task-output-pools.service';
import { SettingTaskOutputPool, Entity, WorkflowTask, Output, SettingTaskOutputPoolWorkflowTask, SettingTaskOutputPoolOutput } from '@common/types';
import { combineLatest, of, Subscription } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { memoize } from 'lodash';
import { IValidatable, SaveChangesService } from '@services/save-changes.service';
import { FacetView, IFacet } from '@common/facet';
import { FormGroup, NgForm, NgModel } from '@angular/forms';
import { maxLengthValidator } from '@common/validators';
import { poolsNameValidator } from './validators/pools-name.validator';
import { registerRevalidationAfterChangeAny } from './task-output-pools.utils';
import { poolsRowValidator } from './validators/pools-row-item.validator';

@Component({
  selector: 'climb-task-output-pools',
  templateUrl: './task-output-pools.component.html',
  styleUrls: ['./task-output-pools.component.scss'],
  providers: [
    TaskOutputPoolsService,
  ]
})
export class TaskOutputPoolsComponent implements IValidatable, OnInit, AfterViewInit, OnDestroy {
  private readonly subs = new Subscription();
  
  readonly MAX_NAME_LENGTH = 100;
  readonly MAX_SELECTION_LENGTH = 10;
  readonly NAME_ERROR = `Pool Name input is ${this.MAX_NAME_LENGTH} symbols max`;
  readonly SELECTION_ERROR = `Max of ${this.MAX_SELECTION_LENGTH} selections allowed`;

  readonly TITLE = "Creation of these pools tells Climb to plot related outputs from separate tasks on the same chart. Plotting Bodyweight (g) from an animal task and Bodyweight (g) from a study task is an example of this utility.";
  private workflowTasksMap = new Map<number, Entity<WorkflowTask>>();
  private outputsMap = new Map<number, Entity<Output>>();
  
  private entities = new Map<number, Entity<SettingTaskOutputPool>>();

  get values(): Entity<SettingTaskOutputPool>[] {
    return Array.from(this.entities.values());
  }

  @ViewChild('form') form: NgForm;
  // @ViewChildren('group') groupNgModelGroups: QueryList<NgModelGroup>;
  @ViewChildren('name') nameNgModels: QueryList<NgModel>;
  @ViewChildren('tasks') tasksNgModels: QueryList<NgModel>;
  @ViewChildren('outputs') outputsNgModels: QueryList<NgModel>;

  @Input() facet: IFacet;
  @Input() readonly = false;
  readonly facetView: FacetView;

  constructor(
    private cdr: ChangeDetectorRef,
    public taskOutputPools: TaskOutputPoolsService,
    private saveChanges: SaveChangesService,
  ) {}

  ngOnInit(): void {
    this.saveChanges.registerValidator(this);

    this.taskOutputPools.getTaskOutputPools()
      .pipe(
        switchMap((values) => combineLatest([
          of(this.taskOutputPools.preparedEntities(values)),
          this.taskOutputPools.initTasks(values),
          this.taskOutputPools.initOutputs(values),
        ]))
      )
      .subscribe(([entities, tasksMap, outputsMap]) => {
        this.entities = entities;
        this.workflowTasksMap = tasksMap;
        this.outputsMap = outputsMap;
        this.cdr.markForCheck();
      })
  }

  ngAfterViewInit(): void {
    this.initNameValidator();
    this.initSelectionValidator(this.tasksNgModels, this.MAX_SELECTION_LENGTH, this.SELECTION_ERROR);
    this.initSelectionValidator(this.outputsNgModels, this.MAX_SELECTION_LENGTH, this.SELECTION_ERROR);
    this.initNameRevalidation();
    this.initRowRevalidation(this.nameNgModels);
    this.initRowRevalidation(this.tasksNgModels);
    this.initRowRevalidation(this.outputsNgModels);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.saveChanges.unregisterValidator(this);
  }

  private initNameValidator(): void {
    const sub = this.nameNgModels.changes
      .pipe(startWith(this.nameNgModels))
      .subscribe((() => {
        for (const model of this.nameNgModels) {
          model.control.setValidators([
            poolsNameValidator(),
            poolsRowValidator(),
            maxLengthValidator(this.MAX_NAME_LENGTH, this.NAME_ERROR),
          ]);
        }
      }));
    this.subs.add(sub);
  }

  private initSelectionValidator(models: QueryList<NgModel>, maxLength: number, message: string): void {
    const sub = models.changes
      .pipe(startWith(models))
      .subscribe(() => {
        for (const model of models) {
          model.control.setValidators([
            poolsRowValidator(),
            maxLengthValidator(maxLength, message),
          ]);
        }
      });
    this.subs.add(sub);
  }

  private initNameRevalidation(): void {
    let changeSubs = new Subscription();
    const sub = this.nameNgModels.changes
      .pipe(
        startWith(this.nameNgModels),
        map(() => this.nameNgModels.map(model => model.control)),
        debounceTime(100),
      )
      .subscribe((nameControls) => {
        changeSubs.unsubscribe();
        changeSubs = new Subscription()
        changeSubs.add(registerRevalidationAfterChangeAny(nameControls))
      });

    this.subs.add(sub);
  }

  private initRowRevalidation(models: QueryList<NgModel>): void {
    let changeSubs = new Subscription();

    const sub = models.changes
      .pipe(startWith(models), debounceTime(100))
      .subscribe(() => {
        changeSubs.unsubscribe();
        changeSubs = new Subscription();
        for (const model of models) {
          const parentControls = Object.values(model.control.parent.controls);
          changeSubs.add(registerRevalidationAfterChangeAny(parentControls));
        }
      });

    this.subs.add(sub);
  }

  private calculateTasksKey(tasks: Entity<SettingTaskOutputPoolWorkflowTask>[]): string {
    return tasks.map(task => task.C_WorkflowTask_key).join('-');
  }

  getTasks = memoize((tasks: Entity<SettingTaskOutputPoolWorkflowTask>[]): Entity<WorkflowTask>[] => {
    return Array.from(tasks)
      .map(item => this.workflowTasksMap.get(item.C_WorkflowTask_key))
      .filter(Boolean);
  }, (tasks) => this.calculateTasksKey(tasks));

  private calculateOutputsKey(outputs: Entity<SettingTaskOutputPoolOutput>[]): string {
    return outputs.map(output => output.C_Output_key).join('-');
  }

  getOutputs = memoize((outputs: Entity<SettingTaskOutputPoolOutput>[]): Entity<Output>[] => {
    return Array.from(outputs)
      .map(item => this.outputsMap.get(item.C_Output_key))
      .filter(Boolean);
  }, (outputs) => this.calculateOutputsKey(outputs));
 
  tasksChange(key: number, newTasks: Entity<WorkflowTask>[]): void {
    // actualization map
    for (const workflowTask of newTasks) {
      this.workflowTasksMap.set(workflowTask.C_WorkflowTask_key, workflowTask);
    }
    const entity = this.entities.get(key);

    if (!entity) return;

    const newWorkflowTaskKeysSet = new Set(newTasks.map(item => item.C_WorkflowTask_key));
    const tasksMap = new Map<number, Entity<SettingTaskOutputPoolWorkflowTask>>(entity.SettingTaskOutputPoolWorkflowTasks.map(item => [
      item.C_WorkflowTask_key,
      item,
    ]));
    for (const [taskKey, value] of tasksMap.entries()) {
      if (newWorkflowTaskKeysSet.has(taskKey)) {
        // delete from new keys set (because it's already added)
        newWorkflowTaskKeysSet.delete(taskKey);
      } else {
        // delete SettingTaskOutputPoolEntity
        this.taskOutputPools.deleteTask(value)
      }
    }

    // adding all new SettingTaskOutputPoolOutput
    for (const C_WorkflowTask_key of newWorkflowTaskKeysSet) {
      const taskOutputPoolOutput = this.taskOutputPools.createTask({
        C_WorkflowTask_key,
        C_SettingTaskOutputPool_key: entity.C_SettingTaskOutputPool_key,
      });
      entity.SettingTaskOutputPoolOutputs.push(taskOutputPoolOutput);
    }
    this.actualizeOutputsByTasks(key, newTasks);
    this.cdr.detectChanges();
  }

  outputsChange(key: number, newOutputs: Entity<Output>[]): void {
    // actualization map
    for (const output of newOutputs) {
      this.outputsMap.set(output.C_Output_key, output);
    }
    const entity = this.entities.get(key);
    if (!entity) return;

    const newOutputKeysSet = new Set(newOutputs.map(item => item.C_Output_key));
    const outputs = new Map<number, Entity<SettingTaskOutputPoolOutput>>(entity.SettingTaskOutputPoolOutputs.map(item => [
      item.C_Output_key,
      item,
    ]));
    for (const [outputKey, value] of outputs.entries()) {
      if (newOutputKeysSet.has(outputKey)) {
        // delete from new keys set (because it's already added)
        newOutputKeysSet.delete(outputKey);
      } else {
        // delete SettingTaskOutputPoolEntity
        this.taskOutputPools.deleteOutput(value)
      }
    }

    // adding all new SettingTaskOutputPoolOutput
    for (const C_Output_key of newOutputKeysSet) {
      const taskOutputPoolOutput = this.taskOutputPools.createOutput({
        C_Output_key,
        C_SettingTaskOutputPool_key: entity.C_SettingTaskOutputPool_key,
      });
      entity.SettingTaskOutputPoolOutputs.push(taskOutputPoolOutput);
    }
    this.cdr.detectChanges();
  }

  getNames(form: NgForm, control: NgModel): string[] {
    return Object.values(form.controls)
      .map((group: FormGroup) => group.controls.name)
      .filter(nameControl => nameControl !== control.control)
      .map(nameControl => nameControl.value);
  }

  async validate(): Promise<string> {
    this.form.form.updateValueAndValidity();
    const modelsList= [this.nameNgModels, this.tasksNgModels, this.outputsNgModels];
    for (const models of modelsList) {
      for (const model of models) {
        if (model.invalid) {
          const errors = model.errors;
          return errors?.name ?? errors?.required ?? errors?.maxLength ?? '';
        }
      }
    }
    return '';
  }

  private actualizeOutputsByTasks(key: number, newTasks: Entity<WorkflowTask>[]) {
    const tasksKeys = new Set(newTasks.map(item => item.C_WorkflowTask_key));
    
    // filter outputs values in map and call output change
    const outputs = this.entities.get(key).SettingTaskOutputPoolOutputs
      .map(item => this.outputsMap.get(item.C_Output_key))
      .filter(item => tasksKeys.has(item.C_WorkflowTask_key));
    this.outputsChange(key, outputs);
  }
}
