import { BaseDetailService } from '@common/facet/base-detail.service';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    NgZone,
    ChangeDetectorRef,
    QueryList,
    ViewChildren,
    ViewChild,
} from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
    EntityQuery,
    Predicate,
} from 'breeze-client';
import { map, switchMap } from 'rxjs/operators';

import { BaseDetail } from '@common/facet';
import {
    getSafeProp,
    randomId,
    sortObjectArrayByAccessor,
    uniqueArrayOnProperty,
    uniqueArrayFromPropertyPath,
    notEmpty,
    currentMaterialHousingID,
    sortObjectArrayByAccessors,
} from '@common/util';
import { DateFormatterService } from '@common/util/date-time-formatting';

import type { IMultiSelectSettings, IMultiSelectTexts, IMultiSelectOption } from 'ngx-bootstrap-multiselect';
import type { NgxDropdownMultiselectComponent } from 'ngx-bootstrap-multiselect/lib/ngx-bootstrap-multiselect.component';

import {
    ColumnSelect,
    ColumnSelectLabel
} from '@common/facet/components/toolbar-column-select/toolbar-column-select.component';
import { AuthService } from '@services/auth.service';
import { DataManagerService } from '@services/data-manager.service';
import { DATA_TYPES_INHERITED, DATA_TYPES_NUMERIC, DATA_TYPES_VALID, DataType } from '../../data-type';
import { ConfirmService } from '@common/confirm/confirm.service';
import { ExportWorkflowDetailService } from '../services/export-workflow-detail.service';
import { ImportFileDefinitionService } from '../../import/import-file-definition.service';
import { PrintPreviewService } from '@common/services';
import { ResourceService } from '../../resources';
import { SaveChangesService } from '@services/save-changes.service';
import { ScheduleType } from '../../protocol/models';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { WebApiService } from '@services/web-api.service';
import { WorkflowLogicService } from '../services/workflow-logic.service';
import { WorkflowVocabService } from '../services/workflow-vocab.service';
import { WorkflowService } from '../services/workflow.service';
import { WorkspaceService } from '../../workspaces/workspace.service';

import {
    BooleanMap,
    DataRow,
    EntityMap,
    IOColumn,
} from '../models/workflow-bulk-data';
import { WorkflowBulkDataExportService } from '../services/workflow-bulk-data-export.service';
import { CurrentWorkgroupService } from '@services/current-workgroup.service';
import { DotmaticsService } from '../../dotmatics/dotmatics.service';
import { TableSort } from '@common/models';
import { PrivilegeService } from '@services/privilege.service';
import { objectValues } from '@datadog/browser-core';
import { AnimalService } from '../../animals/services/animal.service';
import { DataChangedModalComponent, AnimalCommentsModalComponent } from '../components';
import { NoteService } from '@common/notes';
import { EntityChangeService } from '../../entity-changes/entity-change.service';
import { FeatureFlagService } from '@services/feature-flags.service';
import { ReplaySubject, Subject, Subscription, merge, of } from 'rxjs';
import { debouncePromise } from '@common/util/debounced-promise';
import { TaskType } from '../../tasks/models';
import { ExportType } from '@common/export';
import { ConfirmOptions } from '@common/confirm';
import { ToastrService } from '@services/toastr.service';
import { LogLevel } from '@services/models/log-level';
import { UserService } from '../../user/user.service';
import { convertValueToLuxon } from '@common/util/date-time-formatting/convert-value-to-luxon';
import { DateTime } from 'luxon';
import { TIME_FORMATTER_FORMAT } from '@common/util/date-time-formatting/date-format-constants';
import { ClimbMultiSelectComponent } from '@common/climb-multi-select.component';
import { Animal, Entity, JobMaterial, Sample, TaskCohort, TaskCohortInput, TaskInstance, TaskMaterial, TaskOutput, cv_TimeUnit } from '@common/types';
import { TranslationService } from '@services/translation.service';
import { SettingService } from '../../settings/setting.service';
import { TaxonCharacteristic, TaxonCharacteristicInstance } from '../../common/types';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { OverlayService } from '@services/overlay-service';
import { TaskInstanceService } from '@services/task-instance.service';
import { updateEntity } from 'src/app/entity-changes/update-entity';
import { fromResizeObserver } from './helpers';

export interface BulkValues {
    taskStatusKey: number;
    animalStatusKey: string;
    exitReasonKey: string;

    dateDueTaskAlias: string;
    dateDueTaskAliases: string[];
    dateDue: Date;
    dateDueIncrement: number;
    dateDueUnitKey: string;

    harvestDate: Date;
    sampleUnitKey: string;
    sampleTypeKey: string;
    sampleStatusKey: string;
    samplePreservationMethodKey: string;
    sampleContainerTypeKey: string;
    sampleVolume: number;

    collected: boolean;
    collectedByKey: string;
    collectedTime: Date;

    completed: boolean;
    completedByKey: string;
    completedTime: Date;

    reviewed: boolean;
    reviewedByKey: string;
    reviewedTime: Date;

    force: boolean;

    lock: boolean;

    sampleSubtypeKey: string;
    sampleProcessingMethodKey: string;
    sendTo: string;
    sampleAnalysisMethodKey: string;
    specialInstructions: string;
    lotNumber: string;
}

class BulkDataConfig {
    columns: { [key: string]: BulkDataColumnConfig } = {};
    outputs: { [key: string]: boolean } = {};
}
class BulkDataColumnConfig {
    visible = true;
    freeze: boolean;
}

export interface ImportValues {
    hasErrors: boolean;

    taskKeys: any[];
    outputs: any[];

    includeAnimalNames: boolean;
    includeSampleNames: boolean;

    attachToJob: boolean;
    attachToTasks: boolean;

    errors: string[];
    filename: string;
    inProgress: boolean;
}

/**
 * Determines how long a specific "freeze" column is so it can be properly positioned.
 * @param columnName refers to the name of the Column as it is presented in the Column Selector
 * @param className refers to the class that is applied to the <th> and <td> tags. 
 */
export interface IFreezeWidthMapping {
    columnName: string;
    className: string;
}

export interface TemplateRow {
    key: number;
    animal: Animal;
    sample: Sample;
}

let uniqueID = 0;

@Component({
    selector: 'workflow-bulk-data-entry',
    templateUrl: './workflow-bulk-data-entry.component.html',
    styleUrls: ['./workflow-bulk-data-entry.component.scss']
})

export class WorkflowBulkDataEntryComponent extends BaseDetail
    implements AfterViewInit, OnChanges, OnInit, OnDestroy {
    @Input() taskKeys: any[];
    @Input() facet: any;
    @Input() paginationRandomId: any;
    @Input() visibleColumns: any[] = [];
    @Input() readonly: any = false;
    @Input() importVisible = true;
    @Input() hideCommonValues = true;

    @Output() exit: EventEmitter<void> = new EventEmitter<void>();

    @ViewChildren('th') tableHeaders: QueryList<ElementRef<HTMLElement>>;
    @ViewChildren('dateControl') dateControls: NgModel[];
    @ViewChildren('outputContainer') outputContainers: QueryList<ElementRef>;
    @ViewChild(ClimbMultiSelectComponent) multiSelectComponent: ClimbMultiSelectComponent;
    @ViewChildren('freezeSelect,columnOutputSelect') ngxSelectComponent: QueryList<NgxDropdownMultiselectComponent>;
    @ViewChild('columnOutputSelect') columnOutputSelect: NgxDropdownMultiselectComponent;

    tableSort: TableSort;

    // CVs
    animalStatuses: any[] = [];
    exitReasons: any[] = [];
    resources: any[] = [];
    taskStatuses: any[] = [];
    timeUnits: cv_TimeUnit[] = [];
    sampleTypes: any[] = [];
    sampleStatuses: any[] = [];
    preservationMethods: any[] = [];
    containerTypes: any[] = [];
    volumeUnitTypes: any[] = [];
    sampleSubtypes: any[];
    sampleAnalysisMethods: any[];
    sampleProcessingMethods: any[];

    currentResourceKey: number = null;
    taskDefaultEndStatus: any;
    taskDefaultStatus: any;

    // State
    tasks: any[];
    taskRows: { [key: string]: DataRow[] } = {};

    // Samples that were assigned to tasks. (Excludes output samples in sample groups)
    primarySamples: any[] = [];

    currentTime: Date;
    currentTimeText: string;
    currentTimeInterval: any;
    outputsSelectorLabel = 'Outputs Displayed';
    outputsSelectorClasses = '';

    // Are we busy saving/loading entities?
    busy = 0;

    // Common values to appear above table
    common: { isJobIDColVis: boolean, JobID: null | string, isTaskAliasVis: boolean, TaskAlias: null | string } = {
        isJobIDColVis: false,
        JobID: null,
        isTaskAliasVis: false,
        TaskAlias: null,
    };

    // Table header column spans
    // colspan: number;

    // Bulk filldown placeholders
    bulk: BulkValues = {
        taskStatusKey: null,
        animalStatusKey: null,
        exitReasonKey: null,

        dateDueTaskAlias: null,
        dateDueTaskAliases: [],
        dateDue: new Date(),
        dateDueIncrement: 1,
        dateDueUnitKey: null,

        harvestDate: null,
        sampleUnitKey: null,
        sampleTypeKey: null,
        sampleStatusKey: null,
        samplePreservationMethodKey: null,
        sampleContainerTypeKey: null,
        sampleVolume: null,

        collected: false,
        collectedByKey: null,
        collectedTime: null,

        completed: false,
        completedByKey: null,
        completedTime: null,

        reviewed: false,
        reviewedByKey: null,
        reviewedTime: null,

        force: false,

        lock: false,

        sampleSubtypeKey: null,
        sampleProcessingMethodKey: null,
        sendTo: null,
        sampleAnalysisMethodKey: null,
        specialInstructions: null,
        lotNumber: null
    };

    // All the Input/Output columns to be displayed
    inputColumns: IOColumn[] = [];
    outputColumns: IOColumn[] = [];

    // Are any of the Output columns Inherited?
    hasInherited = false;

    // Table column visibility flags
    visible: BooleanMap = {};

    // Table column output flags
    output: BooleanMap = {};

    // Index of the currently active row
    // TODO: use key-based index, help for *ngFor
    currentIndex = -1;

    // Column Select state
    columnSelect: ColumnSelect = { model: [], labels: [] };

    // Column Freeze state
    columnFreeze: ColumnSelect = { model: [], labels: [] };

    // Column Output state
    columnOutput: ColumnSelect = { model: [], labels: [] };

    get columnOutputSettings(): IMultiSelectSettings {
        return {
            checkedStyle: 'fontawesome',
            fixedTitle: true,
            showCheckAll: true,
            showUncheckAll: true,
            pullRight: true,
            focusBack: false,
            buttonClasses: 'btn btn-secondary ' + this.outputsSelectorClasses,
            minSelectionLimit: 0,
        };
    }
    get columnOutputTexts(): IMultiSelectTexts {
        return {
            checkAll: 'Select all',
            uncheckAll: 'Clear all',
            defaultTitle: this.outputsSelectorLabel,
        };
    }

    // Total Freezable columns
    freezeColumns = new Map<string, { freezed: boolean, offset: number }>([
      ['JobID', { freezed: false, offset: 0 } ],
      ['TaskAlias', { freezed: false, offset: 0 } ],
      ['PhysicalMarker', { freezed: false, offset: 0 } ],
      ['AnimalName', { freezed: false, offset: 0 } ],
      ['MicrochipIdentifier', { freezed: false, offset: 0 } ],
      ['AlternatePhysicalID', { freezed: false, offset: 0 } ],
      ['AnimalComments', { freezed: false, offset: 0 } ],
      ['AnimalClassification', { freezed: false, offset: 0 } ],
      ['Cohort', { freezed: false, offset: 0 } ],
      ['HousingID', { freezed: false, offset: 0 } ],
      ['SampleName', { freezed: false, offset: 0 } ],
    ]);

    freezeLabels = [
        new ColumnSelectLabel('JobID', this.common.JobID ? `[${this.translationService.translate('Job')} Name]` : `${this.translationService.translate('Job')} Name`),
        new ColumnSelectLabel('TaskAlias', this.common.TaskAlias ? '[Task Name]' : 'Task Name'),
        new ColumnSelectLabel('PhysicalMarker', 'Marker'),
        new ColumnSelectLabel('AnimalName', 'Animal Name'),
        new ColumnSelectLabel('MicrochipIdentifier', 'Microchip ID'),
        new ColumnSelectLabel('AnimalComments', 'Animal Comments'),
        new ColumnSelectLabel('Cohort', 'Animal Cohort'),
        new ColumnSelectLabel('HousingID', 'Housing ID'),
        new ColumnSelectLabel('SampleName', 'Sample Name')
    ];

    freezeSettings: IMultiSelectSettings = {
        checkedStyle: 'fontawesome',
        fixedTitle: true,
        showUncheckAll: true,
        pullRight: true,
        buttonClasses: 'btn btn-secondary',
        focusBack: false,
        selectionLimit: 3,
        minSelectionLimit: 0,
    };
    freezeTexts: IMultiSelectTexts = {
        uncheckAll: 'Clear all',
        defaultTitle: 'Freeze',
    };
    readonly COMPONENT_LOG_TAG = 'workflow-bulk-data-entry';

    // Column limits for Inputs and Outputs
    readonly MAX_INPUTS = 30;
    readonly MAX_OUTPUTS = 75;

    readonly KEY_NO_MATERIAL = '_NM_';
    readonly DEFAULT_IMPORT: ImportValues = {
        hasErrors: false,
        errors: [],

        taskKeys: null,
        outputs: null,

        attachToJob: false,
        attachToTasks: false,

        includeAnimalNames: false,
        includeSampleNames: false,

        filename: null,
        inProgress: false,
    };

    // Import 
    import: ImportValues = { ...this.DEFAULT_IMPORT };

    // Print
    printPreviewId: string;

    taskPage = 1;

    loadingMessage = 'Loading...';

    // Cohorts 
    cohorts: Set<any> = new Set();

    // Dotmatics workgroup flag
    isDotmatics: boolean;

    // sort changed 
    sortChanged = false;
    importTemplateDownloaded = false;
    cohortsPromise: Promise<any>;
    totalPages = 0;
    cohortsStats: any[] = [];

    // Default table sort property path
    readonly TABLE_SORT_PROPERTY_PATH_DEFAULT = 'task.DateDue';

    studyAdministratorStudies: any[] = [];

    uniqueId = uniqueID++;

    materialTaskKeys = [
        'cv_AnimalStatus',
        'C_ExitReason_key',
        'C_SampleType_key',
        'C_SampleStatus_key',
        'C_PreservationMethod_key',
        'C_ContainerType_key',
        'Volume',
        'C_Unit_key',
        'C_SampleSubtype_key',
        'C_SampleProcessingMethod_key',
        'SendTo',
        'C_SampleAnalysisMethod_key',
        'DateHarvest',
        'LotNumber'
    ];
    taskEntitiesMap = new Map();
    appliedChangesMap = new Map();
    isDataChangeModalOpen = false;

    callSave = new Subject();
    callSave$ = this.callSave.asObservable();
    callSaveSub: Subscription;
    checkChangeSub: Subscription;
    anyChangeSub: any;
    changesSavedSub: Subscription;
    tablePosition: any;

    isGLP = false;

    // the material key of the animal currently selected. 
    selectedMaterialID: number;

    // is the "Search" toggle active?
    searchEnabled = false;
    animalSyncEnabled = false;
    clinicalSyncEnabled = false;

    searchMicrochipID: string;

    exportTypes = ExportType;

    ANIMAL_SYNC_SETTING = 'Animal Sync: View past data in the Animals Facet';
    CLINICAL_SYNC_SETTING = 'Clinical Sync: Create and view Clinical records';
    SEARCH_SETTING = 'Search: Make BDE more hands-free';
    workflowSettings: string[] = [
        this.ANIMAL_SYNC_SETTING,
        this.CLINICAL_SYNC_SETTING,
        this.SEARCH_SETTING
    ];

    enabledWorkflowSettings: string[] = [];

    currentWorkgroupUser: any;

    get showPostEditModal(): boolean {
      return this.featureFlagService.isFlagOn("ShowEditPostCompletedModal");
    }

    get rows(): DataRow[] {
      return this._rows;
    }
    set rows(value: DataRow[]) {
        this._rows = value;
    }
    private _rows: DataRow[];

    taxonCharacteristicsInListView: TaxonCharacteristic[];

    private readonly subs = new Subscription();

    constructor(
        private ngZone: NgZone,
        private ref: ChangeDetectorRef,
        private elementRef: ElementRef,
        private authService: AuthService,
        baseDetailService: BaseDetailService,
        private confirmService: ConfirmService,
        private dataManager: DataManagerService,
        private exportWorkflowDetailService: ExportWorkflowDetailService,
        private importFileDefinitionService: ImportFileDefinitionService,
        private modalService: NgbModal,
        private printPreviewService: PrintPreviewService,
        private resourceService: ResourceService,
        public saveChangesService: SaveChangesService,
        private vocabularyService: VocabularyService,
        private webApiService: WebApiService,
        private workflowBulkDataExportService: WorkflowBulkDataExportService,
        private workflowService: WorkflowService,
        private workflowLogicService: WorkflowLogicService,
        private workflowVocabService: WorkflowVocabService,
        private workspaceService: WorkspaceService,
        private currentWorkgroupService: CurrentWorkgroupService,
        private dotmaticsService: DotmaticsService,
        private privilegeService: PrivilegeService,
        private animalService: AnimalService,
        private noteService: NoteService,
        private entityChangeService: EntityChangeService,
        private featureFlagService: FeatureFlagService,
        private toastrService: ToastrService,
        private userService: UserService,
        private dateFormatterService: DateFormatterService,
        private translationService: TranslationService,
        private settingService: SettingService,
        private overlayService: OverlayService,
        private taskInstanceService: TaskInstanceService,
    ) {
        super(baseDetailService);

        this.printPreviewId = "workflow-bulk-data-print-" + randomId();
    }

    // lifecycle
    ngOnInit(): void {
        this._setDefaultTableSort();
        this.importReset();
        this.initIsGLP();

        this.initialize();

        if (!this.readonly) {
            this.setDataChange();
        }
        this.changesSavedSub = this.dataContext?.changesSaved$.subscribe(() => {
            if (this.workflowService.animalSyncEnabled) {
                this.refreshTaskOutputs();
            }
        });
    }

    ngAfterViewInit(): void {
        this.initFreezeColumnChanged();
        // Add jQuery listeners 
        this.bindJQuery();
        this.initDropdownClickListeners();
    }

    ngOnChanges(changes: any): void {
        if (changes.taskKeys) {
            if (this.taskKeys && !changes.taskKeys.firstChange) {
                this.initialize();
            }
        }
        if (changes.animalSyncEnabled) {
            this.workflowService.animalSyncEnabled = this.animalSyncEnabled;
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
        // Stop updating the current time
        clearInterval(this.currentTimeInterval);

        // Remove jQuery listeners 
        this.unbindJQuery();
        if (this.checkChangeSub) {
            this.checkChangeSub.unsubscribe();
        }
        if (this.anyChangeSub) {
            this.anyChangeSub.unsubscribe();
        }
        if (this.callSaveSub) {
            this.callSaveSub.unsubscribe();
        }
        if (this.changesSavedSub) {
            this.changesSavedSub.unsubscribe();
        }

        const dropdowns: NodeListOf<HTMLElement> = this.elementRef.nativeElement.querySelectorAll('.dropdown-toggle');
        dropdowns.forEach((element: HTMLElement) => {
            element.removeEventListener('click', this.onDropdownMenuClick);
        });
    }

    initialize(): Promise<any> {
        this.setBusy(true);
        // Start the clock
        this.updateCurrentTime();

        this.initWorkflowSettings();

        // set is dotmatics flag
        this.setIsDotmatics();

        this.getStudyAdminStudies();

        // Fetch all the vocabularies
        return this.getCVs().then(() => {
            // Find the current user's Resource
            return this.initCurrentResource();
        }).then(() => {
            return this.initTaxonCharacteristicsFromListView();
        }).then(() => {
            return this.initCurrentWorkgroupUser();
        }).then(() => {
            // Initialize the Tasks and Rows
            return this.initData();
        }).then(() => {
            clearInterval(this.currentTimeInterval);

            // Update the current time
            this.ngZone.runOutsideAngular(() => {
                this.currentTimeInterval = setInterval(() => {
                    this.updateCurrentTime();
                }, 1000);
            });
        }).then(() => {
            // After the page has loaded, do a first save of any newly created
            // TaskOutputSets, but don't reload the tasks
            return this.saveEntities(true, false);
        }).then(() => {
            this.setBusy(false);
        }).then(() => {
            this.totalPages = Math.ceil(this.rows.length / 30);
            this.calculateStatsCohortWise();
        }).catch((err) => {
            this.setBusy(false);
            throw err;
        });
    }

    initFreezeColumnChanged(): void {
        const sub = merge(of(this.tableHeaders), this.tableHeaders.changes)
            .pipe(switchMap(
              (elements: QueryList<ElementRef<HTMLElement>>) => fromResizeObserver(elements.map(element => element.nativeElement))
            ))
            .subscribe(entries => this.calculateFreezedOffsets(entries.map(entry => entry.target)));
        this.subs.add(sub);
    }

    getFreezedOffset(key: string): number | undefined {
        const item = this.freezeColumns.get(key);
        return Boolean(item?.freezed) ? item.offset : undefined;
    }

    getFreezedClass(key: string): boolean {
        return Boolean(this.freezeColumns.get(key)?.freezed);
    }

    calculateFreezedOffsets(elements: Element[]) : void{
        let offset = -10;
        const elementsWidthsMap = new Map(elements.map(element => {
            const key = element.getAttribute('data-key');
            return [key, element.getBoundingClientRect().width];
        }));
        const freezedSet = new Set(this.columnFreeze.model);
        for (const key of this.freezeColumns.keys()) {
            const item = this.freezeColumns.get(key);
            item.freezed = freezedSet.has(key);
            item.offset = offset;
            if (item.freezed) {
                offset += elementsWidthsMap.get(key);
            }
            this.freezeColumns.set(key, item);
        }
    }

    initDropdownClickListeners(): void {
        const dropdowns: NodeListOf<HTMLElement> = this.elementRef.nativeElement.querySelectorAll('.dropdown-toggle');
        dropdowns.forEach((element: HTMLElement) => {
            element.addEventListener('click', this.onDropdownMenuClick);
        });
    }

    onDropdownMenuClick = (event: PointerEvent): void => {
        const multiSelectDropdownToggle: HTMLElement = this.elementRef.nativeElement.querySelector('climb-multi-select .open > .dropdown-toggle');
        if (multiSelectDropdownToggle !== event.target && this.multiSelectComponent.dropdownMultiselect.isOpened) {
            this.multiSelectComponent.close();
        }

        const openedColumnSelectDropdown = this.elementRef.nativeElement.querySelector('ngx-bootstrap-multiselect .open > .dropdown-toggle');
        const openedColumnSelect = this.ngxSelectComponent.toArray().find((component: NgxDropdownMultiselectComponent) => component.isVisible);
        if (openedColumnSelectDropdown && event.target !== openedColumnSelectDropdown) {
           openedColumnSelect.closeDropdown();
        }
    }

    initWorkflowSettings(): void {
        if (!this.facet.GridFilter) {
            return;
        }
        let gridFilter = JSON.parse(this.facet.GridFilter);
        if (!gridFilter) {
            gridFilter = {};
        }

        if (gridFilter.isAnimalSyncEnabled) {
            this.enabledWorkflowSettings.push(this.ANIMAL_SYNC_SETTING);
        }

        if (gridFilter.isClinicalSyncEnabled) {
            this.columnSelect.labels = this.columnSelect.labels.filter((col: any) => col.key !== 'Clinical');
            this.enabledWorkflowSettings.push(this.CLINICAL_SYNC_SETTING);
        }

        if (gridFilter.isScanEnabled) {
            this.enabledWorkflowSettings.push(this.SEARCH_SETTING);
        }
        this.animalSyncEnabled = gridFilter.isAnimalSyncEnabled;
        this.clinicalSyncEnabled = gridFilter.isClinicalSyncEnabled;
        this.searchEnabled = gridFilter.isScanEnabled;
    }

    async onWorkflowSettingsChange(changes: string[]): Promise<void> {
        let filter: any;
        if (this.facet.GridFilter) {
            filter = JSON.parse(this.facet.GridFilter);
        } else {
            filter = {};
        }

        const isAnimalSyncEnabled = changes.includes(this.ANIMAL_SYNC_SETTING);
        const isClinicalSyncEnabled = changes.includes(this.CLINICAL_SYNC_SETTING);
        const isSearchEnabled = changes.includes(this.SEARCH_SETTING);

        if (isAnimalSyncEnabled) {
            const isOpen = await this.checkIfAnimalFacetIsOpen(this.taskKeys);
            if (isOpen) {
                filter.isAnimalSyncEnabled = isAnimalSyncEnabled;
                this.animalSyncEnabled = isAnimalSyncEnabled;
            } else {
                filter.isAnimalSyncEnabled = false;
                this.animalSyncEnabled = false;
                const animalOptionIndex = this.enabledWorkflowSettings.findIndex((s: string) => s === this.ANIMAL_SYNC_SETTING);
                this.enabledWorkflowSettings.splice(animalOptionIndex, 1);

            }
        } else {
            filter.isAnimalSyncEnabled = false;
            this.animalSyncEnabled = false;
        }

        if (isClinicalSyncEnabled) {
            const isOpen = await this.checkIfClinicalFacetIsOpen(this.taskKeys);
            if (isOpen) {
                filter.isClinicalSyncEnabled = isClinicalSyncEnabled;
                this.clinicalSyncEnabled = isClinicalSyncEnabled;
            } else {
                filter.isClinicalSyncEnabled = false;
                this.clinicalSyncEnabled = false;
                const clinicalOptionIndex = this.enabledWorkflowSettings.findIndex((s: string) => s === this.CLINICAL_SYNC_SETTING);
                this.enabledWorkflowSettings.splice(clinicalOptionIndex, 1);
            }
        } else {
            filter.isClinicalSyncEnabled = false;
            this.clinicalSyncEnabled = false;
        }

        filter.isScanEnabled = isSearchEnabled;
        this.searchEnabled = isSearchEnabled;

        this.facet.GridFilter = JSON.stringify(filter);
        this.initColumnSelect();

    }

    getOnlyNumeric(outputs: any): any[] {
        const dataTypes = new Set([...DATA_TYPES_NUMERIC, ...DATA_TYPES_INHERITED]);
        return outputs.filter((output: any) => dataTypes.has(getSafeProp(output.Output, 'cv_DataType.DataType')));
    }

    getOnlyValidImportOutputs(outputs: any): any[] {
        const dataTypes = new Set(DATA_TYPES_VALID);
        return outputs.filter((output: any) => dataTypes.has(getSafeProp(output, 'cv_DataType.DataType')));
    }

    calculateStatsCohortWise() {
        this.cohortsStats = [];
        Promise.resolve(this.cohortsPromise).then(() => {
            const cohorts = {};
            const cohortCounts = {};
            const outputNames = {};
            this.rows.forEach((row) => {
                const rowCohorts = this.getAnimalCohorts(row);
                if (rowCohorts.length > 0) {
                    rowCohorts.forEach((cohort: any) => {
                        if (cohorts[cohort.C_Cohort_key]) {
                            cohorts[cohort.C_Cohort_key] = cohorts[cohort.C_Cohort_key].concat(this.getOnlyNumeric(objectValues(row.taskOutputs)));
                        } else {
                            cohorts[cohort.C_Cohort_key] = this.getOnlyNumeric(objectValues(row.taskOutputs));
                        }
                        if (row.taskOutputs) {
                            objectValues(row.taskOutputs).forEach((output: any) => {
                                outputNames[output.C_Output_key] = output.Output ? output.Output.OutputName : "";
                            });
                        }
                        if (cohortCounts[cohort.C_Cohort_key] && !cohortCounts[cohort.C_Cohort_key].includes(row.animal.C_Material_key)) {
                            cohortCounts[cohort.C_Cohort_key].push(row.animal.C_Material_key);
                        } else {
                            cohortCounts[cohort.C_Cohort_key] = [row.animal.C_Material_key];
                        }
                    });
                }
            });
            this.cohorts.forEach((cohort) => {
                const key = cohort.C_Cohort_key;
                const outputs = cohorts[key];
                const outputWiseValuesArray = {};
                const outputWiseFlags = {};
                if (outputs && outputs.length > 0) {
                    outputs.forEach((output: any) => {
                        if (outputWiseValuesArray[output.C_Output_key]) {
                            if (!isNaN(parseFloat(output.OutputValue))) {
                                outputWiseValuesArray[output.C_Output_key].push(parseFloat(output.OutputValue));
                            }
                        } else {
                            if (!isNaN(parseFloat(output.OutputValue))) {
                                outputWiseValuesArray[output.C_Output_key] = [parseFloat(output.OutputValue)];
                            } else {
                                outputWiseValuesArray[output.C_Output_key] = [];
                            }
                        }
                        outputWiseFlags[output.C_Output_key] = output;
                    });
                    const outputStats: any[] = [];
                    const outputValues = Object.keys(outputWiseValuesArray);
                    if (outputValues && outputValues.length > 0) {
                        outputValues.forEach((output) => {
                            const average = this.average(outputWiseValuesArray[output]);
                            const median = this.median(outputWiseValuesArray[output]);
                            const standard = this.standardDeviation(outputWiseValuesArray[output]);
                            const outputDef = outputWiseFlags[output].Output;
                            const stats = {
                                name: outputNames[output],
                                average,
                                median,
                                standard,
                                HasAvgFlag: outputDef ? this.hasAverageFlag(outputDef.HasCohortStatsFlag, outputDef.AverageFlagMaximum, outputDef.AverageFlagMinimum, average) : false,
                                HasMdnFlag: outputDef ? this.hasMedianFlag(outputDef.HasCohortStatsFlag, outputDef.MedianFlagMaximum, outputDef.MedianFlagMinimum, median) : false,
                                HasStdDevFlag: outputDef ? this.hasStdDevFlag(outputDef.HasCohortStatsFlag, outputDef.StdDevFlagMaximum, outputDef.StdDevFlagMinimum, standard) : false
                            };
                            outputStats.push(stats);
                        });
                        this.cohortsStats.push({
                            CohortName: cohort.CohortName,
                            Total: cohort.CohortMaterial ? cohort.CohortMaterial.length : 0,
                            Count: cohort.CohortMaterial ? cohortCounts[cohort.C_Cohort_key].length : 0,
                            BackgroundColor: cohort.BackgroundColor,
                            ForegroundColor: cohort.ForegroundColor,
                            outputs: outputStats
                        });
                    }
                }
            });
        });
    }
    hasStdDevFlag(hasCohortStatsFlag: any, stdDevFlagMaximum: any, stdDevFlagMinimum: any, standard: number): any {
        if (hasCohortStatsFlag) {
            if (stdDevFlagMaximum !== null && stdDevFlagMinimum !== null) {
                return standard >= stdDevFlagMinimum && standard <= stdDevFlagMaximum;
            } else if (stdDevFlagMaximum !== null) {
                return standard <= stdDevFlagMaximum;
            } else if (stdDevFlagMinimum !== null) {
                return standard >= stdDevFlagMinimum;
            }
        }
        return false;
    }
    hasMedianFlag(hasCohortStatsFlag: any, medianFlagMaximum: any, medianFlagMinimum: any, median: number): any {
        if (hasCohortStatsFlag) {
            if (medianFlagMaximum !== null && medianFlagMinimum !== null) {
                return median >= medianFlagMinimum && median <= medianFlagMaximum;
            } else if (medianFlagMaximum !== null) {
                return median <= medianFlagMaximum;
            } else if (medianFlagMinimum !== null) {
                return median >= medianFlagMinimum;
            }
        }
        return false;
    }
    hasAverageFlag(hasCohortStatsFlag: any, averageFlagMaximum: any, averageFlagMinimum: any, average: number): any {
        if (hasCohortStatsFlag) {
            if (averageFlagMaximum !== null && averageFlagMinimum !== null) {
                return average >= averageFlagMinimum && average <= averageFlagMaximum;
            } else if (averageFlagMaximum !== null) {
                return average <= averageFlagMaximum;
            } else if (averageFlagMinimum !== null) {
                return average >= averageFlagMinimum;
            }
        }
        return false;
    }

    setTablePosition() {
        const tablePosition = $('.bde-table.' + this.uniqueId).find('thead tr th:first-child').position();
        if (tablePosition) {
            this.tablePosition = tablePosition.top;
        }
    }

    /**
     * Add jQuery listeners
     */
    bindJQuery() {
        if (!this.elementRef.nativeElement) {
            // Just in case
            return;
        }

        // Handle row inputs getting focus, perhaps through keyboard events
        jQuery(this.elementRef.nativeElement).on(
            'focus.WorkflowBulkDataEntry',
            'table.workflow-bulk-data-entry tr :input',
            (event: JQuery.TriggeredEvent) => {
                this.rowInputFocus(event);
            }
        );

        // Select the Scan text whenever it gets focus
        jQuery(this.elementRef.nativeElement).on(
            'focus.WorkflowBulkDataEntry',
            'table.workflow-bulk-data-entry tr .scan:input',
            (event: JQuery.TriggeredEvent) => {
                $(event.target).trigger('select');
            }
        );
    }

    /**
     * Remove jQuery listeners
     */
    unbindJQuery() {
        if ($('#sticky_column').length > 0) {
            $('#sticky_column').remove();
        }

        if (!this.elementRef.nativeElement) {
            // Just in case
            return;
        }

        // Remove all listeners for our namespace.
        jQuery(this.elementRef.nativeElement).off('.WorkflowBulkDataEntry');
    }

    /**
     * Update the current time label.
     */
    updateCurrentTime() {
        const now = new Date();
        const nowText = this.dateFormatterService.formatTime(DateTime.now().toFormat(TIME_FORMATTER_FORMAT));

        if (nowText !== this.currentTimeText) {
            // Only update the model when the minute changes to avoid recalculating everything!
            this.currentTime = now;
            this.currentTimeText = nowText;
            // must trigger change detection since the setInterval runOutsideAngular 
            this.ref.detectChanges();
        }
    }

    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    getCVs(): Promise<any> {
        return Promise.all([
            this.workflowVocabService.taskStatuses$.pipe(map((data) => {
                this.taskStatuses = data;

                // Get the default end state status for a TaskInstance
                this.taskDefaultEndStatus = this.taskStatuses.find((status: any) => {
                    return status.IsDefaultEndState;
                });
                // Get the default status for a TaskInstance
                this.taskDefaultStatus = this.taskStatuses.find((status: any) => {
                    return status.IsDefault;
                });
            })).toPromise(),

            this.workflowVocabService.resources$.pipe(map((data) => {
                this.resources = data;
            })).toPromise(),

            this.workflowVocabService.animalStatuses$.pipe(map((data) => {
                this.animalStatuses = data;
            })).toPromise(),

            this.vocabularyService.ensureCVLoaded('cv_DataTypes'),
            this.vocabularyService.getCV('cv_TimeUnits').then((data: any) => {
                this.timeUnits = data;
            }),
            this.vocabularyService.getCV('cv_Units').then((data: any) => {
                this.volumeUnitTypes = data;
            }),
            this.vocabularyService.getCV('cv_ExitReasons').then((data: any) => {
                this.exitReasons = data;
            }),
            this.vocabularyService.getCV('cv_SampleSubtypes').then((data: any[]) => {
                this.sampleSubtypes = data;
            }),
            this.vocabularyService.getCV('cv_SampleProcessingMethods').then((data: any[]) => {
                this.sampleProcessingMethods = data;
            }),
            this.vocabularyService.getCV('cv_SampleAnalysisMethods').then((data: any[]) => {
                this.sampleAnalysisMethods = data;
            }),
            this.workflowVocabService.sampleStatuses$.pipe(map((data) => {
                this.sampleStatuses = data;
            })).toPromise(),

            this.workflowVocabService.sampleTypes$.pipe(map((data) => {
                this.sampleTypes = data;
            })).toPromise(),

            this.workflowVocabService.preservationMethods$.pipe(map((data) => {
                this.preservationMethods = data;
            })).toPromise(),

            this.workflowVocabService.containerTypes$.pipe(map((data) => {
                this.containerTypes = data;
            })).toPromise(),
        ]);
    }

    async initData(): Promise<void> {
        try {
            this.setBusy(true);
            // Fetch the selected tasks
            await this.workflowService.createOutputSets(this.taskKeys, this.authService.getCurrentUserName(), this.currentWorkgroupService.getCurrentWorkgroupKey());
            const tasks = await this.taskInstanceService.fetchTasks(this.taskKeys);
            // Fill out the tasks
            this.tasks = await this.initTasks(tasks);
            // Initialize the DateDue dropdown
            this.initDateDueTasks();
            // Initialize the table
            this.initColumns(tasks);
            const rows: any[] = this.initRows(tasks);
            this.updateCalculatedValues(rows);
            this.rows = rows;
            setTimeout(() => {
                this.setTablePosition();
            }, 300);
            if (this.animalSyncEnabled) {
                this.refreshTaskOutputs();
            }
        } finally {
            this.setBusy(false);
        }
    }

    /**
     * Force a refresh of the data
     */
    async refreshData(): Promise<any> {
        await this.initData()
        this.sortColumns();
        this.totalPages = Math.ceil(this.rows.length / 30);
        this.calculateStatsCohortWise();
    }

    async refreshTasks(taskKeys: number[] = this.taskKeys) {
        try {
            this.setBusy(true);
            const refreshedTasks = await this.taskInstanceService.fetchTasks(taskKeys);
            const refreshedTasksMap = new Map<number, Entity<TaskInstance>>();
            for (const task of refreshedTasks) {
              refreshedTasksMap[task.C_TaskInstance_key] = task;
            }
            this.tasks.forEach((task, index, list) => {
              const refreshedTask = refreshedTasksMap.get(task.C_TaskInstance_key);
              if (!refreshedTask) return;
              list[index] = refreshedTask;
            });
            const rows = this.initRows(this.tasks);
            this.updateCalculatedValues(rows);
            this.rows = rows;
            setTimeout(() => {
                this.setTablePosition();
            }, 300);
            if (this.animalSyncEnabled) {
                this.refreshTaskOutputs();
            }
            this.calculateStatsCohortWise();
            this.sortColumns();
        } finally {
            this.setBusy(false);
        }
    }

    /**
     * Initialize the fetched tasks by filling in the many relationships.
     */
    async initTasks(tasks: any[]): Promise<any[]> {
        const expands = [
            'cv_TaskStatus',
            'TaskJob.Job',
            'TaskJob.Job.JobMaterial',
            'TaskInput.Input',
            'WorkflowTask.Output.CalculatedOutputExpression',
            'TaskOutputSet.TaskOutput',
            'TaskOutputSet.TaskOutputSetMaterial.Material',
            'TaskMaterial.Material.Animal.cv_PhysicalMarkerType',
            'TaskMaterial.Material.Animal.AnimalHealthRecord',
            'TaskMaterial.Material.CohortMaterial.Cohort',
            'TaskMaterial.Material.MaterialPoolMaterial.MaterialPool',
            'TaskMaterial.Material.MaterialSourceMaterial.SourceMaterial',
            'TaskMaterial.Material.Sample',
            'ProtocolTask.cv_ScheduleType',
            'TaskMaterialPool.MaterialPool',
            'TaskAnimalHealthRecord.AnimalHealthRecord.Animal.Material.CohortMaterial.Cohort',
            'TaskAnimalHealthRecord.AnimalHealthRecord.Animal.Material.MaterialPoolMaterial.MaterialPool',
            'TaskBirth.Birth.Mating.MaterialPool',
            'TaskMaterial.Material.Animal.AnimalComment.cv_AnimalCommentStatus',
            'TaskMaterial.Material.Animal.TaxonCharacteristicInstance.TaxonCharacteristic'
        ];
        await this.dataManager.ensureRelationships(tasks, expands);
        tasks = this.sortTasks(tasks);
        return tasks;
    }

    /**
     * Sorts a list of task instances based on the date due. 
     * If the tasks have the same due date and are part of a job, they will be sorted in accordance to their sequence on the job
     * @param tasks a list of TaskInstances
     * @returns a sorted list of tasks
     */
    sortTasks(tasks: TaskInstance[]): any[] {
        const materialSequence: Record<number, number> = {};
        if (tasks[0]?.TaskJob?.length > 0) {
            tasks[0].TaskJob[0].Job.JobMaterial.forEach((jobMat: JobMaterial) => {
                materialSequence[jobMat.C_Material_key] = jobMat.Sequence;
            });
        }
        return tasks.sort(
            (a: TaskInstance, b: TaskInstance) => {
                const dateValOne = a.DateDue ? convertValueToLuxon(a.DateDue).toSeconds() : 0;
                const dateValTwo = b.DateDue ? convertValueToLuxon(b.DateDue).toSeconds() : 0;

                const sequenceValOne = a.TaskMaterial.length > 0 ?
                    materialSequence[a.TaskMaterial[0].C_Material_key] :
                    -Infinity;
                const sequenceValTwo = b.TaskMaterial.length > 0 ?
                    materialSequence[b.TaskMaterial[0].C_Material_key] :
                    -Infinity;

                const date = dateValOne - dateValTwo;
                if (date !== 0) {
                    return date;
                }
                return sequenceValOne - sequenceValTwo;
            }
        );
    }

    initDateDueTasks() {
        if (!this.tasks || (this.tasks.length === 0)) {
            this.bulk.dateDueTaskAliases = [];
            return;
        }

        const seen: BooleanMap = {};

        this.bulk.dateDueTaskAliases = this.tasks.filter((task: any) => {
            const scheduleType = getSafeProp(
                task, 'ProtocolTask.cv_ScheduleType.ScheduleType'
            );

            if ((task.ProtocolTask !== null) &&
                (task.ProtocolTask.C_RelativeProtocolTask_key !== null) &&
                ((scheduleType === ScheduleType.RelativeTaskDue) ||
                    (scheduleType === ScheduleType.RelativeTaskComplete))
            ) {
                // This is a dependent task from a Protocol
                return false;
            }

            const key = task.TaskAlias;
            if (seen[key]) {
                // Already added tasks with this alias
                return false;
            }

            // Remember this task
            seen[key] = true;

            return true;
        }).map<string>((task: any) => task.TaskAlias);
    }

    initCurrentResource(): Promise<void> {
        return this.resourceService.getCurrentUserResource().then((resource: any) => {
            this.currentResourceKey = resource ? resource.C_Resource_key : null;
        });
    }

    initTaxonCharacteristicsFromListView(): Promise<void> {
        return this.settingService.getTaxonCharacteristicsShownInListView().then((results: TaxonCharacteristic[]) => {
            this.taxonCharacteristicsInListView = results;
        });
    }

    /**
     * Find a common property value among all the TaskInstances.
     * 
     * @param tasks TaskInstance array
     * @param path Property path (e.g. 'TaskJob.Job.JobID')
     * @returns the common value, or null if values differed
     */
    findCommonValue(tasks: any[], path: string): any {
        const values = uniqueArrayFromPropertyPath(tasks, path);
        return (values.length === 1) ? values[0] : null;
    }

    /**
     * Initialize the common values that will be display above the table, such
     * as JobID and TaskAlias.
     */
    initCommonValues(tasks: any[]) {
        const jobIDs = new Set<string>();
        const taskAliases = new Set<string>();
        let isNotJobExist = false;

        for (const task of tasks) {
            const alias = task?.TaskAlias;
            if (alias) {
                taskAliases.add(alias);
            }

            const jobs = task?.TaskJob ?? [];
            if (jobs.length === 0) {
                isNotJobExist = true;
                continue;
            }
            for (const job of jobs) {
                const jobID = job?.Job.JobID as string | undefined;
                if (jobID) {
                    jobIDs.add(jobID);
                } else {
                    isNotJobExist = true;
                }
            }
        }

        // See if there are any common values
        // any job tasks exist
        this.common.isJobIDColVis = jobIDs.size > 0;
        // any task aliases exist
        this.common.isTaskAliasVis = taskAliases.size > 0;

        // only job tasks (isNotJobExist: false) with the same JobID
        if (!isNotJobExist && jobIDs.size === 1) {
            this.common.JobID = jobIDs.values().next().value;
        }

        // if JobIDCol is hidden and there is only one alias
        const isTaskAliasVisible = (jobIDs.size === 0 && isNotJobExist) || (jobIDs.size > 0 && !isNotJobExist);
        if (isTaskAliasVisible && taskAliases.size === 1) {
            this.common.TaskAlias = taskAliases.values().next().value;
        }
    }

    /**
     * Initialize the table columns.
     */
    initColumns(tasks: any[]) {
        // Find common values to pull out of the table.
        this.initCommonValues(tasks);

        // Find labels for all the Inputs and Outputs used across all the TaskInstances.
        this.inputColumns = this.findInputs(tasks);
        this.outputColumns = this.findOutputs(tasks);

        this.hasInherited = this.outputColumns.some((col) => col.isInherited);

        // Initialize the column selection and visibility flags.
        this.initColumnSelect();
    }

    /**
     * Initialize the column selection and visibility flags.
     */
    initColumnSelect() {

        const labels = this._getMainColumnSelectLabels();

        if (this.clinicalSyncEnabled) {
            this.columnSelect.labels = labels.filter((col: any) => col.key !== 'Clinical');
        } else {
            this.columnSelect.labels = labels;
        }

        // Default column visibility
        this.visible = {
            JobID: true,
            TaskAlias: true,
            PhysicalMarker: true,
            AnimalName: true,
            MicrochipIdentifier: false,
            AlternatePhysicalID: false,
            Scan: false,
            AnimalComments: false,
            AnimalClassification: false,
            Cohort: false,
            HousingID: true,
            SampleName: true,
            DateDue: true,
            TimeDue: true,
            Inputs: true,
            OutputSample: true,
            HarvestDate: false,
            SampleVolume: false,
            VolumeUnit: false,
            SampleSubtype: false,
            SampleProcessingMethod: false,
            SendTo: false,
            SampleAnalysisMethod: false,
            SpecialInstructions: false,
            SampleLotNumber: false,
            SampleType: false,
            SampleStatus: false,
            PreservationMethod: false,
            ContainerType: false,
            Inherited: true,
            Outputs: true,
            Collected: false,
            CollectedBy: false,
            CollectedDate: false,
            CollectedTime: false,
            Completed: true,
            CompletedBy: true,
            CompletedDate: true,
            CompletedTime: true,
            Reviewed: false,
            ReviewedBy: false,
            ReviewedDate: false,
            ReviewedTime: false,
            TaskStatus: true,
            AnimalStatus: true,
            ExitReason: false,
            Clinical: true,
            Notes: true,
            Files: false,
            Lock: true
        };

        this.taxonCharacteristicsInListView.forEach((taxonCharacteristic: TaxonCharacteristic) => {
            this.visible[taxonCharacteristic.CharacteristicName] = false;
        });

        if (this.visibleColumns.length > 0) {
            for (const col in this.visible) {
                if (this.visible[col] && !this.visibleColumns.includes(col)) {
                    this.visible[col] = false;
                }
                /* tslint:disable-next-line */
                this.columnSelect.labels = this.columnSelect.labels.filter((item) => this.visibleColumns.includes(item.key));
            }
        }

        // Get the selected columns from the user configuration
        const config = this.parseBulkDataConfiguration();
        this.columnSelect.model = labels.filter((item) => {
            const columnConfig = config.columns[item.key];

            if (!columnConfig) {
                // No user config yet, so rely on the default visibility
                return this.visible[item.key];
            }

            // Columns are visible unless explicitly hidden
            return Boolean(config.columns[item.key]?.visible)
        }).map((item) => item.key);

        // Only show that are currently visible
        this.columnFreeze.labels = this.freezeLabels.filter((item: ColumnSelectLabel) => {
            return this.visible[item.key] && this.columnSelect.model.find((c: any) => c === item.key.toString());
        });

        this.columnFreeze.model = labels
          .filter((item) => Boolean(config.columns[item.key]?.freeze))
          .map((item) => item.key);

        const outputLabels = this.outputColumns.filter((o) => o.visible).map((o) => o.label);
        this.columnOutput.labels = outputLabels.map((label) => new ColumnSelectLabel(label, label));
        this.columnOutput.model = outputLabels
            .filter((label) => config.outputs[label] === undefined || config.outputs[label] === true);
        this.columnOutputSelect.model = this.columnOutput.model; // set initial state

        // recreate columnSelect to apply changes to the climb-column-select component
        this.columnSelect = {
            model: this.columnSelect.model,
            labels: this.columnSelect.labels,
        };        

        this.ref.detectChanges();
        // Update the column visiblility
        this.updateVisible();
    }

    private _getMainColumnSelectLabels(): ColumnSelectLabel[] {
        // Assemble the list of all columns that can be selected
        const labels = [
            // Use [] to note columns that can be selected but are not
            // currently visible because the data is in the header.
            new ColumnSelectLabel('JobID', this.common.JobID ? `[${this.translationService.translate('Job')} Name]` : `${this.translationService.translate('Job')} Name`, 0),
            new ColumnSelectLabel('TaskAlias', this.common.TaskAlias ? '[Task Name]' : 'Task Name', 1),
            new ColumnSelectLabel('PhysicalMarker', 'Marker', 2),
            new ColumnSelectLabel('AnimalName', 'Animal Name', 3),
            new ColumnSelectLabel('MicrochipIdentifier', 'Microchip ID', 4),
            new ColumnSelectLabel('Scan', 'Scan', 5),
            new ColumnSelectLabel('AnimalComments', 'Animal Comments', 6),
            new ColumnSelectLabel('Cohort', 'Animal Cohort', 7),
            new ColumnSelectLabel('HousingID', 'Housing ID', 14),
            new ColumnSelectLabel('SampleName', 'Sample Name', 15),
            new ColumnSelectLabel('DateDue', 'Date Due', 16),
            new ColumnSelectLabel('TimeDue', 'Time Due', 17),
            // new ColumnSelectLabel('Inputs', 'Inputs'),
            new ColumnSelectLabel('OutputSample', 'Output Sample', 18),
            new ColumnSelectLabel('HarvestDate', 'Harvest Date', 19),
            new ColumnSelectLabel('SampleType', 'Sample Type', 20),
            new ColumnSelectLabel('SampleStatus', 'Sample Status', 21),
            new ColumnSelectLabel('PreservationMethod', 'Sample Preservation Method', 22),
            new ColumnSelectLabel('ContainerType', 'Sample Container Type', 23),
            new ColumnSelectLabel('SampleVolume', 'Sample Measurement', 24),
            new ColumnSelectLabel('VolumeUnit', 'Sample Unit', 25),
            new ColumnSelectLabel('SampleSubtype', 'Sample Subtype', 26),
            new ColumnSelectLabel('SampleProcessingMethod', 'Sample Processing', 27),
            new ColumnSelectLabel('SendTo', 'Sample Send To', 28),
            new ColumnSelectLabel('SampleAnalysisMethod', 'Sample Analysis', 29),
            new ColumnSelectLabel('SpecialInstructions', 'Special Instructions', 30),
            new ColumnSelectLabel('SampleLotNumber', 'Sample Lot Number', 31),
            // new ColumnSelectLabel('Outputs', 'Outputs'),
            new ColumnSelectLabel('Collected', 'Collected', 32),
            new ColumnSelectLabel('CollectedBy', 'Collected By', 33),
            new ColumnSelectLabel('CollectedDate', 'Collected Date', 34),
            new ColumnSelectLabel('CollectedTime', 'Collected Time', 35),
            new ColumnSelectLabel('Completed', 'Completed', 36),
            new ColumnSelectLabel('CompletedBy', 'Completed By', 37),
            new ColumnSelectLabel('CompletedDate', 'Completed Date', 38),
            new ColumnSelectLabel('CompletedTime', 'Completed Time', 39),
            new ColumnSelectLabel('Reviewed', 'Reviewed', 40),
            new ColumnSelectLabel('ReviewedBy', 'Reviewed By', 41),
            new ColumnSelectLabel('ReviewedDate', 'Reviewed Date', 42),
            new ColumnSelectLabel('ReviewedTime', 'Reviewed Time', 43),
            new ColumnSelectLabel('TaskStatus', 'Task Status', 44),
            new ColumnSelectLabel('AnimalStatus', 'Animal Status', 45),
            new ColumnSelectLabel('ExitReason', 'Exit Reason', 46),
            new ColumnSelectLabel('Clinical', 'Clinical', 47),
            new ColumnSelectLabel('Notes', 'Notes', 48),
            new ColumnSelectLabel('Files', 'Files', 49),
            new ColumnSelectLabel('Lock', 'Lock', 50),
        ];

        this.taxonCharacteristicsInListView.forEach((taxonCharacteristic: TaxonCharacteristic, index: number) => {
            labels.push(new ColumnSelectLabel(taxonCharacteristic.C_TaxonCharacteristic_key, taxonCharacteristic.CharacteristicName, 8 + index));
        });

        return labels.sort((a: ColumnSelectLabel, b: ColumnSelectLabel) => a.sortOrder - b.sortOrder);
    }
    /**
     * Column Freeze selections have changed
     */
    columnFreezeSelectChanged(current: string[]) {
        // update the freeze config here
        this.updateVisible();
        // Save the selection to the database
        this.saveBulkDataConfig();
    }
    /**
     * Column selections have changed
     */
    columnSelectChanged(current: string[]) {
        // Get the current selections
        this.columnSelect.model = current;

        // Update the column visibilty
        this.updateVisible();

        // Save the selection to the database
        this.saveBulkDataConfig();
    }
    /**
     * Column outputs selections have changed
     */
    columnOutputChanged(current: string[]) {
        // update the output columns visibility
        this.updateVisible();
        // Save the selection to the database
        this.saveBulkDataConfig();
    }

    /**
     * Update the column visibility flags.
     */
    updateVisible() {
        // Make a lookup table
        const selected = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });

        // Update the visibilty based on the column selections
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;
            this.visible[key] = (selected[key] === true);
        });

        // Hide the table columns if there are common values, since they will
        // appear above the table.
        if (this.hideCommonValues) {
            this.visible.JobID = this.visible.JobID && this.common.isJobIDColVis;
            this.visible.TaskAlias = this.visible.TaskAlias && !this.common.TaskAlias;
        }

        // Only show freeze columns that are currently visible
        this.columnFreeze.labels = this.freezeLabels.filter((item: ColumnSelectLabel) => {
            return this.visible[item.key] && this.columnSelect.model.find((c: any) => c === item.key.toString());
        });

        if (this.visibleColumns.length === 0 || this.visibleColumns.includes('Inherited')) {
            this.visible.Inherited = this.hasInherited;
        }

        if (this.searchEnabled) {
            this.visible.MicrochipIdentifier = this.searchEnabled;
            this.columnSelect.model.push("MicrochipIdentifier");
        }
      
        this.columnFreeze.model = this.columnFreeze.model.filter((item) => {
            return this.visible[item] && this.columnSelect.model.find((c: any) => c === item.toString());
        });

        this.output = this.columnOutput.model.reduce((prev, curr) => {
            prev[curr] = true;
            return prev;
        }, {});

        const { label, classes } = this.getOutputsSelectorConfig();
        this.outputsSelectorLabel = label;
        this.outputsSelectorClasses = classes;

        this.calculateFreezedOffsets(this.tableHeaders.map(item => item.nativeElement));
    }

    /**
     * Parse the BulkDataConfiguration JSON string, or provide a blank config object
     */
    parseBulkDataConfiguration(): BulkDataConfig {
        try {
            if (this.facet.BulkDataConfiguration) {
                return JSON.parse(this.facet.BulkDataConfiguration);
            }
        } catch (e) {
            console.error('Could not parse TaskGridConfiguration', e);
        }

        return new BulkDataConfig();
    }

    /**
     * Save the column selections for this facet.
     */
    saveBulkDataConfig() {
        // Start from scratch
        const config = new BulkDataConfig();

        // Make a lookup table
        const selected: BooleanMap = {};
        this.columnSelect.model.forEach((key) => {
            selected[key] = true;
        });


        // Make a lookup table
        const freezed: BooleanMap = {};
        this.columnFreeze.model.forEach((key) => {
            freezed[key] = true;
        });

        config.outputs = this.columnOutput.labels.reduce((prev, curr) => {
            prev[curr.key] = this.columnOutput.model.some((x) => x === curr.key);
            return prev;
        }, {});

        // Update each available column
        this.columnSelect.labels.forEach((column) => {
            const key = column.key;

            const columnConfig = new BulkDataColumnConfig();

            // Columns are visible unless explicitly hidden
            columnConfig.visible = selected[key] ? true : false;
            columnConfig.freeze = freezed[key] ? true : false;

            config.columns[key] = columnConfig;
        });

        // Rebuild the BulkDataConfiguration JSON
        this.facet.BulkDataConfiguration = JSON.stringify(config);

        // Save just the BulkDataConfiguration value in the facet
        this.workspaceService.saveBulkDataConfiguration(this.facet);
    }

    /**
     * Find labels for all the unique Inputs used in all the tasks.
     */
    findInputs(tasks: any[]): IOColumn[] {
        const seen: BooleanMap = {};

        const columns: IOColumn[] = [];
        tasks.forEach((task: any) => {
            if (!task.TaskInput) {
                // No Inputs
                return;
            }

            // Sort the Inputs by SortOrder
            const taskInputs: any[] = sortObjectArrayByAccessor(
                task.TaskInput,
                (taskInput: any) => {
                    return getSafeProp(taskInput, 'Input.SortOrder');
                }
            );

            // Add new columns
            taskInputs.forEach((taskInput: any) => {
                if (seen[taskInput.C_Input_key]) {
                    // Already added
                    return;
                }

                // Add the Input label
                columns.push(
                    new IOColumn(taskInput.C_Input_key, taskInput.Input.InputName),
                );

                // Remember this Input
                seen[taskInput.C_Input_key] = true;
            });
        });

        if (columns.length > this.MAX_INPUTS) {
            this.loggingService.logWarning(
                `Could not show all ${columns.length} Inputs. (limit of ${this.MAX_INPUTS})`,
                null, this.COMPONENT_LOG_TAG, true
            );

            // Hide additional columns
            columns.forEach((column: IOColumn, index: number) => {
                if (index >= this.MAX_INPUTS) {
                    column.visible = false;
                }
            });
        }

        return columns;
    }

    /**
     * Find labels for all the unique Outputs used in all the tasks.
     */
    findOutputs(tasks: any[]): IOColumn[] {
        const seen: BooleanMap = {};

        const columns: IOColumn[] = [];
        tasks.forEach((task: any) => {
            if (!task.WorkflowTask || !task.WorkflowTask.Output) {
                // No Outputs
                return;
            }

            // Sort the Outputs by SortOrder
            const outputs: any[] = sortObjectArrayByAccessor(
                task.WorkflowTask.Output,
                (output: any) => {
                    return output.SortOrder;
                }
            );

            outputs.forEach((output: any) => {
                if (seen[output.C_Output_key]) {
                    // Already added
                    return;
                }

                if (!output.IsActive) {
                    // Do not include inactive outputs
                    return;
                }

                // Do not include outputs in historical task instances - outputDiff
                // Include outputs if edit task modal is used to create new task version - workflowTaskDiff
                const outputDiff = convertValueToLuxon(output.DateCreated).diff(convertValueToLuxon(task.DateCreated)).as("milliseconds") > 0; 
                const workflowTaskDiff = convertValueToLuxon(task.WorkflowTask.DateCreated).diff(convertValueToLuxon(task.DateCreated)).as("milliseconds") > 0;
                if (outputDiff && !workflowTaskDiff) {
                    return;
                }

                // Add the Output label and the Output itself for the bulk filldown
                const column = new IOColumn(output.C_Output_key, output.OutputName, output);

                const dataType = getSafeProp(output, 'cv_DataType.DataType');
                if (dataType === DataType.CALCULATED ||
                    dataType === DataType.INHERITED_MOST_RECENT ||
                    dataType === DataType.INHERITED_FIRST_OCCURRENCE ||
                    dataType === DataType.INHERITED_SECOND_MOST_RECENT ||
                    dataType === DataType.INHERITED_THIRD_MOST_RECENT
                ) {
                    // Hide the bulk header popup for Calculated and Inherited outputs
                    column.showBulk = false;
                }

                if (dataType === DataType.INHERITED_MOST_RECENT ||
                    dataType === DataType.INHERITED_FIRST_OCCURRENCE ||
                    dataType === DataType.INHERITED_SECOND_MOST_RECENT ||
                    dataType === DataType.INHERITED_THIRD_MOST_RECENT
                ) {
                    column.isInherited = true;
                }

                if (dataType === DataType.CALCULATED) {
                    column.isCalculated = true;
                }

                // Add to the columns
                columns.push(column);

                // Remember this Output
                seen[output.C_Output_key] = true;
            });
        });

        if (columns.length > this.MAX_OUTPUTS) {
            this.loggingService.logWarning(
                `Could not show all ${columns.length} Outputs. (limit of ${this.MAX_OUTPUTS})`,
                null, this.COMPONENT_LOG_TAG, true
            );

            // Hide additional columns
            columns.forEach((column: IOColumn, index: number) => {
                if (index >= this.MAX_OUTPUTS) {
                    column.visible = false;
                }
            });
        }

        return columns;
    }

    /**
     * Initialize the rows to be displayed in the main table.
     * 
     * A task with multiple Animals, Samples, or OutputSets will have multiple
     * rows and some duplicate data will be elided in those extra rows.
     */
    initRows(tasks: any[]): DataRow[] {
        const rows: DataRow[] = [];
        const taskInstanceKeys: Set<any> = new Set();
        tasks.forEach((task) => {
            const taskRows: DataRow[] = [];

            let defaultRow = new DataRow();
            defaultRow.task = task;
            defaultRow.job = getSafeProp(task, 'TaskJob[0].Job');

            // Map the TaskInputs/Outputs by key for easier lookup
            defaultRow.inputs = this.mapInputs(task);
            defaultRow.outputs = this.mapOutputs(task);

            // Map the TaskOutputSets by Material for easier lookup
            const taskOutputSets = this.mapTaskOutputSets(task);

            if (task.DateDue) {
                task.DateDueSortable = (new Date(this.dateFormatterService.formatDateOnly(task.DateDue))).getTime().toString();
            }
            if (task.DateComplete) {
                task.DateCompleteSortable = (new Date(this.dateFormatterService.formatDateOnly(task.DateComplete))).getTime().toString();
            }
            if (task.DateReviewed) {
                task.DateReviewedSortable = (new Date(this.dateFormatterService.formatDateOnly(task.DateReviewed))).getTime().toString();
            }

            if (notEmpty(task.TaskMaterial)) {
                // Split the Materials into Animals and Samples
                const animals: any[] = task.TaskMaterial.map((tm: any) => {
                    return getSafeProp(tm, 'Material.Animal');
                }).filter((animal: any) => animal);

                let samples: any[] = task.TaskMaterial.map((tm: any) => {
                    return getSafeProp(tm, 'Material.Sample');
                }).filter((sample: any) => sample);

                // Map Animals keys to entities
                const animalMap: EntityMap = {};
                animals.forEach((animal: any) => {
                    animalMap[animal.C_Material_key] = animal;
                });

                // Map Samples keys to entities
                const sampleMap: EntityMap = {};
                samples.forEach((sample: any) => {
                    sampleMap[sample.C_Material_key] = sample;
                });

                // Map Animals keys to Samples and track which Samples are accounted for
                const sampleHasAnimal: BooleanMap = {};
                const animalToSamples: { [key: string]: any[] } = {};

                // Map Samples keys to PrimarySamples and track which Samples are accounted for
                const sampleHasPrimarySample: BooleanMap = {};
                const primarySampleToSamples: { [key: string]: any[] } = {};

                samples.forEach((sample: any) => {
                    const animalSources = this.getAnimalSources(sample);
                    const sampleSources = this.getSampleSources(sample);

                    if ((!animalSources || (animalSources.length === 0)) && (!sampleSources || (sampleSources.length === 0))) {
                        // No source Material... weird
                        return;
                    }

                    // Associate this Sample with an Animal
                    if (animalSources.length === 1) {
                        const animalKey = animalSources[0].C_SourceMaterial_key;
                        if (animalMap[animalKey]) {
                            if (!animalToSamples[animalKey]) {
                                animalToSamples[animalKey] = [];
                            }
                            animalToSamples[animalKey].push(sample);
                            sampleHasAnimal[sample.C_Material_key] = true;
                        }
                    }
                    // Associate this Sample with a PrimarySample
                    if (sampleSources.length === 1) {
                        const sampleKey = sampleSources[0].C_SourceMaterial_key;
                        if (sampleMap[sampleKey]) {
                            if (!primarySampleToSamples[sampleKey]) {
                                primarySampleToSamples[sampleKey] = [];
                            }
                            primarySampleToSamples[sampleKey].push(sample);
                            sampleHasPrimarySample[sample.C_Material_key] = true;
                        }
                    }
                });

                // Filter out the samples that are associated with an animal or primary sample
                samples = samples.filter((sample) => {
                    return (!sampleHasAnimal[sample.C_Material_key] && !sampleHasPrimarySample[sample.C_Material_key]);
                });

                // Add samples without animals to primarySamples
                this.primarySamples = this.primarySamples.concat(samples);

                // Animals, possibly with samples
                animals.forEach((animal: any) => {
                    const animalSamples: any[] = animalToSamples[animal.C_Material_key] || [null];

                    // Get the TaskMaterial for the Animal for scanning
                    const scanMaterial = task.TaskMaterial.find((tm: any) => {
                        return tm.C_Material_key === animal.C_Material_key;
                    });

                    animal.AnimalCohorts = this.getCohorts(animal);
                    animal.currentHousingId = this.getCurrentHousingId(animal);


                    animalSamples.forEach((outputSample: any, index: number) => {
                        taskRows.push(defaultRow.extend({
                            animal,
                            outputSample,
                            scanMaterial,
                            // Track the first row of a task for cell elision.
                            animalFirst: (index === 0),
                        }));
                    });


                    defaultRow = this.addCharacteristicsToDataRow(animal, defaultRow);
                });

                // Samples without animals, possibly with primary samples
                samples.forEach((sample: any) => {
                    const secondarySamples: any[] = primarySampleToSamples[sample.C_Material_key] || [null];

                    // Get the TaskMaterial for the Animal for scanning
                    const scanMaterial = task.TaskMaterial.find((tm: any) => {
                        return tm.C_Material_key === sample.C_Material_key;
                    });

                    secondarySamples.forEach((outputSample: any, index: number) => {
                        taskRows.push(defaultRow.extend({
                            sample,
                            outputSample,
                            scanMaterial,
                            // Track the first row of a task for cell elision.
                            sampleFirst: (index === 0),
                        }));
                    });
                });
            } else if (notEmpty(task.TaskAnimalHealthRecord)) {
                // Link animal to task for Treatment Plans
                const animals: any[] = task.TaskAnimalHealthRecord.map((tahr: any) => {
                    return getSafeProp(tahr, 'AnimalHealthRecord.Animal');
                });

                // Animals should theoretically only contain one animal
                animals.forEach((animal: any, index: number) => {
                    animal.AnimalCohorts = this.getCohorts(animal);
                    animal.currentHousingId = this.getCurrentHousingId(animal);
                    taskRows.push(defaultRow.extend({
                        animal,
                        scanMaterial: { ScanValue: '' },
                        animalFirst: (index === 0),
                        taskOutputSet: this.mapTaskOutputSets(task)[this.KEY_NO_MATERIAL],
                    }));
                });
            } else if (notEmpty(task.TaskMaterialPool)) {
                // Link MaterialPool to task for Housing and Mating type tasks
                const materialPools: any[] = task.TaskMaterialPool.map((tmp: any) => {
                    return getSafeProp(tmp, 'MaterialPool');
                });

                // MaterialPools should theoretically only contain one materialPool
                materialPools.forEach((materialPool: any) => {
                    taskRows.push(defaultRow.extend({
                        taskMaterialPool: materialPool,
                        currentHousingId: materialPool.MaterialPoolID
                    }));
                });
            } else if (notEmpty(task.TaskBirth)) {
                // Link MaterialPool to task for Birth type tasks
                const materialPools: any[] = task.TaskBirth.map((tmp: any) => {
                    return getSafeProp(tmp, 'Birth.Mating.MaterialPool');
                });

                // MaterialPools should theoretically only contain one materialPool
                materialPools.forEach((materialPool: any) => {
                    taskRows.push(defaultRow.extend({
                        taskMaterialPool: materialPool,
                        currentHousingId: materialPool.MaterialPoolID
                    }));
                });
            } else {
                // No materials at all
                taskRows.push(defaultRow.extend({}));
            }
            // TODO: Separate rows for Output sets
            taskRows.forEach((row: DataRow, index: number) => {
                // Note which row is the first for the task
                row.taskFirst = (index === 0);

                if (row.task.C_GroupTaskInstance_key != null || row.task.IsGroup === false) {
                    // Assign, or create, the TaskOuputSet
                    this.assignTaskOutputSet(row, taskOutputSets);
                    row.material = getSafeProp(row.sample, 'Material');

                    // Load parent Key for cohorts
                    let taskInstanceKey: any;
                    if (task.C_GroupTaskInstance_key == null) {
                        taskInstanceKey = task.C_TaskInstance_key;
                    } else {
                        taskInstanceKey = task.C_GroupTaskInstance_key;
                    }
                    taskInstanceKeys.add(taskInstanceKey);

                    rows.push(row);
                }
            });
            // Track which row are associated 
            this.taskRows[task.C_TaskInstance_key] = taskRows;
        });

        // Get cohorts
        this.cohortsPromise = this.workflowService.loadAllTaskCohorts(Array.from(taskInstanceKeys.values()))
        .then((taskCohorts: TaskCohort[]) => {
            for (const taskCohort of taskCohorts) {
                if (!this.cohorts.has(taskCohort.Cohort)) {
                    this.cohorts.add(taskCohort.Cohort);
                }
            }

            rows.forEach((row: DataRow) => {
                // Set AnimalCohort value after we know this.cohorts
                if (row.animal) {
                    row.animal.AnimalCohorts = this.getCohorts(row.animal);
                    row.currentHousingId = this.getCurrentHousingId(row.animal);
                }
            });
        });

        // Do some final cleanup on all the rows
        rows.forEach((row: DataRow, index: number) => {
            row.index = index;

            // Finally, figure out the classes for each row
            row.classes = {
                task: { 'task-extra': !row.taskFirst },
                animal: { 'animal-extra': !row.animalFirst },
                sample: { 'sample-extra': !row.sampleFirst },
            };

            // Create the row key for trackBy 
            const taskKey = row.task.C_TaskInstance_key;
            const animalKey = row.animal ? row.animal.C_Material_key : 'x';
            const sampleKey = row.sample ? row.sample.C_Material_key : 'x';
            row.key = `${taskKey}-${animalKey}-${sampleKey}`;
        });
        return rows;
    }

    /**
     * Adds the characteristics set to be in the list view if they are found on the supplied animal. 
     * @param animal
     * @param dataRow
     */
    addCharacteristicsToDataRow(animal: Animal, dataRow: DataRow): DataRow {
        // define a list of characteristics and their values.
        this.taxonCharacteristicsInListView.forEach((taxonCharacteristic: TaxonCharacteristic) => {
            if (animal.TaxonCharacteristicInstance) {
                const characteristicInstance: TaxonCharacteristicInstance = animal.TaxonCharacteristicInstance
                    .find((tci: TaxonCharacteristicInstance) => tci.C_TaxonCharacteristic_key === taxonCharacteristic.C_TaxonCharacteristic_key);
                if (characteristicInstance) {
                    dataRow.characteristics[taxonCharacteristic.C_TaxonCharacteristic_key] = characteristicInstance;
                } else {
                    dataRow.characteristics[taxonCharacteristic.C_TaxonCharacteristic_key] = null;
                }
            }
        });
        return dataRow;
    }

    /**
     * Return only the Animal Cohorts
     * @param row
     */
    getAnimalCohorts(row: any): any[] {
        if (row.animal) {
            const cohorts = row.animal.Material.CohortMaterial;
            const finalCohorts: any = [];
            if (cohorts && cohorts.length > 0) {
                this.cohorts.forEach((cohort: any) => {
                    const index = cohorts.findIndex((item: any) => item.C_Cohort_key === cohort.C_Cohort_key);
                    if (index > -1) {
                        finalCohorts.push(cohort);
                    }
                });
                return finalCohorts;
            }
            return [];
        }
        return [];
    }

    getCohorts(animal: any): string {
        if (animal) {
            const cohorts = animal.Material.CohortMaterial;
            const finalCohorts: any = [];
            if (cohorts && cohorts.length > 0) {
                this.cohorts.forEach((cohort: any) => {
                    const index = cohorts.findIndex((item: any) => item.C_Cohort_key === cohort.C_Cohort_key);
                    if (index > -1) {
                        finalCohorts.push(cohort);
                    }
                });
                return finalCohorts.map((cohort: any) => cohort.CohortName).join(', ');
            }
            return "";
        }
        return "";
    }

    getCurrentHousingId(animal: any): string {
        if (animal) {
            if (animal.Material && animal.Material.MaterialPoolMaterial) {
                return currentMaterialHousingID(animal.Material.MaterialPoolMaterial);
            }
            return "";
        }
        return "";
    }

    /**
     * Return only the Animal MaterialSourceMaterial associations
     * @param sample 
     */
    getAnimalSources(sample: any): any[] {
        let sources = getSafeProp(sample, 'Material.MaterialSourceMaterial');
        sources = sources.filter((item: any) => {
            return getSafeProp(item, 'SourceMaterial.Animal');
        });
        return sources;
    }

    /**
     * Return only the Sample MaterialSourceMaterial associations
     * @param sample 
     */
    getSampleSources(sample: any): any[] {
        let sources = getSafeProp(sample, 'Material.MaterialSourceMaterial');
        sources = sources.filter((item: any) => {
            return getSafeProp(item, 'SourceMaterial.Sample');
        });
        return sources;
    }

    /**
     * Create a map of this task's TaskInputs by Input key
     */
    mapInputs(task: any) {
        const inputs: any = {};

        if (task.TaskInput) {
            task.TaskInput.forEach((input: any) => {
                inputs[input.C_Input_key] = input;
            });
        }

        return inputs;
    }

    /**
     * Create a map of this task's Outputs (via WorkflowTask) by Output key
     */
    mapOutputs(task: any): EntityMap {
        const outputs: EntityMap = {};

        if (task.WorkflowTask && task.WorkflowTask.Output) {
            task.WorkflowTask.Output.forEach((output: any) => {
                outputs[output.C_Output_key] = output;
            });
        }

        return outputs;
    }

    /**
     * Create a map of TaskOutputSets by Material key.
     */
    mapTaskOutputSets(task: any): EntityMap {
        const taskOutputSetMap: EntityMap = {};

        if (!task || !task.TaskOutputSet) {
            return taskOutputSetMap;
        }

        task.TaskOutputSet.forEach((tos: any) => {
            if (!tos.TaskOutputSetMaterial || (tos.TaskOutputSetMaterial.length === 0)) {
                // Could not find any material... weird.
                taskOutputSetMap[this.KEY_NO_MATERIAL] = tos;
                return;
            }

            // Map the TaskOutputSet by the first Material
            const materialKey = tos.TaskOutputSetMaterial[0].C_Material_key;
            taskOutputSetMap[materialKey] = tos;
        });
        return taskOutputSetMap;
    }

    /**
     * Assign the TaskOutputSet that matches this row.
     * 
     * Checks the Sample first, then the Animal, then no material.
     */
    assignTaskOutputSet(row: DataRow, taskOutputSetMap: EntityMap): any {
        if (row.sample && taskOutputSetMap[row.sample.C_Material_key]) {
            row.taskOutputSet = taskOutputSetMap[row.sample.C_Material_key];
        } else if (row.animal && taskOutputSetMap[row.animal.C_Material_key]) {
            row.taskOutputSet = taskOutputSetMap[row.animal.C_Material_key];
            row.outputAnimal = row.animal;
        } else if (!row.sample && !row.animal && taskOutputSetMap[this.KEY_NO_MATERIAL]) {
            row.taskOutputSet = taskOutputSetMap[this.KEY_NO_MATERIAL];
        }

        if (row.taskOutputSet) {
            // Map the TaskOutputs by Output key.
            row.taskOutputs = this.mapTaskOutputs(row.taskOutputSet);
        } else {
            // Create a new TaskOutputSet for the row
            this.createTaskOutputSet(row);
        }

        // Check if the row has inherited outputs and needs to fetch values
        for (const col of this.outputColumns) {
            if (row.outputs[col.key]) {
                // some unchecked checkbox values are "false" while others are null so convert null values to "false" to fix sorting
                if (col.output.cv_DataType && col.output.cv_DataType.DataType === 'Boolean') {
                    if (row.taskOutputs[col.key].OutputValue === null) {
                        row.taskOutputs[col.key].OutputValue = "false";
                    }
                }
                if (col.isInherited) {
                    row.hasInherited = true;
                    if (row.taskOutputs[col.key] !== undefined && (row.taskOutputs[col.key].OutputValue === null || row.taskOutputs[col.key].OutputValue === "")) {
                        row.needsInherited = true;
                        break;
                    }
                } else if (col.isCalculated) {
                    row.hasCalculated = true;
                }
            }
        }
    }

    /**
     * Create a map of TaskOutputs by the Output key.
     */
    mapTaskOutputs(taskOutputSet: any): EntityMap {
        const taskOutputs: EntityMap = {};

        this.workflowLogicService.updateTaskOutputSetFlags(taskOutputSet);
        if (taskOutputSet && taskOutputSet.TaskOutput) {
            taskOutputSet.TaskOutput.forEach((taskOutput: any) => {
                taskOutputs[taskOutput.C_Output_key] = taskOutput;
            });
        }

        return taskOutputs;
    }

    /**
     * Create a new TaskOutputSet for the row
     */
    createTaskOutputSet(row: DataRow) {
        const task = row.task;

        const outputs = getSafeProp(task, 'WorkflowTask.Output');
        if (!outputs || (outputs.length === 0)) {
            // This task does not have Outputs... nothing to do
            return;
        }

        // Create the new TaskOutputSet
        const taskOutputSet = this.dataManager.createEntity('TaskOutputSet', {
            C_TaskInstance_key: row.task.C_TaskInstance_key,
        });

        // Associate the Material
        let material: any = null;
        if (row.sample) {
            material = row.sample.Material;
        } else if (row.animal) {
            row.outputAnimal = row.animal;
            material = row.animal.Material;
        }
        if (material) {
            this.dataManager.createEntity('TaskOutputSetMaterial', {
                C_TaskOutputSet_key: taskOutputSet.C_TaskOutputSet_key,
                C_Material_key: material.C_Material_key,
            });
        }

        // Add the TaskOutputs
        const taskOutputs: EntityMap = {};
        this.outputColumns.forEach((col) => {
            if (row.outputs[col.key]) {
                // Create a new TaskOutput
                taskOutputs[col.key] = this.workflowService.createOutput(parseInt(col.key, null), taskOutputSet.C_TaskOutputSet_key);
            }
        });

        // Update the row
        row.taskOutputSet = taskOutputSet;
        row.taskOutputs = taskOutputs;
    }

    /**
     * Helper function to identify columns in per-row *ngFor loop.
     * 
     * Note: "this" is not available when used by *ngFor.
     */
    trackColumns(_: any, column: IOColumn): string {
        return column.key;
    }

    /**
     * Helper function to identify rows in main *ngFor loop.
     * 
     * Note: "this" is not available when used by *ngFor.
     */
    trackRows(_: any, row: DataRow): string {
        return row.key;
    }

    /**
     * Mark the selected row as the current row.
     */
    setCurrentRow(index: number) {
        this.showEndStateModal(index);
        if (index !== this.currentIndex) {
            this.currentIndex = index;
            this.syncTaskIfNeeded(index);
        }
    }

    /**
     * Move the current row to the next oncompleted row.
     */
    advanceCurrentRow() {
        let currentTaskKey = null;
        if (this.rows[this.currentIndex] !== undefined) {
            currentTaskKey = this.rows[this.currentIndex].task.C_TaskInstance_key;
        }

        // Advance the current selection
        for (let i = this.currentIndex; i < this.rows.length; ++i) {
            const row = this.rows[i];
            const task = row.task;

            if (row.taskOutputSet) {
                if (row.taskOutputSet.CollectionDateTime) {
                    // Skip collected rows
                    continue;
                }
            } else if (this.taskIsEndState(task)) {
                // Need to find a task that is not completed
                continue;
            }

            // Move the focus to the first input element in the new row.
            this.currentIndex = i;
            this.focusCurrentRow();
            this.syncTaskIfNeeded(this.currentIndex);
            break;
        }
    }

    /**
     * Focus on the first output element in the current row
     */
    focusCurrentRow() {
        // Look for an INPUT or SELECT in the "output" columns of the
        // current row.
        this.setFocusToNextDataInput(this.currentIndex);
    }

    /**
     * Handle a row input getting focus through keyboard events.
     * 
     * @param event JQuery event
     */
    rowInputFocus(event: JQuery.TriggeredEvent) {
        // Get the index for the parent row
        const index: number = jQuery(event.target).parents('tr').first().data('index');
        if (index !== this.currentIndex) {
            // Update the current index
            this.currentIndex = index;
            this.syncTaskIfNeeded(index);
        }
    }


    /**
     * Check for a match when the ScanValue changes
     */
    scanValueChanged(row: DataRow) {
        // Clear any check that is underway
        window.clearTimeout(row.scanTimeout);

        if (!row.animal || !row.scanMaterial) {
            // Nothing to check
            return;
        }

        const animal = row.animal;
        const scanMaterial = row.scanMaterial;

        // Get the Scan input
        let scanValue = scanMaterial.ScanValue;
        if (scanValue !== null) {
            scanValue = scanValue.trim();
        }

        if ((scanValue === null) || (scanValue === '')) {
            // Nothing was scanned
            scanMaterial.ScanValue = null;
            scanMaterial.IsScanValid = false;
            return;
        }

        if ((scanValue === animal.AnimalName) ||
            (scanValue === animal.Material.MicrochipIdentifier)) {
            // The scan matches the current animal
            scanMaterial.ScanValue = animal.AnimalName;
            scanMaterial.IsScanValid = true;
            return;
        }

        // Check the DB for matching animals with a small debounce timeout
        row.scanTimeout = window.setTimeout(() => {
            // Check if the scan matches any active animals
            const query = EntityQuery.from('Animals')
                .where(Predicate.and([
                    Predicate.or([
                        Predicate.create('AnimalName', 'eq', scanValue),
                        Predicate.create('Material.MicrochipIdentifier', 'eq', scanValue)
                    ]),
                    Predicate.create('cv_AnimalStatus.IsExitStatus', 'ne', true)
                ]));

            this.dataManager.returnQueryResults(query).then((found) => {
                if ((scanMaterial.ScanValue === null) ||
                    (scanMaterial.ScanValue.trim() !== scanValue)) {
                    // The ScanValue has changed since the query started.
                    return;
                }
                scanMaterial.IsScanValid = false;
                if (found.length === 1) {
                    scanMaterial.ScanValue = found[0].AnimalName;
                } else if (found.length > 1) {
                    this.loggingService.logWarning(
                        `Found ${found.length} animals that match the scan value “${scanValue}”.`,
                        null, this.COMPONENT_LOG_TAG, true
                    );
                }
            });
        }, 300);
    }

    /**
     * Deal with a change a single output change
     */
    outputChanged(row: DataRow, outputCol: IOColumn) {
        // Make any additional updates
        this.updateTaskOutputSet(row, outputCol);
    }

    /**
     * Deal with the user clicking the Inherited button.
     */
    async inheritedClicked($event: Event, row: DataRow) {
        // Don't propagate the click to the parent row
        $event.stopPropagation();

        if (row.taskOutputSet.CollectionDateTime) {
            // This output has already been collected, so let's be careful
            // about changing the inherited values
            const hasInheritedValues = this.outputColumns.some((col) => {
                return col.isInherited && row.taskOutputs[col.key] &&
                    (row.taskOutputs[col.key].OutputValue !== null);
            });

            if (hasInheritedValues) {
                const options = {
                    title: 'Overwrite existing values?',
                    message: 'Some of the inherited values have already been set. ' +
                        'Are you sure you want to update the values?',
                };

                // Confirm before fetching the inherited values
                try {
                    await this.confirmService.confirm(options);
                } catch {
                    // Canceled
                    return;
                }
            }
        }

        // Just fetch the inherited values
        await this.fetchInheritedValues(row);
        this.calculateStatsCohortWise();
        this.workflowService.recalculateValues(row.task, row.taskOutputSet);
        this.workflowLogicService.updateTaskOutputSetFlags(row.taskOutputSet);
        if (!this.animalSyncEnabled) {
            return;
        }
        for (const outputCol of this.outputColumns) {
            if (outputCol.isInherited) {
                this.syncOutput(row.taskOutputs[outputCol.key]);
            }
        }
    }

    /**
     * Deal with the user clicking the Collected button.
     */
    collectedClicked($event: Event, row: DataRow) {
        // Don't propagate the click to the parent row
        this.showEndStateModal(row.index);
        $event.stopPropagation();

        const collected = !(row.taskOutputSet && row.taskOutputSet.CollectionDateTime);
        this.updateCollected(row, collected, true);
        if (collected) {
            this.advanceCurrentRow();
        }
    }

    setFocusToNextDataInput(activeLine: number): void {
        const isLastLine = activeLine >= this.rows.length;
        if (isLastLine) {
            return;
        }

        const outputContainers = this.outputContainers.toArray();
        const outputsHidden = this.columnOutput.labels.length - this.columnOutput.model.length;
        const outputsAmountInLine = this.outputColumns.length - outputsHidden;
        const nextLineFirstOutputContainerIndex = activeLine * outputsAmountInLine;

        // Here I use outputContainers for the next line and further lines.
        // If the next line doesn't contain any outputElements it will find
        // them in the lines after the next line.
        const lineOutputContainers = outputContainers.slice(nextLineFirstOutputContainerIndex);
        const outputElement = this.getOutputElement(lineOutputContainers);

        setTimeout(() => {
            outputElement?.focus();
        }, 0);
    }

    private getOutputElement(dataOutputs: ElementRef[]): HTMLElement | null {
        const outputsAmountInLine = this.outputColumns.length;
        const skipIndexes = this.outputColumns.reduce((acc, { isInherited, isCalculated }, index) => {
            return { ...acc, [index]: isInherited || isCalculated };
        }, {} as Record<number, boolean>);

        for (let i = 0; i < dataOutputs.length; i++) {
            const indexToCheck = i - Math.floor(i / outputsAmountInLine) * outputsAmountInLine;
            if (skipIndexes[indexToCheck]) {
                continue;
            }
            const focusableElement = this.getKeyboardFocusableElement(dataOutputs[i].nativeElement);
            if (!focusableElement) {
                continue;
            }

            return focusableElement;
        }

        return null;
    }

    private getKeyboardFocusableElement(element: HTMLElement): HTMLElement {
        return element.querySelectorAll(
            'a, button:not(.now-button), input, textarea, select, details, [tabindex]:not([tabindex="-1"])',
        )[0] as HTMLElement;
    }

    /**
     * Deal with a the user clicking the Reviewed button
     */
    reviewedClicked($event: Event, row: DataRow) {
        this.showEndStateModal(row.index);
        // Don't propagate the click to the parent row
        $event.stopPropagation();

        const reviewed = !getSafeProp(row, 'task.IsReviewed');
        this.updateReviewed(row.task, reviewed, true);
    }

    /**
     * Should the Update button be disabled for the DateDue bulk header?
     * 
     * Note: This function gets called by Angular alot! It should be simple.
     */
    bulkDateDueDisabled() {
        // Missing one of Task, Date, Unit
        if (!this.bulk.dateDueTaskAlias || !this.bulk.dateDue || !this.bulk.dateDueUnitKey) {
            return true;
        }

        // Missing a numeric increment (0 is OK)
        if (!this.bulk.dateDueIncrement && (this.bulk.dateDueIncrement !== 0)) {
            return true;
        }

        return false;
    }

    /**
     * Bulk update an output value
     */
    bulkOutputChanged(outputCol: IOColumn) {
        for (const row of this.rows) {
            if (this.isGLP && row.taskOutputs[outputCol.key].OutputValue != null) {
                continue;
            }
            if (row.taskOutputs[outputCol.key] && !row.task.IsWorkflowLocked) {
                row.taskOutputs[outputCol.key].OutputValue = outputCol.value;

                // Make any additional updates
                this.updateTaskOutputSet(row, outputCol);
            }
        }

        outputCol.value = null;
    }

    /**
     * Bulk update the Sample measurement
     */
    bulkSampleMeasurementChanged() {
        for (const row of this.rows) {
            if (!row.task.IsWorkflowLocked) {
                if (row.outputSample) {
                    row.outputSample.Volume = this.bulk.sampleVolume;
                } else if (!row.outputSample && row.sample) {
                    row.sample.Volume = this.bulk.sampleVolume;
                }
            }
        }
    }

    /**
     * Bulk update the Sample unit
     */
    bulkSampleUnitChanged() {
        for (const row of this.rows) {
            if (!row.task.IsWorkflowLocked) {
                if (row.outputSample) {
                    row.outputSample.C_Unit_key = this.bulk.sampleUnitKey;
                } else if (!row.outputSample && row.sample) {
                    row.sample.C_Unit_key = this.bulk.sampleUnitKey;
                }
            }
        }
    }

    /**
     * Bulk update the Sample type
     */
    bulkSampleTypeChanged() {
        for (const row of this.rows) {
            if (!row.task.IsWorkflowLocked) {
                if (row.outputSample) {
                    row.outputSample.C_SampleType_key = this.bulk.sampleTypeKey;
                } else if (!row.outputSample && row.sample) {
                    row.sample.C_SampleType_key = this.bulk.sampleTypeKey;
                }
            }
        }
    }

    /**
     * Bulk update the Sample status
     */
    bulkSampleStatusChanged() {
        for (const row of this.rows) {
            if (!row.task.IsWorkflowLocked) {
                if (row.outputSample) {
                    row.outputSample.C_SampleStatus_key = this.bulk.sampleStatusKey;
                } else if (!row.outputSample && row.sample) {
                    row.sample.C_SampleStatus_key = this.bulk.sampleStatusKey;
                }
            }
        }
    }

    /**
     * Bulk update the Sample preservation method
     */
    bulkSamplePreservationMethodChanged() {
        for (const row of this.rows) {
            if (!row.task.IsWorkflowLocked) {
                if (row.outputSample) {
                    row.outputSample.C_PreservationMethod_key = this.bulk.samplePreservationMethodKey;
                } else if (!row.outputSample && row.sample) {
                    row.sample.C_PreservationMethod_key = this.bulk.samplePreservationMethodKey;
                }
            }
        }
    }

    /**
     * Bulk update the Sample container type
     */
    bulkSampleContainerTypeChanged() {
        for (const row of this.rows) {
            if (!row.task.IsWorkflowLocked) {
                if (row.outputSample) {
                    row.outputSample.Material.C_ContainerType_key = this.bulk.sampleContainerTypeKey;
                } else if (!row.outputSample && row.sample) {
                    row.sample.Material.C_ContainerType_key = this.bulk.sampleContainerTypeKey;
                }
            }
        }
    }

    /**
     * Bulk update the Sample subtype
     */
    bulkSampleSubtypeChanged() {
        for (const row of this.rows) {
            if (!row.task.IsWorkflowLocked) {
                if (row.outputSample) {
                    row.outputSample.C_SampleSubtype_key = this.bulk.sampleSubtypeKey;
                } else if (!row.outputSample && row.sample) {
                    row.sample.C_SampleSubtype_key = this.bulk.sampleSubtypeKey;
                }
            }
        }
    }

    /**
     * Bulk update the Sample processing method
     */
    bulkSampleProcessingMethodChanged() {
        for (const row of this.rows) {
            if (!row.task.IsWorkflowLocked) {
                if (row.outputSample) {
                    row.outputSample.C_SampleProcessingMethod_key = this.bulk.sampleProcessingMethodKey;
                } else if (!row.outputSample && row.sample) {
                    row.sample.C_SampleProcessingMethod_key = this.bulk.sampleProcessingMethodKey;
                }
            }
        }
    }

    /**
     * Bulk update the Sample send to
     */
    bulkSampleSendToChanged() {
        for (const row of this.rows) {
            if (!row.task.IsWorkflowLocked) {
                if (row.outputSample) {
                    row.outputSample.SendTo = this.bulk.sendTo;
                } else if (!row.outputSample && row.sample) {
                    row.sample.SendTo = this.bulk.sendTo;
                }
            }
        }
    }

    /**
     * Bulk update the Sample analysis method
     */
    bulkSampleAnalysisMethodChanged() {
        for (const row of this.rows) {
            if (!row.task.IsWorkflowLocked) {
                if (row.outputSample) {
                    row.outputSample.C_SampleAnalysisMethod_key = this.bulk.sampleAnalysisMethodKey;
                } else if (!row.outputSample && row.sample) {
                    row.sample.C_SampleAnalysisMethod_key = this.bulk.sampleAnalysisMethodKey;
                }
            }
        }
    }

    bulkSpecialInstructionsChanged() {
        for (const row of this.rows) {
            if (!row.task.IsWorkflowLocked) {
                if (row.outputSample) {
                    row.outputSample.SpecialInstructions = this.bulk.specialInstructions;
                } else if (!row.outputSample && row.sample) {
                    row.sample.SpecialInstructions = this.bulk.specialInstructions;
                }
            }
        }
    }

    /**
     * Bulk update the Sample lot number
     */
    bulkSampleLotNumberChanged() {
        for (const row of this.rows) {
            if (!row.task.IsWorkflowLocked) {
                if (row.outputSample) {
                    row.outputSample.LotNumber = this.bulk.lotNumber;
                } else if (!row.outputSample && row.sample) {
                    row.sample.LotNumber = this.bulk.lotNumber;
                }
            }
        }
    }

    /**
     * Bulk update the Collected flag
     */
    bulkCollectedChanged() {
        const force = this.areBulkChangesForced();

        for (const row of this.rows) {
            if (!row.task.IsWorkflowLocked) {
                // Update the Collected By and Time
                this.updateCollected(row, true, force);
            }
        }
    }

    /**
     * Bulk update the Collected By value.
     */
    bulkCollectedByChanged() {
        // Fetch (and reset) the Forced flag
        const force = this.areBulkChangesForced();

        for (const row of this.rows) {
            if (!row.task.IsWorkflowLocked) {
                if (!row.taskOutputSet) {
                    // No outputs
                    continue;
                }

                if (force || !row.taskOutputSet.C_Resource_key) {
                    row.taskOutputSet.C_Resource_key = this.bulk.collectedByKey;
                }
            }
        }
    }

    /**
     * Bulk update the Collected Time value.
     */
    bulkCollectedTimeChanged() {
        // Fetch (and reset) the Forced flag
        const force = this.areBulkChangesForced();

        for (const row of this.rows) {
            if (!row.task.IsWorkflowLocked) {
                if (!row.taskOutputSet) {
                    // No outputs
                    continue;
                }

                if (force || !row.taskOutputSet.CollectionDateTime) {
                    row.taskOutputSet.CollectionDateTime = this.bulk.collectedTime;
                }
            }
        }
    }

    bulkFetchInherited() {
        this.setLoading(true);
        const promises: any = [];
        this.rows.forEach((row: DataRow) => {
            if (!row.task.C_CompletedBy_key) {
                promises.push(
                    this.workflowLogicService.setInheritedOutputValues(
                        row.task, row.taskOutputSet, row.animal
                    ).then(() => {
                        row.needsInherited = false;
                    })
                );
            }
        });
        Promise.all(promises).then(() => {
            this.setLoading(false);
        });
    }

    /**
     * Bulk update the Completed By value.
     */
    bulkCompletedByChanged() {
        // Fetch (and reset) the Forced flag
        const force = this.areBulkChangesForced();

        for (const task of this.tasks) {
            if (!task.IsWorkflowLocked && (force || !task.C_CompletedBy_key)) {
                task.C_CompletedBy_key = this.bulk.completedByKey;
            }
        }
    }

    /**
     * Bulk update the Reviewed flag
     */
    bulkReviewedChanged() {
        // Fetch (and reset) the Forced flag
        const force = this.areBulkChangesForced();

        for (const task of this.tasks) {
            if (!task.IsWorkflowLocked) {
                // Update the Reviewed By and Time
                this.updateReviewed(task, true, force);
            }
        }
    }

    /**
     * Bulk update the Reviewed By value.
     */
    bulkReviewedByChanged() {
        // Fetch (and reset) the Forced flag
        const force = this.areBulkChangesForced();

        for (const task of this.tasks) {
            if (!task.IsWorkflowLocked && (force || !task.C_ReviewedBy_key)) {
                task.C_ReviewedBy_key = this.bulk.reviewedByKey;
            }
        }
    }

    /**
     * Bulk update the DateReviewed value.
     */
    bulkReviewedTimeChanged() {
        // Fetch (and reset) the Forced flag
        const force = this.areBulkChangesForced();

        const newTime = this.getTime(this.bulk.reviewedTime);

        for (const task of this.tasks) {
            if (!task.IsWorkflowLocked && (force || !task.DateReviewed)) {
                if (this.getTime(task.DateReviewed) !== newTime) {
                    // The DateReviewed needs to be changed
                    task.DateReviewed = this.bulk.reviewedTime;
                }
            }
        }

        return Promise.resolve();
    }

    bulkHarvestDateChanged() {
        // Fetch (and reset) the Forced flag
        const force = this.areBulkChangesForced();

        const newTime = this.getTime(this.bulk.harvestDate);

        for (const row of this.rows) {
            if (row.outputSample && !row.task.IsWorkflowLocked) {
                if (force || !row.outputSample.DateHarvest) {
                    if (this.getTime(row.outputSample.DateHarvest) !== newTime) {
                        // The DateHarvest needs to be changed
                        row.outputSample.DateHarvest = this.bulk.harvestDate;
                    }
                }
            }
        }
    }

    /**
     * Bulk update the status of the animals
     */
    bulkAnimalStatusChanged() {
        const animals = this.rows
            .filter((row) => row.animal && !row.task.IsWorkflowLocked)
            .map((row) => row.animal);

        if (this.bulk.animalStatusKey) {
            this.animalService.statusBulkChangePostProcess(animals, this.bulk.animalStatusKey);
        }
    }

    // Changes
    animalStatusChanged(animal: any) {
        this.animalService.statusChangePostProcess(animal);
    }

    /**
     * Bulk update the exit reasons of the animals
     */
    bulkExitReasonChanged() {
        for (const row of this.rows) {
            if (row.animal && !row.task.IsWorkflowLocked) {
                row.animal.C_ExitReason_key = this.bulk.exitReasonKey;
            }
        }
    }

    /**
     * Bulk update task workflow lock
     */
    bulkIsWorkflowLockedChanged() {
        for (const row of this.rows) {
            row.task.IsWorkflowLocked = this.bulk.lock;
        }
    }
    lockAllTaskInstances() {
        for (const row of this.rows) {
            if (this.isAdminForTask(row.task)) {
                row.task.IsWorkflowLocked = true;
            }
        }
    }
    unlockAllTaskInstances() {
        if (this.readonly) {
            return;
        }
        for (const row of this.rows) {
            if (this.isAdminForTask(row.task)) {
                row.task.IsWorkflowLocked = false;
            }
        }
    }

    protected isAdminForTask(task: any): boolean {
        if (this.isGLP) {
            if (task && task.TaskJob && task.TaskJob.length && task.TaskJob[0].Job) {
                const job = task.TaskJob[0].Job;
                if (!job.StudyDirector || job.StudyDirector === this.authService.getCurrentUserName()) {
                    return this.currentWorkgroupUser.StudyAdministrator;
                } else {
                    return false;
                }
            }
        } else {
            if (task && task.TaskJob && task.TaskJob.length && task.TaskJob[0].Job && task.TaskJob[0].Job.C_Study_key) {
                return this.studyAdministratorStudies.find((x: any) => x.C_Study_key === task.TaskJob[0].Job.C_Study_key) != null;
            } else {
                return this.studyAdministratorStudies.length > 0;
            }
        }
    }

    /**
     * Update the TaskOutputSet when an Output Value is change.
     */
    updateTaskOutputSet(row: DataRow, outputCol: IOColumn) {
        if (!row.taskOutputSet) {
            return;
        }
        const type = getSafeProp(outputCol.output, 'cv_DataType.DataType');
        if (type === DataType.NUMBER || type === DataType.CALCULATED || type === DataType.INHERITED_FIRST_OCCURRENCE || type === DataType.INHERITED_MOST_RECENT ||
            type === DataType.INHERITED_SECOND_MOST_RECENT || type === DataType.INHERITED_THIRD_MOST_RECENT) {
            if (this.cohortsStats.length > 0) {
                this.cohortsStats = [];
                this.calculateStatsCohortWise();
            }
        }
        if (type === DataType.NUMBER || type === DataType.CALCULATED) {
            // Deal with the calculated values, but only when Number outputs change
            this.workflowService.recalculateValues(row.task, row.taskOutputSet);
            this.workflowLogicService.updateTaskOutputSetFlags(row.taskOutputSet);
        }
        if (this.animalSyncEnabled) {
            this.syncOutput(row.taskOutputs[outputCol.key]);
        }
    }

    /**
     * Update all calculated outputs for a set of data rows 
     */
    updateCalculatedValues(rows: DataRow[]) {
        const calculatedRows = rows.filter(row => row.hasCalculated);

        calculatedRows.forEach(row => {
            this.workflowService.recalculateValues(row.task, row.taskOutputSet);
            this.workflowLogicService.updateTaskOutputSetFlags(row.taskOutputSet);
        });
    }

    /**
     * Deal with an update to the DateDue for an array of tasks/
     */
    updateDateDue(changedTasks: any[]): Promise<any> {
        return this.updateRelativeTasks(changedTasks);
    }

    /**
     * Update the Collected By and Time for a row that has been marked as
     * collected.
     *
     * @param row the data row
     * @param collected is the row now collected
     * @param force if true, the Collected By will be updated to the current 
     */
    updateCollected(row: DataRow, collected: boolean, force = false) {
        if (!row.taskOutputSet) {
            // This row does not have outputs
            return;
        }

        if (collected) {
            if (force || !row.taskOutputSet.C_Resource_key) {
                // Use the current resource as the default
                row.taskOutputSet.C_Resource_key = this.currentResourceKey;
            }

            if (force || !row.taskOutputSet.CollectionDateTime) {
                // Use the current time as the default
                row.taskOutputSet.CollectionDateTime = new Date();
            }
        } else {
            // Clear the values
            row.taskOutputSet.C_Resource_key = null;
            row.taskOutputSet.CollectionDateTime = null;
        }
    }

    /**
     * Update the Completed By and Time for a Task that has been marked as
     * Completed.
     *
     * @param task the data row
     * @param completed is the task now completed
     * @param force if true, the Collected By will be updated to the current 
     */
    updateCompleted(task: any, completed: boolean, force = false) {
        if (completed) {
            if (force || !task.C_CompletedBy_key) {
                // Use the currentF resource as the default
                task.C_CompletedBy_key = this.currentResourceKey;
            }

            if (force || !task.DateComplete) {
                // Use the current time as the default
                task.DateComplete = new Date();
            }

            if (force || !this.taskIsEndState(task)) {
                // Mark as completed
                task.cv_TaskStatus = this.taskDefaultEndStatus;
            }

            // Provide values for the Collected fields
            for (const row of this.taskRows[task.C_TaskInstance_key]) {
                const tos = row.taskOutputSet;
                if (!tos) {
                    continue;
                }

                if (!tos.CollectionDateTime) {
                    tos.CollectionDateTime = task.DateComplete;
                }
                if (!tos.C_Resource_key) {
                    tos.C_Resource_key = task.C_CompletedBy_key;
                }
            }
        } else {
            // Clear the values
            task.C_CompletedBy_key = null;
            task.DateComplete = null;
            task.cv_TaskStatus = this.taskDefaultStatus;
        }
    }

    /**
     * Update the Reviewed By and Time for a Task that has been marked as
     * Reviewed.
     *
     * @param task the data row
     * @param reviewed is the task now reviewed
     * @param force if true, the Reviewed By will be updated to the current 
     */
    updateReviewed(task: any, reviewed: boolean, force = false) {
        if (reviewed) {
            if (force || !task.C_ReviewedBy_key) {
                // Use the current resource as the default
                task.C_ReviewedBy_key = this.currentResourceKey;
            }

            if (force || !task.DateReviewed) {
                // Use the current time as the default
                task.DateReviewed = new Date();
            }
            if (force || !task.IsReviewed) {
                // Mark as reviewed
                task.IsReviewed = true;
            }
        } else {
            // Clear the values
            task.C_ReviewedBy_key = null;
            task.DateReviewed = null;
            task.IsReviewed = false;
        }
    }

    /**
     * Deal with an update to the DateComplete for an array of tasks.
     */
    updateCompletedTime(changedTasks: any[]): Promise<any> {
        return this.updateRelativeTasks(changedTasks);
    }

    /**
     * Handle the update of a Task status
     * 
     * @param task The updated task
     * @param force Should rows already marked as collected be forced to have
     *        their collected values updated?
     * @returns if any rows were marked as collected and need to be saved.
     */
    updateTaskStatus(task: any, force = false): boolean {
        // Do the standard stuff
        this.workflowLogicService.taskStatusChanged(task);

        return true;
    }

    /**
     * Is the Task in an end state?
     */
    taskIsEndState(task: any): boolean {
        if (!task || !task.cv_TaskStatus) {
            return false;
        }

        return task.cv_TaskStatus.IsEndState;
    }

    /**
     * Should bulk changes be forced even if values are present?
     */
    areBulkChangesForced(): boolean {
        const force = this.bulk.force;

        // Reset back to the default
        this.bulk.force = false;

        return force;
    }

    /**
     * Get the integer time for a possible null date
     */
    getTime(date: Date): number {
        return date ? date.getTime() : 0;
    }

    /**
     * Save the entities related to marking a row collected.
     * 
     * Note: Since this saves types of entities, it ends up saving pretty much
     * all the entities on the page.
     */
    saveEntities(needsSave = true, reloadData = true): Promise<any> {
        if (!needsSave) {
            return Promise.resolve();
        }

        // Note that we are busy
        this.setBusy(true);

        return this.dataManager.saveEntity('TaskOutputSet').then(() => {
            return Promise.all([
                this.dataManager.saveEntity('TaskOutput'),
                this.dataManager.saveEntity('TaskOutputSetMaterial'),
            ]);
        }).then(() => {
            return this.dataManager.saveEntity('TaskInstance');
        }).then(() => {
            if (reloadData) {
                // Refresh the tasks and rows
                return this.initData();
            }
        }).then(() => {
            this.setBusy(false);
        }).catch((err) => {
            this.setBusy(false);
            throw err;
        });
    }

    /**
     * Update the dues dates of tasks that are relative to the changed tasks
     * @param changedTasks The tasks that have been changed
     * @returns a Promise to  return the number of updated relative tasks
     */
    updateRelativeTasks(changedTasks: any[]): Promise<number> {
        if (changedTasks) {
            // Just in case, limit to unique tasks
            changedTasks = uniqueArrayOnProperty(changedTasks, 'C_TaskInstance_key');
        }

        this.setBusy(true);
        return this.workflowLogicService.updateRelativeTasks(
            changedTasks, this.COMPONENT_LOG_TAG
        ).then((count: number) => {
            this.setBusy(false);
            return count;
        }).catch((err) => {
            this.setBusy(false);
            throw err;
        });
    }

    /**
     * Fetch the inherited Output values for the current row
     */
    async fetchInheritedValues(row: DataRow) {
        await this.workflowLogicService.setInheritedOutputValues(
            row.task, row.taskOutputSet, row.animal
        );
        row.needsInherited = false;
    }

    // Cancelling
    onCancel() {
        if (this.tasks) {
            this.tasks.forEach((task) => {
                this.workflowService.cancelTaskInstance(task);
            });
            this.loggingService.logFacetUndoSuccess('workflow-bulk-data-entry');
        }
    }

    /**
     * Open the Import modal
     */
    importOpenModal(modal: any) {
        this.modalErrorCheck();

        // Finally, open the modal 
        this.modalService.open(modal, { size: 'lg' });
    }

    modalErrorCheck() {
        this.importReset();
        if (this.importTemplateDownloaded && this.sortChanged) {
            this.import.errors.push('Sort order has changed after import template was downloaded. Please download the template again.');
        }

        if (!this.tasks || (this.tasks.length === 0)) {
            // Very unlikely, but just in case...
            this.import.errors.push('There are no tasks to import.');
        } else if (!this.outputColumns || (this.outputColumns.length === 0)) {
            this.import.errors.push('These tasks have no outputs.');
        } else if (this.tasks.length === 1) {
            // A single task
            const task = this.tasks[0];
            if (!task.IsGroup) {
                this.import.taskKeys = [task.C_TaskInstance_key];
                this.import.outputs = task.WorkflowTask.Output;
            } else {
                this.import.errors.push('Cannot import directly into this grouped task.');
            }
        } else {
            // Multiple tasks
            const taskInstanceKeys = this.tasks.map(task => task.C_TaskInstance_key);
            // Make sure all tasks have the same workflow task key
            const workflowTaskKey = this.tasks[0].C_WorkflowTask_key;
            const sameTaskDef = this.tasks.every((task) => {
                return task.C_WorkflowTask_key === workflowTaskKey;
            });

            if (!sameTaskDef) {
                // Task instances are not all the same workflow task definition
                this.import.errors.push('Cannot import to more than one type of task.');
            } else {
                this.import.taskKeys = taskInstanceKeys;
                this.import.outputs = this.tasks[0].WorkflowTask.Output;
            }
        }

        if (this.saveChangesService.hasChanges) {
            this.import.errors.push(
                'Cannot import while there are unsaved changes.'
            );
        }

        this.import.hasErrors = (this.import.errors.length > 0);

        if (!this.import.hasErrors) {
            this.import.outputs = this.getOnlyValidImportOutputs(this.import.outputs);
        }
    }

    /**
     * Save the name of the uploaded file
     */
    importFileUpdate(filename: string) {
        this.import.filename = filename;
    }

    /**
     * Format the output template for download
     */
    importDownloadTemplate() {
        // TODO (kevin.stone): This function does not belong on ExportWorkflowDetailService
        const templateRows: TemplateRow[] = [];

        this.tasks.forEach((task) => {
            const taskRow = {
                key: null,
                animal: null, 
                sample: null
            } as TemplateRow;
            if (notEmpty(task.TaskMaterial)) {
                taskRow.key = task.C_TaskInstance_key;
            }
            if (this.import.includeAnimalNames) {
                if (notEmpty(task.TaskMaterial)) {
                    const animal = uniqueArrayFromPropertyPath(task.TaskMaterial, 'Material.Animal');
                    taskRow.animal = animal[0];
                }
            }
            if (this.import.includeSampleNames) {
                taskRow.sample = this.exportWorkflowDetailService.getPrimarySampleFromTask(task);
            }
            templateRows.push(taskRow);
        });

        this.exportWorkflowDetailService.exportOutputsToCsv(            
            this.import.outputs,
            templateRows,
            this.isGLP,
            this.import.includeAnimalNames,
            this.import.includeSampleNames,
            true
        );        

        this.sortChanged = false;
        this.importTemplateDownloaded = true;
        this.modalErrorCheck();
    }

    async showOverwriteExistingValues(activeTasksWillBeChanged: boolean): Promise<boolean> {
        if (!activeTasksWillBeChanged) {
            return true;
        }

        const options = {
            title: 'Overwrite existing values?',
            message:
                'Some of the rows already have output values. ' +
                'Are you sure you want to update the values?',
        };

        // Ask the user for confirmation
        try {
            await this.confirmService.confirm(options);
            return true;
        }
        catch (noAnswer) {
            // When user click 'No', promise is rejected. Handle this.
            return false;
        }
    }

    /**
     * Ask the server to process the uploaded file
     */
    async importProcessFile(): Promise<void> {
        this.import.inProgress = true;
        this.loading = true;
        let messageId = this.overlayService.show();
        try {
            // Stage file for import
            await this.webApiService.postApi(
                'api/file/import-mapping/move',
                JSON.stringify(this.import.filename),
                'application/json',
                false
            );

            // Get file definition
            const definitionName = 'Bulk Data Task Output';
            const importDef = await this.importFileDefinitionService.getImportFileDefinitionByName(definitionName);
            const requestBody = this.getRequestBody(importDef);

            // Pre-import step: emulate BDE import (without saving)
            const resp = await this.webApiService.postApi(
                'api/file/process-bde/',
                requestBody,
                'application/json',
                false
            );

            const {
                ActiveTasksWillBeChanged,
                CompletedTasksWillBeChanged,
                ChangedCompletedTaskInstanceKeys
            } = resp.data;

            this.taskInstanceKeys = ChangedCompletedTaskInstanceKeys ?? [];

            if (ActiveTasksWillBeChanged) {
                const options = {
                    title: 'Overwrite existing values?',
                    message:
                        'Some of the rows already have output values. ' +
                        'Are you sure you want to update the values?',
                };

                // Ask the user for confirmation
                try {
                    this.loading = false;
                    this.overlayService.hide(messageId);
                    await this.confirmService.confirm(options);

                    this.loading = true;
                    messageId = this.overlayService.show();
                } catch {
                    // When user click 'No', promise is rejected. Handle this.
                    return;
                }
            }

            const showMessage = !!this.showPostEditModal && CompletedTasksWillBeChanged;
            if (showMessage) {
                const confirmUpdateCompletedTasks = await this.showDataChangeModal(false);
                if (confirmUpdateCompletedTasks === 'cancel') {
                    this.cancelImport();
                    return;
                }
            }
        
            await this.webApiService.postApi(
                'api/file/import/',
                requestBody,
                'application/json',
                false,
            );
            // Let folks know an import was done
            this.workflowService.workflowImportCompleted();
            this.createNote();

            // Refresh the table and recalculate the calculated values
            await this.initData();

            // If the calculated values have been updated after the import, save the changes.
            if (this.hasChanges()) {
                await this.saveEntities(true, false);
            }
            this.loggingService.logSuccess(
                "Outputs imported", null, this.COMPONENT_LOG_TAG, true
            );
        } catch (exception) {
            let error: any = exception;
            try {
                // Try parse backend exception
                error = JSON.parse(exception);
                const stackTraceIndex = error.FullErrorMessage.indexOf('Stack Trace');
                const stackTrace = stackTraceIndex >= 0
                    ? error.FullErrorMessage.substring(stackTraceIndex + 'Stack Trace'.length)
                    : error.FullErrorMessage;

                error = {
                    message: error.Message,
                    stack: stackTrace
                };
            } catch (e) { /* do nothing on json parse error */ }

            this.loggingService.logError('Import failed. Please see the "Import" facet for error details.',
                error, this.COMPONENT_LOG_TAG, true);
        } finally {
            this.loading = false;
            this.importReset();
            this.modalErrorCheck();
            this.overlayService.hide(messageId);
        }
    }

    cancelImport() {
        // Clear the import selections
        this.importReset();
        this.loading = false;
    }

    getRequestBody(importDef: any): any {
        const utcOffset = new Date().getTimezoneOffset() / 60;
        return {
            FileName: this.import.filename,
            DefinitionKey: importDef.C_ImportFileDefinition_key,
            GlobalVariables: {
                user: this.authService.getCurrentUserName(),
                utcOffset,
                taskInstanceKeys: this.import.taskKeys,
                attachToJob: this.import.attachToJob,
                attachToTasks: this.import.attachToTasks
            }
        };
    }

    /**
     * Clear the import state
     */
    importReset() {
        this.import = { ...this.DEFAULT_IMPORT };
    }

    /**
     * Set or unset the Busy flag.
     * 
     * Note: This works like a semaphore in that you can call it multiple times
     * to increment or decrement the value.
     */
    setBusy(isBusy = true) {
        // Update the semaphore
        this.busy += (isBusy) ? 1 : -1;

        // Use the standard Loading flag
        this.setLoading(this.busy > 0);
        this.loading = this.busy > 0;
    }


    /**
     * Export the current data to CSV
     */
    exportData(exportType: ExportType) {
        // Copy and tweak the visible columns to restore the JobID and TaskAlias columns
        const visible = {
            ...this.visible
        };
        visible.JobID = Boolean(visible.JobID || this.common.isJobIDColVis);
        visible.TaskAlias = Boolean(this.visible.TaskAlias || this.common.TaskAlias);

        this.workflowBulkDataExportService.export(
            this.rows, visible, this.inputColumns, this.outputColumns, exportType, this.isGLP
        );
    }

    /**
     * Print the current data
     */
    printData() {
        this.printPreviewService.printFromId(this.printPreviewId);
    }

    changeTaskPage(newPage: number) {
        this.taskPage = newPage;
        this.totalPages = Math.ceil(this.rows.length / 30);
        this.calculateStatsCohortWise();
        setTimeout(() => {
            // this.setupFreezeTable();
        }, 300);
    }
    // <select> formatters
    volumeUnitKeyFormatter = (value: any) => {
        return value.C_Unit_key;
    }
    volumeUnitFormatter = (value: any) => {
        return value.Unit;
    }
    animalStatusKeyFormatter = (value: any) => {
        return value.C_AnimalStatus_key;
    }
    animalStatusFormatter = (value: any) => {
        return value.AnimalStatus;
    }
    exitReasonKeyFormatter = (value: any) => {
        return value.C_ExitReason_key;
    }
    exitReasonFormatter = (value: any) => {
        return value.ExitReason;
    }
    resourceKeyFormatter = (value: any) => {
        return value.C_Resource_key;
    }
    resourceNameFormatter = (value: any) => {
        return value.ResourceName;
    }
    timeUnitKeyFormatter = (value: any) => {
        return value.C_TimeUnit_key;
    }
    timeUnitFormatter = (value: any) => {
        return value.TimeUnit;
    }
    taskStatusKeyFormatter = (value: any) => {
        return value.C_TaskStatus_key;
    }
    taskStatusFormatter = (value: any) => {
        return value.TaskStatus;
    }
    sampleTypeKeyFormatter = (value: any) => {
        return value.C_SampleType_key;
    }
    sampleTypeFormatter = (value: any) => {
        return value.SampleType;
    }
    sampleStatusKeyFormatter = (value: any) => {
        return value.C_SampleStatus_key;
    }
    sampleStatusFormatter = (value: any) => {
        return value.SampleStatus;
    }
    preservationMethodKeyFormatter = (value: any) => {
        return value.C_PreservationMethod_key;
    }
    preservationMethodFormatter = (value: any) => {
        return value.PreservationMethod;
    }
    containerTypeKeyFormatter = (value: any) => {
        return value.C_ContainerType_key;
    }
    containerTypeFormatter = (value: any) => {
        return value.ContainerType;
    }
    sampleSubtypeKeyFormatter = (value: any) => {
        return value.C_SampleSubtype_key;
    }
    sampleSubtypeFormatter = (value: any) => {
        return value.SampleSubtype;
    }
    sampleProcessingMethodKeyFormatter = (value: any) => {
        return value.C_SampleProcessingMethod_key;
    }
    sampleProcessingMethodFormatter = (value: any) => {
        return value.SampleProcessingMethod;
    }
    sampleAnalysisMethodKeyFormatter = (value: any) => {
        return value.C_SampleAnalysisMethod_key;
    }
    sampleAnalysisMethodFormatter = (value: any) => {
        return value.SampleAnalysisMethod;
    }

    /**
     * Sets isDotmatics flag
     */
    private setIsDotmatics() {
        this.isDotmatics = this.dotmaticsService.setIsDotmatics();
    }

    syncDotmaticsSample(row: any): Promise<any> {
        const isDefaultEndState: boolean = getSafeProp(row.task, 'cv_TaskStatus.IsDefaultEndState');
        if (row.outputSample && isDefaultEndState) {
            if (!row.outputSample.Material.ExternalIdentifier) {
                // Post sample
                if (!row.task.dtxSynchronized) {
                    return this.dotmaticsService.postDotmaticsSample(row.outputSample.C_Material_key).then((response) => {
                        row.task.dtxSynchronized = true;
                    });
                } else {
                    return Promise.resolve();
                }
            } else {
                // Update animal
                return this.dotmaticsService.updateDotmaticsSample(row.outputSample.C_Material_key);
            }
        }
    }

    bulkSyncDotmaticsSamples(taskKeys: any): Promise<any> {
        taskKeys.forEach((taskKey: any) => {
            // get the corresponding row
            const currentRow = this.rows.find((row) => row.task.C_TaskInstance_key === Number(taskKey));
            if (currentRow) {
                this.syncDotmaticsSample(currentRow);
            }
        });
        return Promise.resolve();
    }

    private _setDefaultTableSort() {
        if (!this.tableSort) {
            this.tableSort = new TableSort();
        }
        this.tableSort.natural = true;
    }

    sortColumn(column: any, event: any, refresh = false, nestedBeforeRefresh = false) {
        this.setBusy(true);
        const promise = Promise.resolve(true);
        promise.then(() => {
            if (event && event.shiftKey || nestedBeforeRefresh) {
                this.tableSort.nested = true;
            } else {
                this.tableSort.nested = false;
            }
            this.sortChanged = true;
            if (!refresh) {
                this.tableSort.toggleSort(column);
            }
            if (this.tableSort.nested) {
                const sortDefs = this.tableSort.properties.map((path: any) => {
                    return {
                        sortAccessor: (item: any) => {
                            return getSafeProp(item, path);
                        },
                        descending: this.tableSort.nestedReverse[path]
                    };
                });
                sortObjectArrayByAccessors(this.rows, sortDefs, this.tableSort.natural);
            } else {
                if (this.tableSort.propertyPath === '') {
                    const materialSequence = {};

                    if (this.rows[0].task.TaskJob.length > 0) {
                        this.rows[0].task.TaskJob[0].Job.JobMaterial.forEach((jobMat: JobMaterial) => {
                            materialSequence[jobMat.C_Material_key] = jobMat.Sequence;
                        });
                    }
                    const newRows = [...this.rows];
                    newRows.sort((row1: DataRow, row2: DataRow): number => {
                        const row1TaskOutputSet = row1.taskOutputSet;
                        const row2TaskOutputSet = row2.taskOutputSet;

                        const row1TaskOutputSetDateDue = row1TaskOutputSet?.DateDue;
                        const row2TaskOutputSetDateDue = row2TaskOutputSet?.DateDue;

                        if (row1TaskOutputSetDateDue !== row2TaskOutputSetDateDue) {
                            const dateValOne: number =  row1TaskOutputSetDateDue ? convertValueToLuxon(row1TaskOutputSetDateDue).toSeconds() : 0;
                            const dateValTwo: number = row2TaskOutputSetDateDue ? convertValueToLuxon(row2TaskOutputSetDateDue).toSeconds() : 0;
    
                            const sequenceValOne = row1TaskOutputSet?.TaskOutputSetMaterial?.length > 0 ?
                                materialSequence[row1TaskOutputSet.TaskOutputSetMaterial[0].C_Material_key] :
                                -Infinity;
                            const sequenceValTwo = row2TaskOutputSet?.TaskOutputSetMaterial?.length > 0 ?
                                materialSequence[row2TaskOutputSet.TaskOutputSetMaterial[0].C_Material_key] :
                                -Infinity;
                            const date = dateValOne - dateValTwo;
                            if (date !== 0) {
                                return date;
                            }
                            return sequenceValOne - sequenceValTwo;
                        }

                        const row1TaskDateDue = row1.task?.DateDue;
                        const row2TaskDateDue = row2.task?.DateDue;
                        if (row1TaskDateDue !== row2TaskDateDue) {
                            let dateValOne = 0;
                            let dateValTwo = 0;
                            if (row1TaskDateDue) {
                                dateValOne = convertValueToLuxon(row1TaskDateDue).toSeconds();
                            }
                            if (row2TaskDateDue) {
                                dateValTwo = convertValueToLuxon(row2TaskDateDue).toSeconds();
                            }

                            // if dates difference is zero then return indexes difference
                            return dateValOne - dateValTwo || row1.index - row2.index;
                        }

                        return row1.index - row2.index;
                    });
                    this.rows = newRows;
                } else {
                    const newRows = [...this.rows];
                    sortObjectArrayByAccessor(newRows, (item) => {
                        return getSafeProp(item, this.tableSort.propertyPath);
                    }, this.tableSort.reverse, this.tableSort.natural);
                    this.rows = newRows;
                }
            }
        }).then(() => {
            setTimeout(() => {
               // this.setupFreezeTable();
            }, 300);
            this.setBusy(false);
        });

    }

    sortColumns() {
        if (this.tableSort && this.tableSort.propertyPath !== '') {
            this.sortColumn(this.tableSort.propertyPath, null, true, this.tableSort.nested);
        }
    }

    addClinicalRecord(row: any) {
        this.workspaceService.openClinicalFacet(row.animal.C_Material_key);
    }

    private getStudyAdminStudies(): Promise<any> {
        return this.privilegeService.getCurrentUserStudyAdministratorStudies().then((studyAdminStudies: any[]) => {
            this.studyAdministratorStudies = studyAdminStudies;
        });
    }

    private initCurrentWorkgroupUser(): Promise<any> {
        return this.userService.getThisWorkgroupUser().then((workgroupUser: any) => {
            this.currentWorkgroupUser = workgroupUser;
        });
    }
    average(values: number[]): number | null {
        if (values && values.length > 0) {
            return values.reduce((a, b) => a + b) / values.length;
        } else {
            return null;
        }
    }

    standardDeviation(values: number[]): number | null {
        if (values && values.length > 1) {
            const n = values.length;
            const mean = values.reduce((a, b) => a + b) / n;
            return Math.sqrt(values.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
        } else  {
            return null;
        }
    }

    median(values: number[]): number | null {
        if (values && values.length > 0) {
            values.sort((a, b) => a - b);

            // If length is odd, median = values[length/2]
            if (values.length % 2 !== 0) {
                return values[((values.length + 1) / 2) - 1];
            } else {
                // If length is even, median = (values[length/2]+values[1+length/2])/2
                return (values[((values.length) / 2) - 1] + values[((values.length) / 2)]) / 2;
            }
        } else {
            return null;
        }
    }

    setDataChange() {
        if (!this.showPostEditModal) { // when falg is false then make the different functions work normally without listening to apply button click in change data modal
            this.callSave = new ReplaySubject();
            this.callSave$ = this.callSave.asObservable();
            this.callSave.next(null);
            return;
        }
        this.checkChangeSub = this.dataContext.checkChanges$.subscribe((hasChanges: boolean) => {
            if (!hasChanges) {
                this.appliedChangesMap.clear(); // clear map of changed entities so that data change modal can be shown again
            }
        });
        const isMovingToEndState = (args: any) => {
            return args.propertyName === 'cv_TaskStatus' && args.oldValue.IsEndState === false;
        };
        this.anyChangeSub = this.entityChangeService.onAnyChange(({ args, entity, entityAction }: any) => {
            const entityKey = entity.entityAspect.getKey().values[0];
            if (
                this.isDataChangeModalOpen
                || this.loading
                || entityAction.name !== 'PropertyChange'
                || !args
                || this.appliedChangesMap.has(entityKey + args.propertyName) // don't show modal if already applied
            ) {
                // if we had clicked on apply button in modal then we don't show modal again but here we are calling save if task status is changing to end status
                if (args && isMovingToEndState(args)) {
                    setTimeout(() => { // using timeout because modelChange and attached functions getting called later
                        this.callSave.next();
                    }, 500);
                }
                return;
            }
            const entityFound = ['TaskOutputSet', 'TaskInstance'].includes(entity.entityType.shortName) || [...this.materialTaskKeys, 'OutputValue', 'ScanValue'].includes(args.propertyName);
            const ignoredEntities = ['Note', 'DateModified'];
            const ignoredProperties = ['DateModified', 'C_TaskStatus_key'];
            const isReadonlyOutput = args.propertyName === 'OutputValue' &&
                [DataType.INHERITED_MOST_RECENT, DataType.INHERITED_FIRST_OCCURRENCE,
                DataType.INHERITED_SECOND_MOST_RECENT, DataType.INHERITED_THIRD_MOST_RECENT, DataType.CALCULATED].includes(entity.Output.cv_DataType.DataType);
            const isNullOrEmpty = args.oldValue === null && args.newValue === "";
            if (!entityFound || ignoredEntities.includes(entity.entityType.shortName) || ignoredProperties.includes(args.propertyName) || isReadonlyOutput || isNullOrEmpty) {
                return;
            }
            let taskInstance = entity;
            if (args.propertyName === 'OutputValue') {
                taskInstance = getSafeProp(entity, 'TaskOutputSet.TaskInstance');
            } else if (args.propertyName === 'ScanValue' || entity.entityType.shortName === 'TaskOutputSet') {
                taskInstance = getSafeProp(entity, 'TaskInstance');
            } else if (args.propertyName === 'C_ContainerType_key') {
                taskInstance = getSafeProp(entity, 'TaskMaterial.0.TaskInstance');
            } else if ([...this.materialTaskKeys].includes(args.propertyName)) {
                taskInstance = getSafeProp(entity, 'Material.TaskMaterial.0.TaskInstance');
            }

            if (isMovingToEndState(args)) { // don't show modal if moving from in progress to end state task status
                setTimeout(() => {
                    this.callSave.next();
                }, 500);
                return;
            }
            const { C_TaskInstance_key } = taskInstance;
            const isEndState = taskInstance.cv_TaskStatus ? taskInstance.cv_TaskStatus.IsEndState : taskInstance.cv_TaskStatus;
            const fromEndState = args.propertyName === 'cv_TaskStatus' && args.oldValue.IsEndState === true;
            if (isEndState || fromEndState) { // only show modal if task is already in end state or moving to in progress task status 
                this.taskEntitiesMap.set(entityKey + args.propertyName, { entity, C_TaskInstance_key });
                this.taskInstanceKeys = []; // this is to prevent creating multiple notes on same row/task instance 
                this.taskEntitiesMap.forEach((value: any) => {
                    const pkValue = value.C_TaskInstance_key;
                    if (this.taskInstanceKeys.indexOf(pkValue) < 0) {
                        this.taskInstanceKeys.push(pkValue);
                    }
                });

                this.showDataChangeModal().then((res) => {
                    if (res === 'cancel') {
                        this.taskEntitiesMap.forEach(({ entity: entityObj }) => {
                            entityObj.entityAspect.rejectChanges();
                        });
                    } else {
                        this.taskEntitiesMap.forEach((_, key) => {
                            this.appliedChangesMap.set(key, true);
                        });
                        this.callSave.next();
                    }
                    this.taskEntitiesMap.clear();
                });
            }
        });
    }

    taskInstanceKeys: any = [];
    reason: any;
    showDataChangeModal = debouncePromise(this.openDataChangedModal, 500);
    async openDataChangedModal(createNote = true, taskKeys?: number[]) {
        if (!this.showPostEditModal) {
            return;
        }
        this.isDataChangeModalOpen = true;
        const res = await this.modalService.open(DataChangedModalComponent, { backdrop: 'static', size: 'md', windowClass: 'reason-modal' }).result;
        if (res !== 'cancel') {
          this.reason = res.reason;
          if (createNote) {
            this.createNote(taskKeys);
          }
        } else {
          this.modalService.dismissAll();
        }
        this.isDataChangeModalOpen = false;
        return res;
    }

    createNote(taskKeys?: number[]) {
        if (!this.showPostEditModal) {
            return;
        }
        const keys = taskKeys ?? this.taskInstanceKeys;
        keys.forEach((key: any) => {
            const pkName = 'C_TaskInstance_key';
            const pkValue = key;
            this.noteService.createNote(
                pkName,
                pkValue,
                this.reason
            );
            this.noteService.updateNoteCount(pkName, pkValue);
        });
        this.taskInstanceKeys = [];
    }

    openAnimalCommentsModal(row: any) {
        this.modalService.open(AnimalCommentsModalComponent, { backdrop: 'static', size: 'md', windowClass: 'reason-modal' }).result
            .then((res) => {
                if (res !== 'cancel') {
                    const { comments, commentStatusKey } = res;

                    this.dataManager.createEntity('AnimalComment', {
                        C_Material_key: row.animal.C_Material_key,
                        Comment: comments,
                        C_AnimalCommentStatus_key: +commentStatusKey
                    });
                } else {
                    this.modalService.dismissAll();
                }
                return res;
            });
    }


    onSearchEnter(): void {
        const hasMicrochipID = this.rows.find((row: DataRow) => row.animal && row.animal.Material.MicrochipIdentifier === this.searchMicrochipID) !== undefined;
        if (hasMicrochipID) {
            this._searchMicrochipID();
            this.autoFocusFirstControlElement();
        } else {
            const message = `This Microchip ID is not present in this list. Please ensure that you have the correct animal`;
            this.loggingService.logWarning(message,
                null, this.COMPONENT_LOG_TAG, true);
        }
    }

    /**
     * Forces forcus back to the #scanInput input.
     */
    refocusSearchInput(): void {
        this.searchMicrochipID = "";
        const targetInput: HTMLInputElement = this.elementRef.nativeElement.querySelector('#searchInput');
        setTimeout(() => {
            targetInput.focus();
        }, 0)
    }

    /**
     * Sorts the current page by the following criteria
     * - Similarity to the microchipID that is being searched, matches will appear first.
     * - If the above search produces a tie, then it will sort on whether or not the task is completed, incomplete first.
     * - If the above produces a tie, then it will sort based on the due date of the tasks, most recent first.
     * - If the above produces a tie, then it will sort based alphabetical order based on the task name. 
     */
    private _searchMicrochipID(): void {
        const sortFn = (a: DataRow, b: DataRow) => {
            const aAnimal = a.animal;
            const bAnimal = b.animal;

            const aMicrochipID: string = aAnimal ? aAnimal.Material.MicrochipIdentifier : null;
            const bMicrochipID: string = bAnimal ? bAnimal.Material.MicrochipIdentifier : null;

            const searchIndexA: number = aMicrochipID === this.searchMicrochipID ? 1 : -1;
            const searchIndexB: number = bMicrochipID === this.searchMicrochipID ? 1 : -1;

            if (searchIndexA < searchIndexB) {
                return 1;
            } else if (searchIndexA > searchIndexB) {
                return -1;
            } else {
                const aTask: any = a.task;
                const bTask: any = b.task;

                const aTaskStatus: any = aTask.cv_TaskStatus;
                const bTaskStatus: any = bTask.cv_TaskStatus;

                const aCompleted: boolean = aTaskStatus ? aTaskStatus.IsEndState : false;
                const bCompleted: boolean = bTaskStatus ? bTaskStatus.IsEndState : false;

                const aCompletedNumber: number = aCompleted ? 1 : 0;
                const bCompletedNumber: number = bCompleted ? 1 : 0;

                const completedComparison: number = aCompletedNumber - bCompletedNumber;
                if (completedComparison === 0) {
                    const aTaskDateDue: Date = new Date(aTask.DateDue);
                    const bTaskDateDue: Date = new Date(bTask.DateDue);

                    const aDateDueTime: number = aTaskDateDue ? aTaskDateDue.getTime() : 0;
                    const bDateDueTime: number = bTaskDateDue ? bTaskDateDue.getTime() : 0;

                    if (aDateDueTime > bDateDueTime) {
                        return 1;
                    } else if (aDateDueTime < bDateDueTime) {
                        return -1;
                    } else {
                        const aTaskName: string = aTask.WorkflowTask.TaskName;
                        const bTaskName: string = bTask.WorkflowTask.TaskName;
                        return aTaskName.localeCompare(bTaskName);
                    }
                }
                return completedComparison;
            }
        };
        this.rows = [...this.rows].sort(sortFn);
    }

    /**
     * Get the auto focus on the first <input> <select> or <textarea> tag in the "Output" section of the table
     */
    private autoFocusFirstControlElement(): void {
        setTimeout(() => {
            const tableOutputsSelector = 'workflow-facet tr.workflow-bulk-data-entry-row td.output';
            const outputColumns: HTMLElement[] = this.elementRef.nativeElement.querySelectorAll(tableOutputsSelector);
            const inputSelector = 'input:not([readonly]):not([disabled])';
            const textAreaSelector = 'textarea:not([readonly]):not([disabled])';
            const selectSelector = 'select:not([disabled])';

            for (const column of outputColumns) {
                const input: HTMLInputElement | null = column.querySelector(inputSelector);
                const textArea: HTMLTextAreaElement | null = column.querySelector(textAreaSelector);
                const select: HTMLSelectElement | null = column.querySelector(selectSelector);

                type FocusableElementType = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | null;

                const focusableElement: FocusableElementType = input || textArea || select;
                if (focusableElement) {
                    focusableElement.focus();
                    return;
                }
            }
            const topIndex = this.rows[0].index;
            this.setCurrentRow(topIndex);
        }, 0);
    }

    private syncTaskIfNeeded(index: number) {
        if (index < 0 || index === undefined) {
            return;
        }
        const rowIndex = this.rows.findIndex((row: DataRow) => row.index === index);
        const task = this.rows[rowIndex];
        this.syncTaskWithFacetsIfNeeded(task);
    }

    private syncTaskWithFacetsIfNeeded(task: DataRow) {
        const shouldSyncAnimalsFacet = this.shouldSyncAnimalsFacet(task);
        const shouldSyncClinicalFacet = this.shouldSyncClincalFacet(task);
        if (!shouldSyncAnimalsFacet && !shouldSyncClinicalFacet) {
            return;
        }
    
        if (shouldSyncAnimalsFacet) {
            this.workflowService.syncWithAnimalsFacet(task);
        }
        if (shouldSyncClinicalFacet) {
            this.workflowService.syncWithClinicalFacet(task);
        }
    }

    private shouldSyncAnimalsFacet(task: any): boolean {
        const taskType = getSafeProp(task, 'taskOutputSet.TaskInstance.WorkflowTask.cv_TaskType.TaskType');
        return this.animalSyncEnabled && (taskType === TaskType.Animal || taskType === TaskType.Job);
    }

    private shouldSyncClincalFacet(task: any): boolean {
        return this.clinicalSyncEnabled && task.animal;
    }

    private getOutputsSelectorConfig(): any {
        let label = '';
        let classes = '';

        if (this.columnOutput.model.length === 0) {
            label = 'All Outputs Hidden';
            classes = 'outputs-has-hidden-options';
        } else if (this.columnOutput.labels.length === this.columnOutput.model.length) {
            label = 'All Outputs Shown';
        } else {
            const outputsHidden = this.columnOutput.labels.length - this.columnOutput.model.length;
            label = `${outputsHidden} Output${outputsHidden > 1 ? 's' : ''} Hidden`;
            classes = 'outputs-has-hidden-options';
        }

        return { label, classes };
    }

    private syncOutput(output: any) {
        this.workflowService.syncOutput(output);
    }

    refreshTaskOutputs() {
        this.rows.forEach((row) => {
            objectValues(row.taskOutputs).forEach((taskOutput: TaskOutput) => {
                if (taskOutput.Output) {
                    this.syncOutput(taskOutput);
                }
            });
        });
    }

    exitClicked() {
        this.workflowService.syncWithClinicalFacet(null);

        this.exit.emit();
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }

    /**
     * Opens a modal that requires the user to confirm further selection. 
     * @param index
     */
    public showEndStateModal(index: number) {
        if (this.isGLP) {
            const foundIndex = this.rows.findIndex((row: DataRow) => row.index === index);
            const animal = this.rows[foundIndex].animal;
            if (animal) {
                const animalStatus = animal.cv_AnimalStatus;
                const materialID = animal.C_Material_key;
                if (materialID !== this.selectedMaterialID) {
                    this.selectedMaterialID = materialID;
                    if (animalStatus && animalStatus.IsExitStatus) {
                        const options: ConfirmOptions = {
                            title: "Animal Status Is End State",
                            message: `The selected Animal's status is set to an end-state status of: ${animalStatus.AnimalStatus}`,
                            yesButtonText: 'Confirm',
                            onlyYes: true
                        };
                        this.confirmService.confirm(options);
                    }
                }
            }
        }
    }

    private checkIfAnimalFacetIsOpen(taskInstanceKeys: any[]): Promise<boolean> {
        return this.workflowService.getTaskInstances(taskInstanceKeys).then((data) => {
            const hasAnimalOrJobTasks = data.some((ti: any) =>
                getSafeProp(ti, 'WorkflowTask.cv_TaskType.TaskType') === TaskType.Job ||
                getSafeProp(ti, 'WorkflowTask.cv_TaskType.TaskType') === TaskType.Animal);
            if (!hasAnimalOrJobTasks) {
                return;
            }
            const animalsFacet = this.workspaceService.currentWorkspace
                .WorkspaceDetail.find((d: any) => d.FacetName === 'Animals');
            if (!animalsFacet) {
                this.toastrService.showToast(
                    'Please open the Animals Facet to use the Animals Facet Sync.', LogLevel.Warning);
            }
            return animalsFacet !== undefined;
        });
    }

    private async checkIfClinicalFacetIsOpen(taskInstanceKeys: any[]): Promise<boolean> {
        const taskInstances = await this.workflowService.getTaskInstances(taskInstanceKeys) as Entity<TaskInstance>[];

        const hasRelevantTasks = taskInstances.some(ti => {
            const taskType = ti?.WorkflowTask?.cv_TaskType?.TaskType;
            return taskType === TaskType.Job || taskType === TaskType.Animal || taskType === TaskType.HealthRecord;
        });
        if (!hasRelevantTasks) {
            return false;
        }

        const clinicalFacet = this.workspaceService.currentWorkspace.WorkspaceDetail.find(d => d.FacetName === 'Clinical');
        if (!clinicalFacet) {
            this.toastrService.showToast(
                'Please open the Clinical Facet to use the Clinical Facet Sync.', LogLevel.Warning);
        }
        return clinicalFacet !== undefined;
    }

    toOptions(labels: ColumnSelectLabel[]): IMultiSelectOption[] {
        return labels.map((label) => ({
            id: label.key,
            name: label.label,
        }));
    }

    /**
     * Bulk update the TaskStatus
     * 
     * Will also mark related rows as completed.
     */
    async bulkTaskStatusChanged() {
        try {
            // Fetch (and reset) the Forced flag
            const force = this.areBulkChangesForced();

            const changedTasks = this.tasks
                .filter(task => {
                    if (task.C_TaskStatus_key === this.bulk.taskStatusKey || task.IsWorkflowLocked) {
                        return false;
                    }
                    return force || !this.taskIsEndState(task)
                });

            const taskInstanceKeys = changedTasks.map(task => task.C_TaskInstance_key);
            const taskEndStatusKeys = changedTasks
                .filter(task => getSafeProp(task, 'cv_TaskStatus.IsEndState'))
                .map(task => task.C_TaskInstance_key);

            if (taskInstanceKeys.length === 0) {
                return;
            }

            const res = Boolean(taskEndStatusKeys.length)
                ? await this.showDataChangeModal(true, taskEndStatusKeys)
                : Promise.resolve();
            if (res === 'cancel') {
                return;
            }
            this.setBusy(true);
            await this.saveEntities(true, false);
            const keys = await this.taskInstanceService.taskBulkStatusChange({
              TaskInstanceKeys: taskInstanceKeys,
              ModifiedByKey: this.currentResourceKey,
              TaskStatusKey: this.bulk.taskStatusKey,
              source: this.COMPONENT_LOG_TAG,
            });

            const taskKeysToRefresh = keys.length > 0 ? keys.filter(k => taskInstanceKeys.includes(k)) : taskInstanceKeys;
            await this.refreshTasks(taskKeysToRefresh);
        } finally {
            this.setBusy(false);
        }
    }

    /**
     * Handle a change in the Task status
     * 
     * Setting the status to an end state will mark the related rows as
     * collected.
     * 
     * @param task
     */
    async taskStatusChanged(task: Entity<TaskInstance>, newKey: number) {
        const resetEntity = updateEntity(task, 'C_TaskStatus_key', newKey);
        try {
            const taskInstanceKeys = [task.C_TaskInstance_key];
            const isEndState = this.taskIsEndState(task);
            if (isEndState) {
                await this.createNotes(taskInstanceKeys);
            }
            this.setBusy(true);
            await this.saveEntities(true, false);
            const keys = await this.taskInstanceService.taskBulkStatusChange({
                TaskInstanceKeys: taskInstanceKeys,
                ModifiedByKey: this.currentResourceKey,
                TaskStatusKey: newKey,
                source: this.COMPONENT_LOG_TAG,
            });

            const taskKeysToRefresh = keys.length > 0 ? keys.filter(k => taskInstanceKeys.includes(k)) : taskInstanceKeys;
            await this.refreshTasks(taskKeysToRefresh);
        } catch (error) {
            resetEntity();
            throw error;
        } finally {
            this.setBusy(false);
        }
    }

    /**
     * Handle a change to a task's DateDue value (only time if date is set)
     */
    dueTimeChanged(task: Entity<TaskInstance>, date: Date) {
        const newDateValue = task.DateDue ? new Date(task.DateDue) : date;
        if (newDateValue && date) {
            newDateValue.setHours(date.getHours());
            newDateValue.setMinutes(date.getMinutes());
            newDateValue.setSeconds(date.getSeconds());
            newDateValue.setMilliseconds(date.getMilliseconds());
        }
        this.dateDueChangedRequest(task, newDateValue);
    }

    /**
     * Handle a change to a task's DateDue value (only date if date is set)
     */
    async dateDueChanged(task: Entity<TaskInstance>, date: Date) {
        const newDateValue = task.DateDue ? new Date(task.DateDue) : date;
        if (newDateValue && date) {
            newDateValue.setFullYear(date.getFullYear());
            newDateValue.setMonth(date.getMonth());
            newDateValue.setDate(date.getDate());
        }
        await this.dateDueChangedRequest(task, newDateValue);
    }

    /**
     * Handle a change to a task's DateDue value
     */
    private async dateDueChangedRequest(task: Entity<TaskInstance>, date: Date) {
        const resetEntity = updateEntity(task, 'DateDue', date);
        try {
            const taskInstanceKeys = [task.C_TaskInstance_key];
            const isEndState = this.taskIsEndState(task);
            if (isEndState) {
                await this.createNotes(taskInstanceKeys);
            }
            this.setBusy(true);
            await this.saveEntities(true, false);
            const keys = await this.taskInstanceService.taskBulkDueDateChange({
                TaskDateDues: taskInstanceKeys.map(key => ({ TaskInstanceKey: key, DateDue: date ?? null })),
                ModifiedByKey: this.currentResourceKey,
                source: this.COMPONENT_LOG_TAG
            });

            const taskKeysToRefresh = keys.length > 0 ? keys.filter(k => taskInstanceKeys.includes(k)) : taskInstanceKeys;
            await this.refreshTasks(taskKeysToRefresh);
        } catch (error) {
            resetEntity();
            throw error;
        } finally {
            this.setBusy(false);
        }
    }

     /**
     * Bulk update the DateDue for matching tasks
     */
    
    async bulkDateDueChanged(): Promise<void> {
        if (this.bulkDateDueDisabled()) {
            return;
        }

        const tasks = this.rows
            .map((row: DataRow) => row.task as Entity<TaskInstance>)
            .filter(task => task.TaskAlias === this.bulk.dateDueTaskAlias && !task.IsWorkflowLocked);

        if (tasks.length === 0) {
            return;
        }

        // Warn if any of the selected tasks already has a date due
        const foundDueDate = tasks.some(task => (task.DateDue !== null));

        if (foundDueDate) {
            await this.confirmService.confirm({
                title: 'Overwrite existing values?',
                message: 'Some of the matching tasks already have a Date Due. ' +
                    'Are you sure you want to update the values?',
            });
        }

        const timeUnitKey = parseInt(this.bulk.dateDueUnitKey, 10);
        const timeUnit = this.timeUnits.find(item => (item.C_TimeUnit_key === timeUnitKey));
        const minutes = timeUnit.MinutesPerUnit * this.bulk.dateDueIncrement;
        const dateDue = this.bulk.dateDue;

        try {
            const taskEndStateKeys = tasks
                .filter(task => this.taskIsEndState(task))
                .map(task => task.C_TaskInstance_key);

            if (taskEndStateKeys.length > 0) {
                await this.createNotes(taskEndStateKeys);
            }
            this.setBusy(true);
            await this.saveEntities(true, false);
            const taskInstanceKeys = tasks.map(task => task.C_TaskInstance_key);
            const keys = await this.taskInstanceService.taskBulkDueDateChange({
              TaskDateDues: taskInstanceKeys.map((key, index) => ({
                  TaskInstanceKey: key,
                  DateDue: convertValueToLuxon(dateDue)
                      .plus({ 'minutes': Math.round(minutes) * index })
                      .toJSDate()
              })),
              ModifiedByKey: this.currentResourceKey,
              source: this.COMPONENT_LOG_TAG
            });

            const taskKeysToRefresh = keys.length > 0 ? keys.filter(k => taskInstanceKeys.includes(k)) : taskInstanceKeys;
            await this.refreshTasks(taskKeysToRefresh);
        } finally {
            this.setBusy(false);
        }
    }
    
    /**
     * Handle a change to a task's DateComplete value (only time if date is set)
     */
    async completedTimeChanged(row: DataRow, date: Date) {
        const task = row.task as Entity<TaskInstance>;
        const newDateValue = task.DateComplete ? new Date(task.DateComplete) : date;
        if (newDateValue && date) {
            newDateValue.setHours(date.getHours());
            newDateValue.setMinutes(date.getMinutes());
            newDateValue.setSeconds(date.getSeconds());
            newDateValue.setMilliseconds(date.getMilliseconds());
        }
        await this.completedDateChangedRequest(row, newDateValue);
    }

    /**
     * Handle a change to a task's DateDue value (only date if date is set)
     */
    completedDateChanged(row: DataRow, date: Date) {
        const task = row.task as Entity<TaskInstance>;
        const newDateValue = task.DateDue ? new Date(task.DateDue) : date;
        if (newDateValue && date) {
            newDateValue.setFullYear(date.getFullYear());
            newDateValue.setMonth(date.getMonth());
            newDateValue.setDate(date.getDate());
        }
        this.completedDateChangedRequest(row, newDateValue);
    }

    /**
     * Deal with the user marking a Task as completed.
     */
    private async completedDateChangedRequest(row: DataRow, date: Date) {
        const task = row.task as Entity<TaskInstance>;
        const resetEntity = updateEntity(task, 'DateComplete', date);
        try {
            const taskInstanceKeys = [task.C_TaskInstance_key];
            const isEndState = this.taskIsEndState(task);
            if (isEndState) {
                await this.createNotes(taskInstanceKeys);
            }
            this.setBusy(true);
            await this.saveEntities(true, false);
            const keys = await this.taskInstanceService.taskBulkCompletedDateChange({
                TaskInstanceKeys: taskInstanceKeys,
                DateComplete: date,
                ModifiedByKey: this.currentResourceKey,
                source: this.COMPONENT_LOG_TAG
            });

            const taskKeysToRefresh = keys.length > 0 ? keys.filter(k => taskInstanceKeys.includes(k)) : taskInstanceKeys;
            await this.refreshTasks(taskKeysToRefresh);
        } catch (error) {
            resetEntity();
            throw error;
        } finally {
            this.setBusy(false);
        }
    }

    /**
     * Bulk update the DateComplete value.
     */
    async bulkCompletedTimeChanged() {
        const force = this.areBulkChangesForced();

        const tasks = this.tasks.filter(task => !task.IsWorkflowLocked || force)
        const taskInstanceKeys = tasks.map(task => task.C_TaskInstance_key);
        const taskEndStateKeys = tasks
            .filter(task => this.taskIsEndState(task))
            .map(task => task.C_TaskInstance_key);

        if (taskInstanceKeys.length === 0) {
            return;
        }

        try {
            if (taskEndStateKeys.length > 0) {
                await this.createNotes(taskEndStateKeys);
            }

            this.setBusy(true);
            await this.saveEntities(true, false);
            const keys = await this.taskInstanceService.taskBulkCompletedDateChange({
                TaskInstanceKeys: taskInstanceKeys,
                DateComplete: this.bulk.completedTime ?? null,
                ModifiedByKey: this.currentResourceKey,
                source: this.COMPONENT_LOG_TAG
            });

            const taskKeysToRefresh = keys.length > 0 ? keys.filter(k => taskInstanceKeys.includes(k)) : taskInstanceKeys;
            await this.refreshTasks(taskKeysToRefresh);
        } finally {
            this.setBusy(false);
        }
    }

    /**
     * Bulk update the Completed flag
     */
    async bulkCompletedChanged(): Promise<void> {
        const force = this.areBulkChangesForced();
        const tasks = this.tasks.filter(task => (force && !task.IsWorkflowLocked) || !this.taskIsEndState(task))
        const taskInstanceKeys = tasks.map(task => task.C_TaskInstance_key);

        if (taskInstanceKeys.length === 0) {
            return;
        }

        const taskEndStateKeys = tasks
            .filter(task => this.taskIsEndState(task))
            .map(task => task.C_TaskInstance_key);
      
        if (taskEndStateKeys.length > 0) {
            await this.createNotes(taskEndStateKeys);
        }
        try {
            this.setBusy(true);
            await this.saveEntities(true, false);
            const keys = await this.taskInstanceService.taskBulkStatusChange({
                TaskInstanceKeys: taskInstanceKeys,
                ModifiedByKey: this.currentResourceKey,
                TaskStatusKey: this.taskDefaultEndStatus?.C_TaskStatus_key,
                source: this.COMPONENT_LOG_TAG,
            });

            const taskKeysToRefresh = keys.length > 0 ? keys.filter(k => taskInstanceKeys.includes(k)) : taskInstanceKeys;
            if (this.isDotmatics) {
                this.bulkSyncDotmaticsSamples(taskKeysToRefresh);
            }
            await this.refreshTasks(taskKeysToRefresh);
        } finally {
            this.setBusy(false);
        }
    }

    /**
     * Deal with a the user clicking the Completed button
     */
    async completedChange($event: PointerEvent, row: DataRow, actualRowIndex: number): Promise<void> {
        this.showEndStateModal(row.index);
        // Don't propagate the click to the parent row
        $event.stopPropagation();
        console.log(event);
        const task = row.task as Entity<TaskInstance>;
        const isEndState = this.taskIsEndState(task);
        const newTaskStatusKey = (isEndState ? this.taskDefaultStatus : this.taskDefaultEndStatus)?.C_TaskStatus_key;
        const resetEntity = updateEntity(task, 'DateComplete', newTaskStatusKey);
        try {
            this.setBusy(true);
            if (isEndState) {
                await this.createNotes([task.C_TaskInstance_key]);
            }
            await this.saveEntities(true, false);
            const taskInstanceKeys = [task.C_TaskInstance_key];
            const keys = await this.taskInstanceService.taskBulkStatusChange({
                TaskInstanceKeys: taskInstanceKeys,
                ModifiedByKey: this.currentResourceKey ?? task.C_CompletedBy_key,
                TaskStatusKey: newTaskStatusKey,
                source: this.COMPONENT_LOG_TAG,
            });

            // PointerEvent.pointerType is blank if the pointer is not from a mouse click, tap, or pen.  
            if (this.searchEnabled && $event.pointerType == "") {
                this.refocusSearchInput();
            } else {
                this.setFocusToNextDataInput(actualRowIndex + 1);
            }

            const taskKeysToRefresh = keys.length > 0 ? keys.filter(k => taskInstanceKeys.includes(k)) : taskInstanceKeys;
            await this.refreshTasks(taskKeysToRefresh);
        } catch (error) {
            resetEntity();
            throw error;
        } finally {
            this.setBusy(false);
        }
    }

    private async createNotes(keys: number[]) {
      const res = Boolean(keys.length) ? await this.showDataChangeModal(true, keys) : Promise.resolve();
      if (res === 'cancel') {
          throw new Error('Post edit modal was cancelled');
      }
    }
}
