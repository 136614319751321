import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { Observable } from 'rxjs';
import {
    NgbTypeahead,
    NgbTypeaheadSelectItemEvent
} from '@ng-bootstrap/ng-bootstrap';

import { ClimbTypeahead } from './climb-typeahead.interface';
import { TypeaheadHelper } from './typeahead-helper';

import {
    BrowserDetection,
    randomId
} from '../util';

/*
* Component to select data from a typeahead
*
* It does not set a model value, like a traditional <input>,
*   but rather is used primarily for the (selectItem) output event.
*
* NOTE: Can select an item based on exact text match, if only 1 result returned
*/
@Component({
    selector: 'climb-indirect-typeahead',
    templateUrl: './climb-typeahead-shared.html',
    styleUrls: ['./climb-typeahead-shared.scss'],
})
export class ClimbIndirectTypeaheadComponent implements ClimbTypeahead, OnInit, AfterViewInit {
    @ViewChild(NgbTypeahead) ngbTypeahead: NgbTypeahead;

    /*
    * search is a function returning observable.
    *   preferably search should be an arrow function
    *   (or else 'this' keyword can't be used.
    */
    @Input() search: (text: string) => Promise<any[]>;
    /*
    * resultFormatter is a function returning a string.
    *   Is used to display results using an object property.
    *
    *   E.g. resultFormatter = (value: any) => { return value.AnimalName; }
    */
    @Input() resultFormatter: (value: any) => string;
    @Input() resultTemplate: any;

    /*
    * Optional override of default exact match behavior
    */
    @Input() exactMatchFunction: (data: any[], inputText: string) => boolean;

    /*
    * input element placeholder text
    */
    @Input() placeholder: string;
    @Input() disabled: boolean;
    @Input() required: boolean;
    @Input() fieldName: string;
    @Input() id: string;
    @Input() container = "";
    @Input() error: boolean;

    /*
    * Callback when item is selected
    */
    @Output() selectItem: EventEmitter<any> = new EventEmitter<any>();

    /*
    * Callback on blur
    */
    @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();


    typeaheadHelper: TypeaheadHelper;


    // state variables
    inputModel: string;

    constructor() {
        this.typeaheadHelper = new TypeaheadHelper();
    }

    ngOnInit() {
        this.clearInputField();

        if (!this.fieldName) {
            this.fieldName = "typeahead_input_" + randomId();
        }

        if (!this.id) {
            this.id = "typeahead_input_" + randomId();
        }

        // if no result formatter, just display raw search results
        if (!this.resultFormatter) {
            this.resultFormatter = (value: any) => value;
        }

        if (!this.placeholder ||
            // IE 'input' event with placeholder text causes bad behavior.
            BrowserDetection.isIE()
        ) {
            this.placeholder = '';
        }
    }

    ngAfterViewInit() {
        this.typeaheadHelper.setNgbTypeahead(this.ngbTypeahead);
    }

    clearInputField() {
        this.inputModel = '';
    }

    selectItemHandler(event: NgbTypeaheadSelectItemEvent) {
        setTimeout(() => {
            this.inputModel = null;
            this.typeaheadHelper.writeInputValue("");
        });
        event.preventDefault();
        this.selectItem.emit(event.item);
    }

    // observable function for ng-bootstrap typeahead
    searchObservable = (text$: Observable<string>): Observable<any[]> => {
        return this.typeaheadHelper.searchObservable(
            text$,
            (text: string) => this.search(text),
            (value: unknown) => this.resultFormatter(value),
            this.exactMatchFunction ? (value: unknown[], term: string) => this.exactMatchFunction?.(value, term) : undefined, 
        );
    }

    onBlurHandler(event: Event) {
        // Not implemented
        this.onBlur.emit(event);
    }

    onClickHandler(event: Event) {
        // Temporarily disable, because it breaks bar-code scanning
        // this.typeaheadHelper.triggerTypeahead();
    }

    onShowClickHandler() {
        this.typeaheadHelper.triggerTypeahead();
    }

    onClearClickHandler() {
        this.clearInputField();
    }

    onKeydownHandler(event: KeyboardEvent) {
        this.typeaheadHelper.triggerTypeaheadOnDownArrow(event);
    }

    onModelChange(event: Event) {
        // Not implemented
    }
}
