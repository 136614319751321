import { Component, OnInit, Input, StaticProvider, forwardRef, OnDestroy, Injector } from '@angular/core';
import { Comparator, Formatter, OnChange, OnTouched, Search, Value } from './multiselect-control.types';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const valueAccessorProvider: StaticProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiselectControlComponent),
  multi: true,
}

@Component({
  selector: 'climb-multiselect-control',
  templateUrl: './multiselect-control.component.html',
  styleUrls: ['./multiselect-control.component.scss'],
  providers: [valueAccessorProvider],
})
export class MultiselectControlComponent<T> implements ControlValueAccessor, OnInit, OnDestroy {
  private readonly subs = new Subscription();
  private onChange: OnChange<T[]> = () => undefined;
  private onTouched: OnTouched = () => undefined;
  private ngControl: NgControl | null;

  @Input() placeholder: string;
  @Input() formatter: Formatter<T>;
  @Input() comparator: Comparator<T>;
  @Input() search: Search<T>;
  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
  }
  get disabled(): boolean {
    return this._disabled;
  }
  _disabled = false;

  form = this.fb.array([]);

  get error(): boolean {
    return Boolean(this.ngControl?.invalid);
  }

  constructor(
    private fb: FormBuilder,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);
    const valueChangesSubs = this.form.valueChanges
      .pipe(debounceTime(20))
      .subscribe(() => {
        this.onChange(this.form.getRawValue());
        this.onTouched();
      });
    this.subs.add(valueChangesSubs);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  removeItem(index: number): void {
    this.form.removeAt(index);
  }

  selectItem(item: T): void {
    this.form.push(this.fb.control(item));
  }

  writeValue(value: Value<T>): void {
    const result = value ?? [];
    while (result.length !== this.form.length) {
      const lastIndex = this.form.length - 1;
      if (result.length > this.form.length) {
        this.form.push(this.fb.control(null));
      }
      if (result.length < this.form.length) {
        this.form.removeAt(lastIndex);
      }
    }
    this.form.setValue(result, { emitEvent: false });
  }

  registerOnChange(fn: OnChange<T[]>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouched): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  searchValues = (term: string): Promise<T[]> => this.search(term, this.form.value);
}
