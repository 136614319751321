import { Entity } from "../breeze.interface";
import { SettingTaskOutputPoolOutput } from "./setting-task-output-pool-output.interface";
import { SettingTaskOutputPoolWorkflowTask } from "./setting-task-output-pool-workflow-task.interface";

export interface SettingTaskOutputPool {
  C_SettingTaskOutputPool_key?: number;
  CreatedBy?: Date;
  DateCreated?: Date;
  DateModified?: Date; 
  ModifiedBy?: string
  SettingTaskOutputPoolName?: string;
  SettingTaskOutputPoolOutputs?: Entity<SettingTaskOutputPoolOutput>[];
  SettingTaskOutputPoolWorkflowTasks?: Entity<SettingTaskOutputPoolWorkflowTask>[];
  Version?: number;
}