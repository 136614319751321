import { Component, ChangeDetectionStrategy, Input, HostBinding, AfterViewInit, OnChanges, SimpleChanges, Inject, OnDestroy } from '@angular/core';
import { ModernAppService } from '@common/modern-app.service';
import { COMPONENT_DATA_STORE_TOKEN, ComponentDataStore } from 'src/app/mf-store/components-data';

let componentCounter = 0;

@Component({
  selector: 'climb-modern-component-factory',
  template: '',
  styleUrls: ['./modern-component-factory.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModernComponentFactoryComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() facetId?: string;
  @Input() component: ModernComponent;
  @Input() data: unknown;

  @HostBinding('id')
  elementId = (++componentCounter).toString();

  constructor(
    private modernApp: ModernAppService,
    @Inject(COMPONENT_DATA_STORE_TOKEN)
    private componentDataStore: ComponentDataStore,
  ) {}

  ngAfterViewInit(): void {
    this.modernApp.openComponent(this.elementId, this.component.componentId, this.facetId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.previousValue !== changes.data?.currentValue) {
      this.componentDataStore.events.setDataByIdEvent({
        id: this.elementId,
        data: changes.data.currentValue,
      })
    }
  }

  ngOnDestroy(): void {
    this.componentDataStore.events.deleteDataByIdEvent({ id: this.elementId });
  }
}
