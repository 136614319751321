<!-- Shared template for ClimbTypeaheads -->
<!-- Requires position: relative because of auto-positioning of typeahead results -->
<div [ngClass]="{'climb-typeahead': true, 'has-danger': typeaheadHelper.searchFailed || error}"
     style="position: relative;">
    <div class="input-group">
        <input type="text"
               class="form-control"
               [name]="fieldName"
               [id]="id"
               [attr.name]="fieldName"
               [(ngModel)]="inputModel"
               (ngModelChange)="onModelChange($event)"
               [ngbTypeahead]="searchObservable"
               [placement]="['bottom-left', 'top-left']"
               [inputFormatter]="resultFormatter"
               [resultFormatter]="resultFormatter"
               [resultTemplate]="resultTemplate"
               (selectItem)="selectItemHandler($event)"
               placeholder="{{placeholder}}"
               [editable]="false"
               [required]="required"
               [disabled]="disabled"
               [container]="container"
               (blur)="onBlurHandler($event)"
               (click)="onClickHandler($event)"
               (keydown)="onKeydownHandler($event)" />
        <div class="input-group-append">
            <button class="btn btn-secondary"
                    type="button"
                    tabindex="-1"
                    aria-label="Clear"
                    *ngIf="inputModel"
                    [disabled]="disabled"
                    (click)="onClearClickHandler()">
                <i class="fa fa-remove remove-item" title="Clear"></i>
            </button>
            <button class="btn btn-secondary"
                    type="button"
                    tabindex="-1"
                    aria-label="Show choices"
                    *ngIf="!inputModel"
                    [disabled]="disabled"
                    (click)="onShowClickHandler()">
                <i class="fa fa-caret-down" title="Show choices&hellip;"></i>
            </button>
            <!-- Extra buttons get thrown in here -->
            <ng-content></ng-content>
        </div>
    </div>
    <div *ngIf="typeaheadHelper.searching" class="mt-2">
        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
        Searching&hellip;
    </div>
    <div class="alert alert-warning mt-1" role="alert" *ngIf="typeaheadHelper.searchFailed">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        Sorry, choices could not be loaded.
    </div>
</div>
