import { Injectable } from '@angular/core';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult
} from 'breeze-client';

import { DataManagerService } from '../services/data-manager.service';
import { EnumerationService } from '../enumerations/enumeration.service';
import { QueryDef } from '../services/query-def';
import { BaseEntityService } from '../services/base-entity.service';
import {
    getSafeProp,
    notEmpty,
    softCompare,
    sortObjectArrayByProperty,
} from '../common/util';
import {
    getIsActivePredicate
} from '../services/queries';
import { TaskType } from './models';
import { DataType } from '../data-type/data-type.type';
import { WebApiService } from '../services/web-api.service';
import { WorkflowService } from '../workflow/services/workflow.service';
import { CalculatedOutputExpression, Entity, Input, Output, OutputFlag, Protocol, Sample, SampleGroupSourceMaterial, TaskInstance, TaskJob, TaskMaterial, WorkflowTask } from '@common/types';

/**
 * Service for WorkflowTask definitions
 */
@Injectable()
export class TaskService extends BaseEntityService {

    draggedTasks: any[] = [];

    constructor(
        private dataManager: DataManagerService,
        private enumerationService: EnumerationService,
        private webApiService: WebApiService,
        private workflowService: WorkflowService,
    ) {
        super();
    }

    async getTasks(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery('WorkflowTasks', queryDef);
        query = query.where('IsHidden', '!=', 'true');

        // TODO: don't need these....
        this.ensureDefExpanded(queryDef, 'cv_TaskType');
        this.ensureDefExpanded(queryDef, "Output.cv_DataType");
        this.ensureDefExpanded(queryDef, "Output.OutputFlag");

        query = query.expand(queryDef.expands.join(','));

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        try {
            return await this.dataManager.executeQuery(query);
        } catch (error) {
            return this.dataManager.queryFailed(error);
        }
    }

    searchTasks(queryDef: QueryDef): Promise<Entity<WorkflowTask>[]> {
        // don't need count of search results
        queryDef.inlineCount = false;

        let query = this.buildDefaultQuery('WorkflowTasks', queryDef);
        query = query.select('C_WorkflowTask_key, TaskName');
        query = query.where('IsHidden', '!=', 'true');

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.returnQueryResults(query);
    }

    buildPredicates(filter: any): Predicate[] {
        const predicates: Predicate[] = [];
        if (!filter) {
            return predicates;
        }

        if (filter.TaskName) {
            predicates.push(Predicate.create('TaskName', FilterQueryOp.Contains, { value: filter.TaskName }));
        }
        if (filter.Input) {
            predicates.push(Predicate.create(
                'Input', FilterQueryOp.Any,
                'InputName', FilterQueryOp.Contains, { value: filter.Input },
            ));
        }
        if (filter.Output) {
            predicates.push(Predicate.create(
                'Output', FilterQueryOp.Any,
                'OutputName', FilterQueryOp.Contains, { value: filter.Output },
            ));
        }
        if (filter.Protocol) {
            predicates.push(Predicate.create(
                'ProtocolTask', FilterQueryOp.Any,
                'Protocol.ProtocolName', FilterQueryOp.Contains, { value: filter.Protocol },
            ));
        }
        if (filter.C_WorkflowTask_key) {
            predicates.push(Predicate.create(
                'C_WorkflowTask_key', 'eq', filter.C_WorkflowTask_key
            ));
        }
        if (filter.TaskType) {
            predicates.push(Predicate.create('cv_TaskType.TaskType', 'eq', filter.TaskType));
        }
        if (filter.C_TaskType_key) {
            predicates.push(
                Predicate.create('C_TaskType_key', 'eq', filter.C_TaskType_key)
            );
        }
        if (filter.IsActive) {
            const isActivePredicate: Predicate = getIsActivePredicate(filter.IsActive);
            predicates.push(isActivePredicate);
        }
        if (filter.Effort) {
            predicates.push(Predicate.create(
                'Effort', 'eq', filter.Effort
            ));
        }
        if (notEmpty(filter.CreatedBy)) {
            predicates.push(Predicate.create(
                'CreatedBy', 'eq', filter.CreatedBy
            ));
        }

        return predicates;
    }

    getTaskByKey(taskKey: number): Promise<any> {
        const query = EntityQuery.from('WorkflowTasks')
            .expand('cv_TaskType, OriginalJobWorkflowTask.CurrentWorkflowTask')
            .where('C_WorkflowTask_key', '==', taskKey);

        return this.dataManager.returnSingleQueryResult(query, true);
    }

    getTaskByOutputKey(outputKey: number): Promise<any> {
        const query = EntityQuery.from('WorkflowTasks')
            .expand('cv_TaskType')
            .where('Output', 'any', 'C_Output_key', 'eq', outputKey);

        return this.dataManager.returnSingleQueryResult(query);
    }

    getTaskInputs(taskKey: number): Promise<any[]> {
        const expands = [
            'cv_DataType',
            'cv_DosingTable',
            'cv_JobCharacteristicType',
            'WorkflowTask.Output.CalculatedOutputExpression.ExpressionInputMapping'
        ];

        const query = EntityQuery.from('Inputs')
            .expand(expands.join(','))
            .where('C_WorkflowTask_key', '==', taskKey)
            .orderBy('SortOrder')
            .where('IsActive', '==', true);

        return this.dataManager.returnQueryResults(query);
    }

    getTaskOutputs(taskKey: number): Promise<any[]> {
        const expands = [
            'cv_DataType',
            'CalculatedOutputExpression.ExpressionOutputMapping'
        ]
        const query = EntityQuery.from('Outputs')
            .expand(expands.join(','))
            .where('C_WorkflowTask_key', '==', taskKey)
            .orderBy('SortOrder')
            .where('IsActive', '==', true);

        return this.dataManager.returnQueryResults(query);
    }

    createTask(): any {
        const initialValues = {
            IsActive: true,
            IsCostPerMaterial: false,
            IsDurationPerMaterial: false,
            IsEffortPerMaterial: false,
            SingleAnimal: true,
            ShowAgeInDays: false,
            ShowAgeInWeeks: false,
            ShowAnimalComments: false,
            ShowAnimalStatus: false,
            ShowBirthDate: false,
            ShowHousingID: false,
            ShowMarker: false
        };

        return this.dataManager.createEntity('WorkflowTask', initialValues);
    }

    createInput(initialValues: any): any {
        return this.dataManager.createEntity('Input', initialValues);
    }

    createOutput(initialValues: any): any {
        return this.dataManager.createEntity('Output', initialValues);
    }

    createCalculatedOutputExpression(initialValues: any): any {
        return this.dataManager.createEntity('CalculatedOutputExpression', initialValues);
    }

    createInputMapping(calcOutExpr: any, input: any): any {
        const initialValues: any = {
            C_CalculatedOutputExpression_key: calcOutExpr.C_CalculatedOutputExpression_key,
            C_Input_key: input.C_Input_key,
        };
        return this.dataManager.createEntity('ExpressionInputMapping', initialValues);
    }

    createOutputMapping(calcOutExpr: any, output: any): any {
        const initialValues: any = {
            C_CalculatedOutputExpression_key: calcOutExpr.C_CalculatedOutputExpression_key,
            C_Output_key: output.C_Output_key,
        };
        return this.dataManager.createEntity('ExpressionOutputMapping', initialValues);
    }

    createProtocolInstance(initialValues: any): any {
        return this.dataManager.createEntity('ProtocolInstance', initialValues);
    }

    async createTaskInstance(initialValues: any, isCRO?: boolean, job?: any): Promise<any> {
        initialValues.IsLocked = false;
        const taskInstance = this.dataManager.createEntity('TaskInstance', initialValues);

        await this.addTaskInstanceAssociations(taskInstance, initialValues.TaskInputs, isCRO, job);
        await this.addSampleGroupAssociations(taskInstance, initialValues.SampleGroups);
        await this.enumerationService.attachInputEnumerations(taskInstance.TaskInput);
        return taskInstance;
    }

    private addSampleGroupAssociations(taskInstance: any, sampleGroups?: any[]): Promise<any> {
        if (sampleGroups) {
            for (const group of sampleGroups) {
                const newGroup: any = {
                    C_TaskInstance_key: taskInstance.C_TaskInstance_key,
                    NumSamples: group.NumSamples,
                    C_SampleType_key: group.C_SampleType_key,
                    C_SampleStatus_key: group.C_SampleStatus_key,
                    C_PreservationMethod_key: group.C_PreservationMethod_key,
                    C_ContainerType_key: group.C_ContainerType_key,
                    C_SampleSubtype_key: group.C_SampleSubtype_key,
                    C_SampleProcessingMethod_key: group.C_SampleProcessingMethod_key,
                    SendTo: group.SendTo,
                    C_SampleAnalysisMethod_key: group.C_SampleAnalysisMethod_key,
                    SpecialInstructions: group.SpecialInstructions
                };
                this.dataManager.createEntity('SampleGroup', newGroup);
            }
        }

        return Promise.resolve(taskInstance);
    }

    private async addTaskInstanceAssociations(taskInstance: any, taskInputs?: any[], isCRO?: boolean, job?: any): Promise<any> {
        // Fetch WorkflowTask record so it is available for the new task
        const queryWorkflowTasks = EntityQuery.from('WorkflowTasks')
            .where('C_WorkflowTask_key', '==', taskInstance.C_WorkflowTask_key);

        try {
            await this.dataManager.executeQuery(queryWorkflowTasks);
            if (!taskInstance.TaskAlias) {
                // No alias provided, so default to the task name
                taskInstance.TaskAlias = taskInstance.WorkflowTask.TaskName;
            }
            // Attach Inputs to new TaskInstance
            const queryInputs = EntityQuery.from('Inputs')
                .expand('cv_DataType')
                .where('C_WorkflowTask_key', '==', taskInstance.C_WorkflowTask_key)
                .where('IsActive', '==', true);

            const data = await this.dataManager.executeQuery(queryInputs);
            const inputs = data.results as any[];
            for (const input of inputs) {
                const newTaskInput: any = {
                    C_TaskInstance_key: taskInstance.C_TaskInstance_key,
                    C_Input_key: input.C_Input_key
                };

                // Add an InputValue if exists
                if (notEmpty(taskInputs)) {
                    const initialInputValue = this.findTaskInputValue(
                        taskInputs,
                        input.C_Input_key
                    );
                    if (initialInputValue) {
                        newTaskInput.InputValue = initialInputValue;
                    }
                }

                this.dataManager.createEntity('TaskInput', newTaskInput);
            }

            if (isCRO) {
                this.setValueToJobCharateristicInputs(job, taskInstance);
            }
            return taskInstance;
        } catch (error) {
            return this.dataManager.queryFailed(error);
        }
    }

    private findTaskInputValue(taskInputs: any[], targetInputKey: number): string {
        let value = "";

        if (notEmpty(taskInputs) && targetInputKey) {
            for (const taskInput of taskInputs) {
                if (taskInput.C_Input_key === targetInputKey) {
                    value = taskInput.InputValue;
                    break;
                }
            }
        }

        return value;
    }

    /**
     * Set input value to all inputs matching same job characteristic type than the characteristics in the job
     * @param job
     * @param tasks
     */
    private setValueToJobCharateristicInputs(job: any, taskInstance: any) {
        const jobCharacteristics = job.JobCharacteristicInstance
            .filter((x: any) => !!x.JobCharacteristic.cv_JobCharacteristicType && notEmpty(x.CharacteristicValue));

        if (!taskInstance.TaskInput) {
            return;
        }

        for (const jobCharacteristic of jobCharacteristics) {
            const jobCharacteristicTypeKey = jobCharacteristic.JobCharacteristic.C_JobCharacteristicType_key;

            for (const taskInput of taskInstance.TaskInput) {
                if (taskInput.Input.cv_DataType.DataType === DataType.JOB_CHARACTERISTIC &&
                    taskInput.Input.C_JobCharacteristicType_key === jobCharacteristicTypeKey &&
                    taskInput.InputValue !== jobCharacteristic.CharacteristicValue) {
                    taskInput.InputValue = jobCharacteristic.CharacteristicValue;
                }
            }
        }
    }

    async createProtocolTaskInstances(
        protocolKey: number,
        protocolInstanceKey: number,
        taskAdditionalValues: any[],
        requiredTaskType?: TaskType
    ): Promise<any[]> {
        const manager = this.dataManager.getManager();
        const protocol = manager.getEntityByKey('Protocol', protocolKey) as Entity<Protocol>;

        // Ensure we have InputDefaults loaded
        await this.dataManager.ensureRelationships([protocol], ['ProtocolTask.InputDefault']);
        let promise: Promise<any> = Promise.resolve();
        const taskInstances: TaskInstance[] = [];
        // Ensure tasks are added in the right order
        const protocolTasksSorted = sortObjectArrayByProperty(protocol.ProtocolTask, 'SortOrder');
        for (const protocolTask of protocolTasksSorted) {
            // Skip task if not required type
            if (requiredTaskType) {
                if (!this.protocolTaskIsValidType(protocolTask, requiredTaskType)) {
                    continue;
                }
            }

            // Assign the default input values
            const taskInputs: any[] = protocolTask.InputDefault.map((inputDefault: any) => {
                return {
                    C_Input_key: inputDefault.C_Input_key,
                    InputValue: inputDefault.InputValue,
                };
            });

            const taskInitialValues: Record<string, unknown> = {
                C_Protocol_key: protocolKey,
                C_ProtocolTask_key: protocolTask.C_ProtocolTask_key,
                C_ProtocolInstance_key: protocolInstanceKey,
                C_WorkflowTask_key: protocolTask.C_WorkflowTask_key,

                TaskAlias: protocolTask.TaskAlias,
                TaskInputs: taskInputs,
            };

            if (taskAdditionalValues) {
                /* tslint:disable-next-line */
                for (const attrName in taskAdditionalValues) {
                    taskInitialValues[attrName] = taskAdditionalValues[attrName];
                }
            }

            promise = promise.then(async () => {
                const taskInstance = await this.createTaskInstance(taskInitialValues);
                taskInstances.push(taskInstance);
            });
        }
        await promise;
        return taskInstances;
    }

    private protocolTaskIsValidType(protocolTask: any, requiredTaskType: TaskType): boolean {
        const thisTaskType = this.getProtocolTaskType(protocolTask);

        return requiredTaskType === thisTaskType;
    }

    private getProtocolTaskType(protocolTask: any): string {
        return getSafeProp(protocolTask, 'WorkflowTask.cv_TaskType.TaskType');
    }

    generateProtocolAlias(existingProtocolInstances: any[], protocol: any): string {
        let protocolAlias = protocol.ProtocolName;
        let protocolCount = 1;
        for (const protocolInstance of existingProtocolInstances) {
            if (protocolInstance.C_Protocol_key === protocol.C_Protocol_key) {
                if (protocolInstance.ProtocolAlias.length > protocolAlias.length) {
                    const extra = protocolInstance.ProtocolAlias.substring(protocolAlias.length);
                    const leftSplit = extra.split('(');
                    const rightSplit = leftSplit[1].split(')');
                    const count = parseInt(rightSplit[0], 10);
                    if (count >= protocolCount) {
                        protocolCount = count + 1;
                    }
                } else {
                    protocolCount += 1;
                }
            }
        }

        if (protocolCount > 1) {
            protocolAlias += ' (' + protocolCount + ')';
        }

        return protocolAlias;
    }

    createTaskMaterial(initialValues: any): any {
        const manager = this.dataManager.getManager();
        const entityType = 'TaskMaterial';

        const initialTaskInstanceKey = initialValues.C_TaskInstance_key;
        const initialMaterialKey = initialValues.C_Material_key;

        // Check local entities for duplicates
        const taskMaterials: TaskMaterial[] = this.getNonDeletedLocalEntities(manager, entityType);
        const duplicates = taskMaterials.filter((taskMaterial) => {
            return softCompare(taskMaterial.C_TaskInstance_key, initialTaskInstanceKey) &&
                softCompare(taskMaterial.C_Material_key, initialMaterialKey);
        });

        if (duplicates.length === 0) {
            return this.dataManager.createEntity(entityType, initialValues);
        }

        return null;
    }

    createTaskBirth(initialValues: any): any {
        const manager = this.dataManager.getManager();
        const entityType = 'TaskBirth';

        const initialTaskInstanceKey = initialValues.C_TaskInstance_key;
        const initialBirthKey = initialValues.C_Birth_key;

        // Check local entities for duplicates
        const taskBirths: any[] = this.getNonDeletedLocalEntities(manager, entityType);
        const duplicates = taskBirths.filter((taskBirth) => {
            return softCompare(taskBirth.C_TaskInstance_key, initialTaskInstanceKey) &&
                softCompare(taskBirth.C_Birth_key, initialBirthKey);
        });

        if (duplicates.length === 0) {
            return this.dataManager.createEntity(entityType, initialValues);
        }

        return null;
    }

    createTaskMaterialPool(initialValues: any): any {
        const manager = this.dataManager.getManager();
        const entityType = 'TaskMaterialPool';

        const initialTaskInstanceKey = initialValues.C_TaskInstance_key;
        const initialMaterialPoolKey = initialValues.C_MaterialPool_key;

        // Check local entities for duplicates
        const taskMaterialPools: any[] = this.getNonDeletedLocalEntities(manager, entityType);
        const duplicates = taskMaterialPools.filter((taskMaterialPool) => {
            return softCompare(taskMaterialPool.C_TaskInstance_key, initialTaskInstanceKey) &&
                softCompare(taskMaterialPool.C_MaterialPool_key, initialMaterialPoolKey);
        });

        if (duplicates.length === 0) {
            return this.dataManager.createEntity(entityType, initialValues);
        }

        return null;
    }
    
    createTaskCohort(initialValues: any): any {
        const manager = this.dataManager.getManager();
        const entityType = 'TaskCohort';

        const initialTaskInstanceKey = initialValues.C_TaskInstance_key;
        const initialCohortKey = initialValues.C_Cohort_key;

        // Check local entities for duplicates
        const taskCohorts: any[] = this.getNonDeletedLocalEntities(manager, entityType);
        const duplicates = taskCohorts.filter((taskCohort) => {
            return softCompare(taskCohort.C_TaskInstance_key, initialTaskInstanceKey) &&
                softCompare(taskCohort.C_Cohort_key, initialCohortKey);
        });

        if (duplicates.length === 0) {
            return this.dataManager.createEntity(entityType, initialValues);
        }

        return null;
    }
    
    public async isTaskAssociatedWithWorkflowData(wfTask: any): Promise<boolean> {
        const apiUrl = `api/task/isTaskAssociatedWithWorkflowData/${wfTask.C_WorkflowTask_key}`;

        const response = await this.webApiService.callApi(apiUrl);
        return response.data;
    }

    public async isTaskAssociatedWithInheritedOutputs(wfTask: any): Promise<boolean> {
        const apiUrl = `api/task/isTaskAssociatedWithInheritedOutputs/${wfTask.C_WorkflowTask_key}`;

        const response = await this.webApiService.callApi(apiUrl);
        return response.data;
    }

    public async isOutputAssociatedWithWorkflowData(taskOutput: any): Promise<boolean> {
        const apiUrl = `api/task/isOutputAssociatedWithWorkflowData/${taskOutput.C_Output_key}`;

        const response = await this.webApiService.callApi(apiUrl);
        return response.data;
    }

    public async isOutputAssociatedWithInheritedOutputs(taskOutput: any): Promise<boolean> {
        const apiUrl = `api/task/isOutputAssociatedWithInheritedOutputs/${taskOutput.C_Output_key}`;

        const response = await this.webApiService.callApi(apiUrl);
        return response.data;
    }

    async deleteInputSafe(taskInput: any): Promise<boolean> {
        const query = EntityQuery.from('TaskInputs')
            .where('C_Input_key', '==', taskInput.C_Input_key);

        // safe if there are no TaskInputs
        const count = await this.dataManager.returnQueryCount(query);
        return count === 0;
    }

    async deleteTask(wfTask: any) {
        const expands = [
            'Input', 
            'Output', 
            'ProtocolTask',
            'TaskInstance.TaskOutputSet.TaskOutputSetMaterial',
            'TaskInstance.TaskMaterial',
            'TaskInstance.TaskJob',
            'TaskInstance.TaskLine',
            'TaskInstance.TaskAnimalHealthRecord',
            'TaskInstance.TaskCohort',
            'TaskInstance.SampleGroup', 
            'ProtocolTask',
            'Output.CalculatedOutputExpression.ExpressionOutputMapping',
            'Output.CalculatedOutputExpression.ExpressionInputMapping'
        ];
        await this.dataManager.ensureRelationships([wfTask], expands);
        while (wfTask.Input.length > 0) {
            const input = wfTask.Input[0];
            while (input.TaskInput.length > 0) {
                this.dataManager.deleteEntity(input.TaskInput[0]);
            }
            this.dataManager.deleteEntity(input);
        }
        while (wfTask.Output.length > 0) {
            const output = wfTask.Output[0];
            while (output.OutputFlag.length > 0) {
                this.dataManager.deleteEntity(output.OutputFlag[0]);
            }
            this.deleteOutput(wfTask.Output[0]);
        }
        while (wfTask.TaskInstance.length > 0) {
            const taskInstance = wfTask.TaskInstance[0];
            // delete output sets
            while (taskInstance.TaskOutputSet.length > 0) {
                const taskOutputSet = taskInstance.TaskOutputSet[0];
                // delete output set materials
                while (taskOutputSet.TaskOutputSetMaterial.length > 0) {
                    this.dataManager.deleteEntity(taskOutputSet.TaskOutputSetMaterial[0]);
                }
                this.dataManager.deleteEntity(taskOutputSet);
            }
            // delete task materials
            while (taskInstance.TaskMaterial.length > 0) {
                this.dataManager.deleteEntity(taskInstance.TaskMaterial[0]);
            }
            // delete task jobs
            while (taskInstance.TaskJob.length > 0) {
                this.dataManager.deleteEntity(taskInstance.TaskJob[0]);
            }
            // delete task lines
            while (taskInstance.TaskLine.length > 0) {
                this.dataManager.deleteEntity(taskInstance.TaskLine[0]);
            }
            // delete task health records
            while (taskInstance.TaskAnimalHealthRecord.length > 0) {
                this.dataManager.deleteEntity(taskInstance.TaskAnimalHealthRecord[0]);
            }

            // delete task cohort
            if (taskInstance.TaskCohort) {
                while (taskInstance.TaskCohort.length > 0) {
                    this.deleteTaskCohort(taskInstance.TaskCohort[0]);
                }
            }

            // delete sample groups
            if (taskInstance.SampleGroup) {
                while (taskInstance.SampleGroup.length > 0) {
                    this.dataManager.deleteEntity(taskInstance.SampleGroup[0]);
                }
            }

            this.deleteStoredFileMaps(taskInstance.StoredFileMap);

            this.dataManager.deleteEntity(taskInstance);
        }
        while (wfTask.ProtocolTask.length > 0) {
            this.dataManager.deleteEntity(wfTask.ProtocolTask[0]);
        }
        this.dataManager.deleteEntity(wfTask);
    }

    private deleteStoredFileMaps(storedFileMaps: any) {
        if (storedFileMaps) {
            while (storedFileMaps.length > 0) {
                const storedFileMap = storedFileMaps[0];
                this.dataManager.deleteEntity(storedFileMap);
                // Intentionally does not delete related StoredFile
            }
        }
    }

    deleteOutput(output: any) {
        while (output.CalculatedOutputExpression.length) {
            const expr = output.CalculatedOutputExpression[0];
            this.deleteCalculatedOutputExpression(expr);
        }

        while (output.TaskOutput.length > 0) {
            this.dataManager.deleteEntity(output.TaskOutput[0]);
        }

        while (output.OutputFlag.length > 0) {
            this.dataManager.deleteEntity(output.OutputFlag[0]);
        }

        this.dataManager.deleteEntity(output);
    }

    deleteCalculatedOutputExpression(expr: any) {
        while (expr.ExpressionInputMapping.length > 0) {
            this.dataManager.deleteEntity(expr.ExpressionInputMapping[0]);
        }

        while (expr.ExpressionOutputMapping.length > 0) {
            this.dataManager.deleteEntity(expr.ExpressionOutputMapping[0]);
        }

        this.dataManager.deleteEntity(expr);
    }

    deleteInput(input: any) {
        while (input.InputDefault.length > 0) {
            this.dataManager.deleteEntity(input.InputDefault[0]);
        }
        while (input.TaskInput.length > 0) {
            this.dataManager.deleteEntity(input.TaskInput[0]);
        } 
        this.dataManager.deleteEntity(input);
    }

    deleteOutputMapping(outputMapping: any) {
        this.dataManager.deleteEntity(outputMapping);
    }

    deleteInputMapping(inputMapping: any) {
        this.dataManager.deleteEntity(inputMapping);
    }

    deleteTaskMaterial(taskMaterial: any) {
        this.dataManager.deleteEntity(taskMaterial);
    }

    async deleteSampleFromTask(taskJob: TaskJob, sample: Sample) {
        for (const sampleGroup of taskJob.TaskInstance.SampleGroup) {
            const sampleGroupSourceMaterialToDelete = sampleGroup.SampleGroupSourceMaterial.filter((item: SampleGroupSourceMaterial) => {
                return item.C_Material_key && item.C_Material_key === sample.C_Material_key;
            });
            if (sampleGroupSourceMaterialToDelete) {
                sampleGroupSourceMaterialToDelete.forEach((toDelete: SampleGroupSourceMaterial) => {
                    this.dataManager.deleteEntity(toDelete);
                });
            }
        }

        const taskMaterial = taskJob.TaskInstance.TaskMaterial.find((taskMaterialItem: TaskMaterial) => {
            return taskMaterialItem.C_Material_key === sample.C_Material_key;
        });
        if (!taskMaterial) {
            return;
        }

        await this.dataManager.ensureRelationships([taskJob], ['TaskInstance.MemberTaskInstance.TaskMaterial']);
        await this.dataManager.ensureRelationships([sample], ['Material.MaterialSourceMaterial']);

        const sourceMaterialKeys = new Set(sample.Material.MaterialSourceMaterial.map(msm => msm.C_SourceMaterial_key)) ?? new Set();
        const memberTaskInstance = taskJob?.TaskInstance?.MemberTaskInstance?.find((mti: TaskInstance) => {
            if (!mti.TaskMaterial.length) {
                return false;
            }
            return mti.TaskMaterial.some(tm => tm.C_Material_key === taskMaterial.C_Material_key) && !mti.TaskMaterial.some(tm => sourceMaterialKeys.has(tm.C_Material_key));
        });
        
        const memberTaskMaterial = memberTaskInstance?.TaskMaterial.find((itemTaskMaterial: TaskMaterial) => {
            return itemTaskMaterial.C_TaskInstance_key === memberTaskInstance.C_TaskInstance_key;
        });

        if (taskMaterial?.C_TaskInstance_key === taskJob.C_TaskInstance_key && memberTaskMaterial) {
            this.deleteTaskMaterial(memberTaskMaterial);
            this.workflowService.deleteTask(memberTaskInstance);
            this.deleteTaskMaterial(taskMaterial);
        }
    }

    deleteTaskCohort(taskCohort: any) {
        if (taskCohort.TaskCohortInput) {
            while (taskCohort.TaskCohortInput.length > 0) {
                this.dataManager.deleteEntity(taskCohort.TaskCohortInput[0]);
            }
        }
        this.dataManager.deleteEntity(taskCohort);
    }

    cancelWorkflowTask(task: any) {
        if (!task) {
            return;
        }

        if (task.C_WorkflowTask_key > 0) {
            this.cancelWorkflowTaskEdits(task);
        } else {
            this.cancelNewWorkflowTask(task);
        }
    }

    private cancelNewWorkflowTask(task: any) {
        try {
            this.deleteTask(task);
        } catch (error) {
            console.error('Error cancelling new task: ' + error);
        }
    }

    private cancelWorkflowTaskEdits(task: any) {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(task);

        for (const output of task.Output) {
            this.dataManager.rejectChangesToEntityByFilter(
                'CalculatedOutputExpression', (item: any) => {
                    return item.C_Output_key === output.C_Output_key;
                }
            );
        }

        this.dataManager.rejectChangesToEntityByFilter(
            'Input', (item: any) => {
                return item.C_WorkflowTask_key === task.C_WorkflowTask_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'Output', (item: any) => {
                return item.C_WorkflowTask_key === task.C_WorkflowTask_key;
            }
        );
    }

    copyTask(fromTask: any, toTask: any) {
        // Copy task level details
        toTask.C_TaskType_key = fromTask.C_TaskType_key;
        toTask.TaskName = fromTask.TaskName + ' (Copy)';
        toTask.DateCreated = new Date();
        toTask.C_Workgroup_key = fromTask.C_Workgroup_key;
        toTask.Cost = fromTask.Cost;
        toTask.Description = fromTask.Description;
        toTask.Duration = fromTask.Duration;
        toTask.Effort = fromTask.Effort;
        toTask.IsActive = fromTask.IsActive;
        toTask.IsHidden = fromTask.IsHidden;
        toTask.IsEditable = fromTask.IsEditable;
        toTask.NoMaterials = fromTask.NoMaterials;
        toTask.IsCostPerMaterial = fromTask.IsCostPerMaterial;
        toTask.IsDurationPerMaterial = fromTask.IsDurationPerMaterial;
        toTask.IsEffortPerMaterial = fromTask.IsEffortPerMaterial;
        toTask.AutomaticallyEndTask = fromTask.AutomaticallyEndTask;
        toTask.ShowAgeInDays = fromTask.ShowAgeInDays;
        toTask.ShowAgeInWeeks = fromTask.ShowAgeInWeeks;
        toTask.ShowAnimalComments = fromTask.ShowAnimalComments;
        toTask.ShowAnimalStatus = fromTask.ShowAnimalStatus;
        toTask.ShowBirthDate = fromTask.ShowBirthDate;
        toTask.ShowHousingID = fromTask.ShowHousingID;
        toTask.ShowMarker = fromTask.ShowMarker;
        toTask.SingleAnimal = fromTask.SingleAnimal;
        toTask.SingleOutputPerAnimal = fromTask.SingleOutputPerAnimal;
        toTask.SortOrder = fromTask.SortOrder;

        const inputKeyMap: {[key: number]: number} = {};
        const outputKeyMap: {[key: number]: number} = {};
        const expressionKeyMap: {[key: number]: number} = {};

        // Copy the inputs
        for (const fromInput of fromTask.Input) {
            const toInput: Input = this.dataManager.createEntity('Input', {
                DateCreated: new Date(),
                C_WorkflowTask_key: toTask.C_WorkflowTask_key,
                C_DataType_key: fromInput.C_DataType_key,
                InputName: fromInput.InputName,
                SortOrder: fromInput.SortOrder,
                ValidationMax: fromInput.ValidationMax,
                ValidationMin: fromInput.ValidationMin,
                C_EnumerationClass_key: fromInput.C_EnumerationClass_key,
                C_VocabularyClass_key: fromInput.C_VocabularyClass_key,
                C_DosingTable_key: fromInput.C_DosingTable_key,
                C_JobCharacteristicType_key: fromInput.C_JobCharacteristicType_key,
                TextLineCount: fromInput.TextLineCount,
                IsActive: fromInput.IsActive,
                IsRequired: fromInput.IsRequired,
                RequiresValidation: fromInput.RequiresValidation
            });
            inputKeyMap[fromInput.C_Input_key] = toInput.C_Input_key;
        }

        // Copy the outputs
        for (const fromOutput of fromTask.Output) {
            let toOutput: any = this.dataManager.createEntity('Output', {
                DateCreated: new Date(),
                C_WorkflowTask_key: toTask.C_WorkflowTask_key,
                C_DataType_key: fromOutput.C_DataType_key,
                OutputName: fromOutput.OutputName,
                SortOrder: fromOutput.SortOrder,
                ValidationMax: fromOutput.ValidationMax,
                ValidationMin: fromOutput.ValidationMin,
                C_EnumerationClass_key: fromOutput.C_EnumerationClass_key,
                C_VocabularyClass_key: fromOutput.C_VocabularyClass_key,
                TextLineCount: fromOutput.TextLineCount,
                C_InheritedFromOutput_key: fromOutput.C_InheritedFromOutput_key,
                HasFlag: fromOutput.HasFlag,
                FlagMinimum: fromOutput.FlagMinimum,
                FlagMaximum: fromOutput.FlagMaximum,
                DecimalPlaces: fromOutput.DecimalPlaces,
                HasCohortStatsFlag: fromOutput.HasCohortStatsFlag,
                AverageFlagMinimum: fromOutput.AverageFlagMinimum,
                AverageFlagMaximum: fromOutput.AverageFlagMaximum,
                MedianFlagMinimum: fromOutput.MedianFlagMinimum,
                MedianFlagMaximum: fromOutput.MedianFlagMaximum,
                StdDevFlagMinimum: fromOutput.StdDevFlagMinimum,
                StdDevFlagMaximum: fromOutput.StdDevFlagMaximum,
                IsActive: fromOutput.IsActive,
                IsRequired: fromOutput.IsRequired,
                RequiresValidation: fromOutput.RequiresValidation
            });
            outputKeyMap[fromOutput.C_Output_key] = toOutput.C_Output_key;

            // Copy flags from the outputs
            for (const fromOutputFlag of fromOutput.OutputFlag) {
                const toOutputFlag: OutputFlag = this.dataManager.createEntity('OutputFlag', {
                    TaskFlagMessage: fromOutputFlag.TaskFlagMessage,
                    DateCreated: new Date(),
                    Maximum: fromOutputFlag.Maximum,
                    Minimum: fromOutputFlag.Minimum,
                });
                if (toOutput.OutputFlag) {
                    toOutput.OutputFlag.push(toOutputFlag);
                } else {
                    toOutput = [toOutputFlag];
                }
            }

            for (const fromCalcOutExp of fromOutput.CalculatedOutputExpression) {
                const toCalcOutExp: CalculatedOutputExpression = this.dataManager.
                    createEntity('CalculatedOutputExpression', {
                    C_Output_key: outputKeyMap[fromCalcOutExp.C_Output_key],
                    OutputExpression: fromCalcOutExp.OutputExpression,
                });
                expressionKeyMap[fromCalcOutExp.C_CalculatedOutputExpression_key] =
                    toCalcOutExp.C_CalculatedOutputExpression_key;

                for (const fromMapping of fromCalcOutExp.ExpressionInputMapping) {
                    if (inputKeyMap[fromMapping.C_Input_key]) {
                        this.dataManager.createEntity('ExpressionInputMapping', {
                            C_CalculatedOutputExpression_key: expressionKeyMap[
                                fromMapping.C_CalculatedOutputExpression_key],
                            C_Input_key: inputKeyMap[fromMapping.C_Input_key],
                            ExpressionVariableName: fromMapping.ExpressionVariableName
                        });
                    }
                }
                
                for (const fromMapping of fromCalcOutExp.ExpressionOutputMapping) {
                    if (outputKeyMap[fromMapping.C_Output_key]) {
                        this.dataManager.createEntity('ExpressionOutputMapping', {
                            C_CalculatedOutputExpression_key: expressionKeyMap[
                                fromMapping.C_CalculatedOutputExpression_key],
                            C_Output_key: outputKeyMap[fromMapping.C_Output_key],
                            ExpressionVariableName: fromMapping.ExpressionVariableName
                        });
                    }
                }
            }
        }
    }

    getOutputByKey(outputKey: number): Promise<Entity<Output>> {
        const outputQuery = EntityQuery.from('Outputs')
            .where('C_Output_key', '==', outputKey);
        return this.dataManager.returnSingleQueryResult(outputQuery);
    }

    getOutputByKeys(outputKeys: number[]): Promise<Entity<Output>[]> {
      const outputQuery = EntityQuery.from('Outputs')
          .where('C_Output_key', 'in', outputKeys);
      return this.dataManager.returnQueryResults(outputQuery);
  }

    cancelEditToTasks(tasks: any[]) {
        for (const task of tasks) {
            this.dataManager.rejectEntityAndRelatedPropertyChanges(task);
        }
    }

    async ensureVisibleColumnsDataLoaded(tasks: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(tasks[0], visibleColumns);
        return this.dataManager.ensureRelationships(tasks, expands);
    }
}
