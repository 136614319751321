import { AbstractControl, ValidatorFn } from "@angular/forms";

export const poolsRowValidator = (): ValidatorFn => (control: AbstractControl) => {
  if (control.value && control.value?.length) return null;

  const controls = control.parent?.controls ?? {};
  const rowEmpty = Object.values(controls).every((control) => !(control.value?.length));

  return !rowEmpty
    ? { required: "Each field in line is required" }
    : null;
};
