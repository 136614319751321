import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';

export interface OutputData {
  OutputName: string;
  xValues: string[],
  yValues: string[],
}

@Component({
    selector: 'output-chart-renderer',
    styles: [`
      :host {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        height: 250px;
      }

      h3 {
        padding: 0;
        margin: 0;
      }

      div {
        flex: 1;
      }
    `],
    template: `
      <h3>{{ output?.OutputName }}</h3>
      <div>
        <climb-modern-component-factory
          [component]="DATE_CHART"
          [data]="prepareData(output)"
        ></climb-modern-component-factory>
      </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutputChartRendererComponent {
    readonly DATE_CHART: ModernComponent = {
      componentId: 'date-chart.component',
    };

    @Input() output: OutputData;

    prepareData(output?: OutputData): { x: number, y: number }[] {
      return output?.xValues
        .map<{ x: number, y: number }>((item, index) => ({
          x: new Date(item).getTime(),
          y: Number(output.yValues[index]),
        }))
        .sort((itemA, itemB) => itemA.x - itemB.x) ?? [];
    }
}

