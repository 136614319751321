import { ScheduleSharedModule } from './schedule/schedule-shared.module';
import { DynamicComponentLoaderModule } from './dynamic/dynamic-component-loader.module';
import { TaskTableModule } from './tasks/task-table.module';
import { DataTypeModule } from './data-type/data-type.module';
import { GlobalErrorHandler } from './global-error.handler';
import { FacetSharedModule } from './common/facet/facet-shared.module';
import { ClimbAppComponent } from './climb-app.component';
import { EntityChangesModule } from './entity-changes/entity-changes.module';
import { WorkgroupModule } from './workgroup/workgroup.module';
import { GridsterModule } from 'angular2gridster';
import { DashboardModule } from './dashboard/dashboard.module';
import { WipModule } from './wip/wip.module';
import { ClimbRoutingModule } from './routing/climb-routing.module';
import { WizardSharedModule } from './wizard/wizard-shared.module';
import { ImportSharedModule } from './import/import-shared.module';
import { LocationsModule } from './locations/locations.module';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from "ag-grid-angular";
import { DragulaModule } from 'ng2-dragula';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { NgxMaskModule } from 'ngx-mask';

import { AnimalsModule } from './animals/animals.module';
import { AnimalsSharedModule } from './animals/animals-shared.module';

import { AuditModule } from './audit/audit.module';
import { AuditSharedModule } from './audit/audit-shared.module';

import { ClimbCommonModule } from './common/climb-common.module';

import { BirthsModule } from './birth/births.module';
import { BirthsSharedModule } from './birth/births-shared.module';

import { CharacteristicsModule } from './characteristics/characteristics.module';
import { CharacteristicsSharedModule } from './characteristics/characteristics-shared.module';
import { ChartsModule } from './charts/charts.module';

import { ImportModule } from './import/import.module';
import { IoTModule } from './iot/iot.module';
import { JobsSharedModule } from './jobs/jobs-shared.module';
import { TasksModule } from './tasks/tasks.module';

import { ClinicalModule } from './clinical/clinical.module';
import { ClinicalSharedModule } from './clinical/clinical-shared.module';

import { CohortSharedModule } from './cohort/cohort-shared.module';

import { ConstructsModule } from './constructs/constructs.module';
import { ConstructsSharedModule } from './constructs/constructs-shared.module';
import { EventsSharedModule } from './events/events-shared.module';

import { EnumerationsModule } from './enumerations/enumerations.module';
import { EnumerationsSharedModule } from './enumerations/enumerations-shared.module';
import { GenotypesModule } from './genotypes/genotypes.module';
import { GenotypesSharedModule } from './genotypes/genotypes-shared.module';
import { HousingModule } from './housing/housing.module';
import { HousingSharedModule } from './housing/housing-shared.module';
import { IoTSharedModule } from './iot/iot-shared.module';
import { LocationsSharedModule } from './locations/locations-shared.module';

import { InstitutionsModule } from './institution/institutions.module';
import { InstitutionsSharedModule } from './institution/institutions-shared.module';

import { LinesModule } from './lines/lines.module';
import { LinesSharedModule } from './lines/lines-shared.module';

import { LoginModule } from './login/login.module';

import { MatingsModule } from './matings/matings.module';
import { MatingsSharedModule } from './matings/matings-shared.module';

import { HeaderModule } from './header/header.module';

import { MonitoringModule } from './monitoring/monitoring.module';

import { MessagesSharedModule } from './messages/messages-shared.module';

import { OrdersSharedModule } from './orders/orders-shared.module';

import { PlatesModule } from './plates/plates.module';
import { PlatesSharedModule } from './plates/plates-shared.module';

import { ProtocolsSharedModule } from './protocol/protocols-shared.module';
import { ProtocolsModule } from './protocol/protocols.module';

import { PwdResetModule } from './pwdreset/pwd-reset.module';
import { RegisterModule } from './register/register.module';

import { ReportingModule } from './reporting/reporting.module';
import { ReportingSharedModule } from './reporting/reporting-shared.module';

import { ResourcesSharedModule } from './resources/resources-shared.module';

import { RolesModule } from './role/roles.module';
import { SamplesModule } from './samples/samples.module';
import { SamplesSharedModule } from './samples/samples-shared.module';
import { SearchSharedModule } from './search/search-shared.module';
import { SearchModule } from './search/search.module';
import { SettingsModule } from './settings/settings.module';

import { StudiesModule } from './studies/studies.module';
import { StudiesSharedModule } from './studies/studies-shared.module';

import { UsersModule } from './user/users.module';
import { UsersSharedModule } from './user/users-shared.module';

import { VocabulariesModule } from './vocabularies/vocabularies.module';
import { VocabulariesSharedModule } from './vocabularies/vocabularies-shared.module';
import { WorkflowsSharedModule } from './workflow/workflows-shared.module';
import { WorkflowsModule } from './workflow/workflows.module';
import { WorkspacesSharedModule } from './workspaces/workspaces-shared.module';
import { WorkspacesModule } from './workspaces/workspaces.module';

import { ArchwizardModule } from 'angular-archwizard';

import { WizardModule } from './wizard/wizard.module';

import { ClimbServicesModule } from './services/climb-services.module';
import { ScheduleModalsModule } from './schedule/schedule-modals-module';
import { TasksSharedModule } from './tasks/tasks-shared.module';

import { SSOModule } from './sso/sso.module';
import { DotmaticsModule } from './dotmatics/dotmatics.module';
import { VersionsModule } from './versions/versions.module';
import { CensusModule } from './census/census.module';
import { JobsModule } from './jobs/jobs.module';
import { MultipleTabModule } from './multiply-tab/multiple-tab.module';
import { CLIMB_SURFACE_MODE } from '@common/tokens';
import { FACET_STORE_PROVIDER, WORKSPACE_STORE_PROVIDER } from './mf-store';
import { EVENTS_PROVIDER } from './mf-store/events';
import { COMPONENT_DATA_STORE_PROVIDER } from './mf-store/components-data';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        DragulaModule.forRoot(),
        NgxBarcode6Module,
        NgxMaskModule.forRoot(),
        ArchwizardModule.forRoot(),
        // for SSO
        SSOModule.forRoot(),
        // for ag-grid
        AgGridModule.withComponents([]),
        NgxBootstrapMultiselectModule,
        NgxPaginationModule,
        GridsterModule.forRoot(),
        // our modules
        DynamicComponentLoaderModule.forRoot(),
        ClimbRoutingModule,
        HeaderModule,
        ClimbCommonModule.forRoot(),
        FacetSharedModule,
        AnimalsModule,
        AnimalsSharedModule.forRoot(),
        AuditModule,
        AuditSharedModule.forRoot(),
        BirthsModule,
        BirthsSharedModule,
        CharacteristicsModule,
        CharacteristicsSharedModule.forRoot(),
        ChartsModule,
        MultipleTabModule,
        ClinicalModule,
        ClinicalSharedModule.forRoot(),
        ClimbServicesModule.forRoot(),
        CohortSharedModule.forRoot(),
        ConstructsModule,
        ConstructsSharedModule,
        CensusModule,
        DashboardModule,
        DataTypeModule,
        DotmaticsModule.forRoot(),
        EventsSharedModule,
        EntityChangesModule.forRoot(),
        EnumerationsModule,
        EnumerationsSharedModule.forRoot(),
        GenotypesModule,
        GenotypesSharedModule,
        HousingModule,
        HousingSharedModule,
        LinesModule,
        LinesSharedModule.forRoot(),
        LocationsSharedModule,
        LocationsModule,
        LoginModule,
        ImportModule,
        ImportSharedModule,
        InstitutionsModule,
        InstitutionsSharedModule.forRoot(),
        IoTModule,
        IoTSharedModule,
        JobsModule,
        JobsSharedModule.forRoot(),
        MatingsModule,
        MatingsSharedModule,
        MessagesSharedModule.forRoot(),
        MonitoringModule,
        OrdersSharedModule.forRoot(),
        ProtocolsModule,
        ProtocolsSharedModule.forRoot(),
        PlatesModule,
        PlatesSharedModule,
        PwdResetModule,
        RegisterModule,
        ReportingModule,
        ReportingSharedModule,
        ResourcesSharedModule.forRoot(),
        RolesModule,
        SamplesModule,
        SamplesSharedModule.forRoot(),
        ScheduleModalsModule,
        ScheduleSharedModule.forRoot(),
        SearchSharedModule.forRoot(),
        SearchModule,
        SettingsModule,
        StudiesModule,
        StudiesSharedModule.forRoot(),
        TaskTableModule.forRoot(),
        TasksModule,
        TasksSharedModule.forRoot(),
        UsersModule,
        UsersSharedModule.forRoot(),
        VersionsModule,
        VocabulariesModule,
        VocabulariesSharedModule.forRoot(),
        WorkflowsSharedModule.forRoot(),
        WorkflowsModule,
        WorkspacesSharedModule.forRoot(),
        WizardModule,
        WizardSharedModule,
        WorkgroupModule,
        WorkspacesModule,
        WipModule,
    ],
    bootstrap: [
        ClimbAppComponent
    ],
    declarations: [
        ClimbAppComponent
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: CLIMB_SURFACE_MODE,
            useValue: 'light',
        },
        WORKSPACE_STORE_PROVIDER,
        FACET_STORE_PROVIDER,
        COMPONENT_DATA_STORE_PROVIDER,
        EVENTS_PROVIDER,
    ]
})
export class AppModule {}
