import { EventCallable } from 'effector';
import { InjectionToken, NgZone, Provider } from "@angular/core";
import { partial } from "lodash";
import { mappedStore$, mappedStore, StoreWrapper } from "../helpers";
import { $facetStore, expandEvent, collapseEvent, collapseAllEvent, closeEvent, addEvent, autoSizeEvent, changeEvent, initEvent } from "./facets";
import { Facet } from "./types";
import { Store } from '../types';


export * from './types';

export type FacetStore = Store<Facet[], {
  expandEvent: EventCallable<{ id: string }>,
  collapseEvent: EventCallable<{ id: string }>,
  collapseAllEvent: EventCallable<void>,
  closeEvent: EventCallable<{ id: string }>,
  addEvent: EventCallable<Pick<Facet, 'componentId' | 'facet' | 'type'>>,
  autoSizeEvent: EventCallable<void>,
  changeEvent: EventCallable<{ id: string, facet: Partial<Facet> }>,
  initEvent: EventCallable<Pick<Facet, 'componentId' | 'facet' | 'type'>[]>,
}>;

const Api: FacetStore = {
  __store: $facetStore,
  events: {
    expandEvent,
    collapseEvent,
    collapseAllEvent,
    closeEvent,
    addEvent,
    autoSizeEvent,
    changeEvent,
    initEvent,
  },
  getValue$: partial(mappedStore$, $facetStore),
  getValue: partial(mappedStore, $facetStore),
};

export const FACET_STORE_TOKEN = new InjectionToken<FacetStore>('FACET_STORE_TOKEN');
export const FACET_STORE_PROVIDER: Provider = {
  provide: FACET_STORE_TOKEN,
  useFactory: (ngZone: NgZone) => {
    const wrapper = new StoreWrapper(ngZone);
    wrapper.setApi(Api as Store);
    return wrapper;
  },
  deps: [NgZone],
};

window.mfStore ??= {};
window.mfStore.facetStore = Api;