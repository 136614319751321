import { SettingTaskOutputPool } from "./setting-task-output-pool.interface";
import { WorkflowTask } from "./workflow-task.interface";

export interface SettingTaskOutputPoolWorkflowTask {
  C_SettingTaskOutputPoolWorkflowTask_key?: number;
  C_WorkflowTask_key?: number;
  C_SettingTaskOutputPool_key?: number;
  CreatedBy?: string;
  DateCreated?: Date;
  ModifiedBy?: string;
  DateModified?: Date;
  Version?: number;
  WorkflowTask?: WorkflowTask;
  SettingTaskOutputPool?: SettingTaskOutputPool;
}