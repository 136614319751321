const EXCLUDE_CREATED = new Set(['Settings: Task Output Pools']);
const EXCLUDE_MODIFIED = new Set(['Job Standard Phrases', 'Study Administrator Studies']);
const EXCLUDE_DELETED = new Set(['Resources', 'Vocabularies: Housing Types', 'Vocabularies: Mating Types', 'Settings: Task Output Pools']);

export const getUpdateTypes = (subsection: string): { Name: string }[] => {
  const updateTypes: { Name: string }[] = [];

  if (!EXCLUDE_CREATED.has(subsection)) {
    updateTypes.push({ Name: 'Created' });
  }
  if (!EXCLUDE_MODIFIED.has(subsection)) {
    updateTypes.push({ Name: 'Modified' });
  }

  if (!EXCLUDE_DELETED.has(subsection)) {
    updateTypes.push({ Name: 'Deleted' });
  }

  return updateTypes;
}