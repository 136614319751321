import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy,
} from '@angular/core';

import {
    SaveChangesService,
    UnsavedChanges,
} from '../../services/save-changes.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'detail-toolbar',
    templateUrl: './detail-toolbar.component.html'
})
export class DetailToolbarComponent implements OnInit, OnDestroy {
    @Input() logTag: string;
    @Input() promptForSave = true;
    @Input() hasChanges = false;
    @Input() loading = false;

    // TODO: Remove this block of workaround Inputs
    @Input() addInstitution: boolean;
    @Input() addHousing: boolean;
    @Input() addVocabularies: boolean;
    @Input() addLocation: boolean;
    @Input() addUser: boolean;
    @Input() addRole: boolean;
    @Input() addEnumerations: boolean;
    @Input() addMonitoring: boolean;
    @Input() addStudy: boolean;
    @Input() emitOnSave: boolean;

    @Input() facetPrivilege = '';
    @Input() showListViewButton = true;

    // TODO: Remove all emitters from here
    @Output() saveHousing: EventEmitter<void> = new EventEmitter<void>();
    @Output() saveInstitution: EventEmitter<void> = new EventEmitter<void>();
    @Output() saveVocabularies: EventEmitter<void> = new EventEmitter<void>();
    @Output() saveLocation: EventEmitter<void> = new EventEmitter<void>();
    @Output() saveUser: EventEmitter<void> = new EventEmitter<void>();
    @Output() saveRole: EventEmitter<void> = new EventEmitter<void>();
    @Output() saveEnumerations: EventEmitter<void> = new EventEmitter<void>();
    @Output() saveMonitoring: EventEmitter<void> = new EventEmitter<void>();
    @Output() saveStudy: EventEmitter<void> = new EventEmitter<void>();
    @Output() genericSaveEmitter: EventEmitter<void> = new EventEmitter<void>();

    @Output() save = new EventEmitter<void>();
    @Output() exit = new EventEmitter<UnsavedChanges>();

    // TODO: this is getter for backwards compatibility:
    //  previously we are always looking at dataContext.checkChanges$ which is global changes state observable
    get hasPendingChanges(): boolean {
        return this.isFacetLevelSaveSupported ? this.hasChanges : this.saveChangesService.hasChanges;
    }

    get isSaveDisabled(): boolean {
        return this.isFacetLevelSaveSupported
          ? (!this.hasChanges || this.loading)
          : (!this.saveChangesService.hasChanges || this.saveChangesService.saving || this.saveChangesService.isLocked);
    }
    canWrite: boolean;
    private readonly facetsForFacetLevelSave = ['animal-detail'] as const;
    private unSubHasChange: Subscription;
    private isFacetLevelSaveSupported = false;

    constructor(private saveChangesService: SaveChangesService) {}

    ngOnInit() {
        // TODO: Remove backwards compatibility flag once all facets have separate saving/discard
        this.isFacetLevelSaveSupported = this.facetsForFacetLevelSave.some(facet => facet === this.logTag);

        this.initPrivilege();
    }

    ngOnDestroy() {
        if (this.unSubHasChange) {
            this.unSubHasChange.unsubscribe();
        }
    }

    private initPrivilege(): void {
        this.canWrite = (this.facetPrivilege === 'ReadWrite');
    }

    saveClicked(): void {
        if (this.addHousing) {
            this.saveHousing.emit();
            return;
        }
        if (this.addInstitution) {
            this.saveInstitution.emit();
            return;
        }
        if (this.addVocabularies) {
            this.saveVocabularies.emit();
            return;
        }
        if (this.addLocation) {
            this.saveLocation.emit();
            return;
        }
        if (this.addUser) {
            this.saveUser.emit();
            return;
        }
        if (this.addRole) {
            this.saveRole.emit();
            return;
        }
        if (this.addEnumerations) {
            this.saveEnumerations.emit();
            return;
        }
        if (this.addMonitoring) {
            this.saveMonitoring.emit();
            return;
        }
        if (this.addStudy) {
            this.saveStudy.emit();
            return;
        }
        if (this.emitOnSave) {
            this.genericSaveEmitter.emit();
            return;
        }

        // This is temporary solution. In the future, it should call this.save.emit without any condition
        if (this.isFacetLevelSaveSupported) {
            this.save.emit();
            return;
        }

        this.saveChangesService.saveChanges(this.logTag);
    }

    exitClicked(): void {
        if (this.isFacetLevelSaveSupported) {
            this.exit.emit(UnsavedChanges.noChanges);
        } else {
            // How it was handled before. Left as is for backwards compatibility
            if (!this.promptForSave) {
                this.exit.emit(UnsavedChanges.noChanges);
                return;
            }

            this.saveChangesService.promptForUnsavedChanges(this.logTag).then((result) => {
                this.exit.emit(result);
            });
        }

    }
}
