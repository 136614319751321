import { FacetLoadingStateService } from './facet-loading-state.service';
import { BaseFacetService } from './base-facet.service';
import { BaseDetailService } from './base-detail.service';
import { FacetHeaderComponent } from './components/facet-header/facet-header.component';
import { FacetHeaderActionButtonComponent } from './components/facet-header/facet-header-action-button.component';
import { FacetToolbarComponent } from './components/facet-toolbar/facet-toolbar.component';
import { ToolbarAddButtonComponent } from './components/toolbar-add-button/toolbar-add-button.component';
import { ToolbarBulkDropdownComponent } from './components/toolbar-bulk-dropdown/toolbar-bulk-dropdown.component';
import { ToolbarCageCardButtonComponent } from './components/toolbar-cage-card-button/toolbar-cage-card-button.component';
import { ToolbarSaveButtonComponent } from './components/toolbar-save-button/toolbar-save-button.component';
import { ToolbarListViewButtonComponent } from './components/toolbar-list-view-button/toolbar-list-view-button.component';
import { WorkspacesSharedModule } from './../../workspaces/workspaces-shared.module';
import { ClimbCommonModule } from './../climb-common.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClimbServicesModule } from '../../services/climb-services.module';

/**
 * Module for all shared facet behavior and components.
 *   (can't include in ClimbCommon due to circular references with WorkspacesShared)
 */
@NgModule({
    imports: [
        ClimbCommonModule,
        ClimbServicesModule,
        CommonModule,
        WorkspacesSharedModule
    ],
    declarations: [
        FacetHeaderComponent,
        FacetHeaderActionButtonComponent,
        FacetToolbarComponent,
        ToolbarAddButtonComponent,
        ToolbarBulkDropdownComponent,
        ToolbarCageCardButtonComponent,
        ToolbarSaveButtonComponent,
        ToolbarListViewButtonComponent,
    ],
    exports: [
        FacetHeaderComponent,
        FacetHeaderActionButtonComponent,
        FacetToolbarComponent,
        ToolbarAddButtonComponent,
        ToolbarBulkDropdownComponent,
        ToolbarCageCardButtonComponent,
        ToolbarSaveButtonComponent,
        ToolbarListViewButtonComponent,
    ],
    providers: [
        BaseDetailService,
        BaseFacetService,
        FacetLoadingStateService
    ]
})
export class FacetSharedModule { }
