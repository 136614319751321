import { InjectionToken, StaticProvider } from "@angular/core";
import { openComponentEvent } from "./events";
import { Event } from '../types';

export interface Events {
  openComponent: Event<{ elementId: string, componentId: string, facetId?: string }>;
}

const Api: Events = {
  openComponent: openComponentEvent,
};

export const EVENTS_TOKEN = new InjectionToken<Events>('EVENTS_TOKEN');
export const EVENTS_PROVIDER: StaticProvider = {
  provide: EVENTS_TOKEN,
  useValue: Api,
};

window.mfStore ??= {};
window.mfStore.events = Api;