import { Output } from "./output.interface";
import { SettingTaskOutputPool } from "./setting-task-output-pool.interface";

export interface SettingTaskOutputPoolOutput {
  C_SettingTaskOutputPoolOutput_key?: number;
  C_Output_key?: number;
  C_SettingTaskOutputPool_key?: number;
  CreatedBy?: string;
  DateCreated?: Date;
  ModifiedBy?: string;
  DateModified?: Date;
  Version?: number;
  Output?: Output;
  SettingTaskOutputPool?: SettingTaskOutputPool;
}