import { ApiResponse } from './models/api-response';
import { Injectable, } from '@angular/core';

import { notEmpty } from '../common/util/not-empty';

import { apiServiceBaseUri, apiServiceBaseUriAdmin, statusApiAppKey, statusApiID } from '../config/climb-web-settings';
import { CountResult } from './models';
import { 
    HttpClient, 
    HttpHeaders, 
    HttpParams 
} from '@angular/common/http';
import { OverlayService } from './overlay-service';

@Injectable()
export class WebApiService {

    constructor(
        private httpClient: HttpClient,
        private overlayService: OverlayService
    ) {
        // nothing to do
    }

    callTextApi(apiUrl: string, params?: HttpParams): Promise<ApiResponse> {
        const responseType = 'text';
        return this.callApi(apiUrl, params, responseType);
    }

    callApi(
        apiUrl: string, 
        params?: HttpParams, 
        responseType?: string
    ): Promise<ApiResponse> {
        return this.callGet(
            apiServiceBaseUri,
            apiUrl,
            params,
            responseType
        );
    }

    callGetAdminApi(
        apiUrl: string,
        params?: HttpParams,
        responseType?: string
    ): Promise<ApiResponse> {
        return this.callGet(
            apiServiceBaseUriAdmin,
            apiUrl,
            params,
            responseType
        );
    }

    private callGet<T = any>(
        serviceBaseUrl: string,
        apiUrl: string,
        params?: HttpParams,
        responseType?: string
    ): Promise<ApiResponse<T>> {
        const serviceUrl = serviceBaseUrl + apiUrl;

        const options: any = {
            params
        };

        if (responseType) {
            options.responseType = responseType;
        }

        return this.httpClient.get(serviceUrl, options).toPromise().then((response) => {
            return <ApiResponse<T>>{
                data: response as unknown as T,
            };
        });
    }

    callCountApi(apiUrl: string, keys: number[]): Promise<CountResult[]> {
        let params = new HttpParams();
        if (keys) {
            params = params.set('keys', keys.join(','));
        }
        return this.callApi(apiUrl, params).then((response) => {
            return response.data.results as CountResult[];
        });
    }

    postApi<T = any>(apiUrl: string, 
            data?: any, 
            contentType?: string,
            showOverlay = true,
            addDataToOptions = true
            ): Promise<ApiResponse<T>> {
        let overlayId: string;
        if (showOverlay) {
            overlayId = this.overlayService.show();
        }
        
        const serviceUrl = apiServiceBaseUri + apiUrl;

        let headers = new HttpHeaders();
        if (contentType) { 
            headers = headers.append('Content-Type', contentType); 
        }
        const options = { headers };

        if (addDataToOptions) {
            options['params'] = data;
        }

        return this.httpClient.post(serviceUrl, data, options).toPromise().then((response) => {
            return {
                data: response
            } as ApiResponse<T>;
        }).finally(() => {
            if (showOverlay) {
                this.overlayService.hide(overlayId);
            }
        });
    }

    putApi<T = any>(apiUrl: string, 
        data?: any, 
        contentType?: string,
        showOverlay = true,
        addDataToOptions = true
        ): Promise<ApiResponse<T>> {
        let overlayId: string;
        if (showOverlay) {
            overlayId = this.overlayService.show();
        }
        
        const serviceUrl = apiServiceBaseUri + apiUrl;

        let headers = new HttpHeaders();
        if (contentType) { 
            headers = headers.append('Content-Type', contentType); 
        }
        const options = { headers };

        if (addDataToOptions) {
            options['params'] = data;
        }

        return this.httpClient.put(serviceUrl, data, options).toPromise().then((response) => {
            return {
                data: response
            } as ApiResponse<T>;
        }).finally(() => {
            if (showOverlay) {
                this.overlayService.hide(overlayId);
            }
        });
    }

    buildURLSearchParams(filter: any): HttpParams {
        let params: HttpParams = new HttpParams();

        if (notEmpty(filter)) {
            Object.keys(filter).forEach((key, index) => {
                params = params.set(key, filter[key]);
            });
        }

        return params;
    }

    /**
     * Flatten an object parameter into a set of scalar parameters. 
     * 
     * Note: This only works one level deep
     * 
     * For example,
     *   'filter' => { JobID: 123, Task: "Feed" }
     * becomes
     *   'filter[JobId]' => 123
     *   'filter[Task]' => "Feed"
     */
    flattenParam(params: HttpParams, paramName: string): HttpParams {
        if (params.has(paramName)) {
            const value = params.get(paramName);

            // Clear out the existng value
            params = params.delete(paramName);

            // Add the value properties as flattened params
            Object.keys(value).forEach((key) => {
                params = params.set(`${paramName}[${key}]`, value[key]);
            });
        }

        // Return the updated params
        return params;
    }

    callStatusApi(): Promise<any> {
        const serviceUrl = apiServiceBaseUriAdmin + '/api/systemmessage/getcurrentincidents';

        const headers = new HttpHeaders({
            'x-api-id': statusApiID,
            'x-api-key': statusApiAppKey
        });
        const options: any = {
            headers,
            responseType: 'application/json'
        };
        
        return this.httpClient.get(serviceUrl, options).toPromise().then((response: any) => {
            return response;
        });
    }
}
