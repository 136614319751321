export interface TableOptions {
    columns: TableColumnDef[];
    columnsForcedToExport?: TableColumnDef[];
    rowClass?: any; // string, string[], or function
    csvFileName: string;
    enableDeleteColumn?: boolean;
    enableDetailColumn?: boolean;
    enableJournalColumn?: boolean;
    enableAddButton?: boolean;
    enableDraggable?: boolean;
    dragOnlyActiveRows?: boolean;
    enableSelectable?: boolean;
    // reload dataService on column selection change
    refreshOnColumnChange?: boolean;
    enableAuditColumns?: boolean;
    groupBy?: string;
    // dates need to be handled differently if coming from WebApi
    useUtcDates?: boolean;
}

export interface FormatterOptions {
    escapeHTML: boolean;
}

export interface TableColumnDef {
    displayName: string;
    /** The unique ID to give the column. This is optional. If missing, the ID will default to the field.
     *  used as ag-greed colId 
     */
    colId?: string;
    field: string;
    formatter?: Formatter;
    exportFormatter?: (row: any, value: any) => string;
    sortable?: boolean;
    visible?: boolean;
    exportable?: boolean;
    sortDir?: SortDirection;
    sortedAt?: number;
    sortField?: string;
    isGrouped?: boolean;
    headerClass?: any; // string, string[], or function
    cellClass?: any;   // string, string[], or function
    maxWidth?: number; // px
    minWidth?: number; // px
    width?: number;    // px
    rendererComponent?: any;
    outputClick?: ClickCallback;
    position?: number;
    ignore?: boolean;
    forcedToExport?: boolean;
    comparator?: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => number;
}

export type Formatter = ((row: any, value: any) => string) | ((row: any, value: any, formatterOptions: FormatterOptions) => string);

export type ClickCallback = (data: any) => void;

export interface TableState {
    // pagination info
    pageNumber: number;
    pageSize: number;
    rowOffset: number;
    // sort info
    // sort fields + direction
    sort: string;
    sorts: SortItem[];
    loadingMessage?: string;
}

export interface DataService {
    run: (state: TableState) => Promise<DataResponse>;
    /**
     * Optional hook to lazy load extra data before exporting
     */
    preExport?: (dataResponse: DataResponse) => Promise<DataResponse>;
}

export interface DataResponse<T = any> {
    results: T[];
    inlineCount: number;
}

export interface RestoreState {
    columns: RestoreColumn[];
    sorts: SortItem[];
}

export interface RestoreColumn {
    colId?: string;
    field: string;
    visible: boolean;
    position?: number;
}

export interface SortItem {
    field: string;
    dir: SortDirection;
    originalField?: string;
}

export type SortDirection = 'asc' | 'desc';

export interface ColumnsState {
    /** visible columns .field properties */
    visible: string[];
    /** hidden columns .field properties */
    hidden: string[];
}
