import { createStore, createEvent, sample } from 'effector';

export const $featureFlagStore = createStore<unknown>({});

// public events
export const setData = createEvent<unknown>();

// @ts-ignore
// actions
sample({
  clock: setData,
  target: $featureFlagStore,
});

